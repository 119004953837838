import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../store/store";
import { baseUrl } from "../constants";
import { loadingEnd, loadingStart, LoadingSubject } from "./loadingSlice";
import { IClub } from "./clubSlice";
import qs from 'qs';
// @ts-ignore
import notifSound from "./../assets/audio/message-incoming.mp3";

export interface IMessageCustomerInfo {
  id: string;
  full_name: string;
  email: string;
  photo_url: string;
  document_photo_url: string;
  document_number: string;
  document_country: string;
  city: string;
  birthdate: string;
  balance: number;
}

export interface IMessage {
  id: string;
  club_id: string;
  customer_id: string;
  admin_id: string;
  content: string;
  created_at: string;
  customer: IMessageCustomerInfo;
  club: IClub;
  isNew?: boolean;
}

export interface IMessagesGrouped {
  id: string;
  customer: IMessageCustomerInfo;
  messages: IMessage[];
}

export interface IMessagesState {
  messagesList: IMessage[];
  messagesGroupedList: IMessagesGrouped[];
}

const initialState: IMessagesState = {
  messagesList: [],
  messagesGroupedList: [],
};

const messages = createSlice({
  name: "messages",
  initialState,
  reducers: {
    _getMessagesList: (state, { payload }: PayloadAction<IMessage[]>) => {
      return { ...state, messagesList: payload };
    },
    _getMessagesGroupedList: (state, { payload }: PayloadAction<IMessagesGrouped[]>) => {
      return { ...state, messagesGroupedList: payload };
    },
    markMessageAsRead: (state, { payload }: PayloadAction<string[]>) => {
      const messageIds = payload;

      const messagesList = state.messagesList.map(m => {
        const found = messageIds.find(_m => _m === m.id);
        return { ...m, isNew: found ? false : m.isNew }
      })

      return { ...state, messagesList };
    },
  },
});

export const getMessagesList = (
  club_id?: string,
  customer_id?: string,
  onSuccess?: () => void,
  checkForNew?: boolean,
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.GetMessagesList));
    const { token } = getState().auth;
    const { messagesList } = getState().messages;
    const queryParams = qs.stringify({ club_id, customer_id });
    const url = `${baseUrl}/api/message${queryParams ? '?' + queryParams : ''}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    let jsonData: IMessage[] = await response.json();

    if (response.status === 200) {
      if (checkForNew) {
        jsonData = jsonData.map(n => {
          const isFound = messagesList.find(m => m.id === n.id && !m.isNew);
          return { ...n, isNew: !isFound }
        })
        jsonData = jsonData.filter(m => !m.admin_id);
        // const playSound = JSON.stringify(jsonData) !== JSON.stringify(messagesList);
        // if (playSound) {
        //   const sound = new Audio(notifSound);
        //   sound.play();
        // }
      }
      console.log('getMessagesListJson', jsonData);
      dispatch(_getMessagesList(jsonData));
      onSuccess && onSuccess();
    } else {
      console.log('getMessagesListNot200', jsonData);
    }
  } catch (e) {
    console.log('getMessagesListErr', e);
  } finally {
    dispatch(loadingEnd(LoadingSubject.GetMessagesList));
  }
};

export const getMessagesGroupedList = (
  club_id?: string,
  onSuccess?: () => void,
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.GetMessagesGroupedList));
    const { token } = getState().auth;
    const url = `${baseUrl}/api/message/customer-groupped?club_id=${club_id}`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonData: IMessagesGrouped[] = await response.json();
    if (response.status === 200) {
      const modifiedData = jsonData.map(mg => ({ ...mg, id: `${mg.customer.id}${mg.customer.birthdate}` }))
      console.log('getMessagesGroupedListJson', modifiedData);
      dispatch(_getMessagesGroupedList(modifiedData));
      onSuccess && onSuccess();
    } else {
      console.log('getMessagesGroupedListNot200', jsonData);
    }
  } catch (e) {
    console.log('getMessagesGroupedListErr', e);
  } finally {
    dispatch(loadingEnd(LoadingSubject.GetMessagesGroupedList));
  }
};

export const createMessage = (
  bodyParams: {
    club_id: string;
    content: string;
    customer_id: string;
  },
  onSuccess?: () => void,
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.CreateMessage));
    const { token } = getState().auth;
    const url = `${baseUrl}/api/message`
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyParams)
    });
    const jsonData = await response.json();
    if (response.status === 200) {
      console.log('createMessageJson', jsonData)
      dispatch(getMessagesGroupedList(bodyParams.club_id))
      onSuccess && onSuccess();
    } else {
      console.log('createMessageNot200', jsonData)
    }
  } catch (e) {
    console.log('createMessageErr', e);
  } finally {
    dispatch(loadingEnd(LoadingSubject.CreateMessage));
  }
};

export const { _getMessagesGroupedList, _getMessagesList, markMessageAsRead } = messages.actions;

export default messages.reducer;
