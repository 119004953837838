import { IconButton } from "@mui/material";
import AppText from "components/AppText/AppText";
import ModalWrapper from "components/modals/ModalWrapper";
import SendIcon from "@mui/icons-material/Send";
import RefreshIcon from "@mui/icons-material/Cached";
import styles from "./../HelpChatPage.module.css";
import { IMessage, markMessageAsRead } from "slices/messagesSlice";
import { RootState } from "store/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useEffect, useRef } from "react";

type Props = {
  open: boolean;
  selectedGroupId: string;
  onCloseModal: () => void;
  onClickSendMessage: () => void;
  onRefreshList: () => void;
  setMessageText: React.Dispatch<React.SetStateAction<string>>;
  messageText: string;
};

const ChatModal = (props: Props) => {
  const {
    open,
    selectedGroupId,
    onCloseModal,
    onClickSendMessage,
    onRefreshList,
    setMessageText,
    messageText,
  } = props;

  const { me } = useSelector((s: RootState) => s.auth);
  const { messagesGroupedList, messagesList } = useSelector(
    (s: RootState) => s.messages
  );
  const dispatch = useDispatch();

  const selectedGroupItem = messagesGroupedList.find(
    (mg) => mg.id === selectedGroupId
  );

  const scrollRef = useRef<any>(null);

  useEffect(() => {
    setTimeout(() => {
      scrollRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  }, [selectedGroupId, messagesGroupedList]);

  useEffect(() => {
    let newMessages: string[] = [];
    const messagesListNew = messagesList.filter((m) => m.isNew);
    messagesListNew.map((m) => {
      const found = selectedGroupItem?.messages.find((f) => f.id === m.id);
      if (found) {
        newMessages.push(found.id);
      }
    });
    if (newMessages.length) {
      dispatch(markMessageAsRead(newMessages));
    }
  }, [selectedGroupItem]);

  const renderMessageItem = (item: IMessage) => {
    const isMine = item.admin_id === me?.id;

    const wrapperStyles = {
      style: {
        justifyContent: isMine ? "flex-end" : "flex-start",
      },
    };

    const itemStyles = {
      style: {
        backgroundColor: isMine ? "var(--lightgreen)" : "var(--gray1)",
        borderTopLeftRadius: 40,
        borderTopRightRadius: 40,
        borderBottomLeftRadius: isMine ? 40 : 0,
        borderBottomRightRadius: isMine ? 0 : 40,
        border: `1px solid ${isMine ? "#c0dbb2" : "#ccc"}`,
      },
    };

    return (
      <div {...wrapperStyles} className={styles["message-item-wrapper"]}>
        <div {...itemStyles} className={styles["message-item"]}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <AppText>{item.content}</AppText>
            <AppText
              style={{
                alignSelf: isMine ? "flex-end" : "flex-start",
                marginTop: 5,
                fontSize: 10,
              }}
              color="gray3"
              variant="body4"
            >
              {moment(item.created_at).format("DD-MM-YYYY hh:mm:ss")}
            </AppText>
          </div>
        </div>
      </div>
    );
  };

  return (
    <ModalWrapper
      title={selectedGroupItem?.customer.full_name}
      open={open}
      onClose={onCloseModal}
    >
      <div>
        <div className={styles["second-header-wrapper"]}>
          <AppText color="gray3">{selectedGroupItem?.customer.city}</AppText>
          <IconButton
            className={styles["refresh-icon-wrapper"]}
            onClick={onRefreshList}
          >
            <RefreshIcon className={styles["refresh-icon"]} />
          </IconButton>
        </div>

        <div id={"messages_container"} className={styles["messages-container"]}>
          {selectedGroupItem?.messages.map((m) => renderMessageItem(m))}
          <div ref={scrollRef} />
        </div>
        <div className={styles["text-area-container"]}>
          <textarea
            name={"chat_textarea"}
            onKeyDown={(e) => {
              if (e.keyCode == 13 && e.shiftKey == false) {
                e.preventDefault();
                onClickSendMessage();
              }
            }}
            onChange={(e) => setMessageText(e.target.value)}
            className={styles["text-area"]}
            value={messageText}
          />
          <IconButton
            disabled={!messageText}
            className={styles["send-button-wrapper"]}
            onClick={onClickSendMessage}
          >
            <SendIcon
              color={messageText ? "primary" : "disabled"}
              className={styles["send-button"]}
            />
          </IconButton>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ChatModal;
