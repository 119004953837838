import React, { ReactNode, useEffect } from "react";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
import Background from "./Background";
import { Breakpoint, Container } from "@mui/material";

export interface AuthorizedLayoutProps {
  children?: ReactNode;
  noBackground?: boolean;
  fullWidth?: boolean;
  maxWidth?: false | Breakpoint;
}

const AuthorizedLayout: React.FC<AuthorizedLayoutProps> = ({
  children,
  noBackground = false,
  fullWidth = false,
  maxWidth = "lg",
}) => {
  return (
    <>
      {!noBackground && <Background type="image" />}
      <AppHeader />
      <Container
        maxWidth={maxWidth}
        style={
          fullWidth
            ? { maxWidth: "100vw", paddingLeft: 0, paddingRight: 0 }
            : {}
        }
        sx={{
          py: 8,
          pt: { xs: "67px", sm: "115px" },
          minHeight: { xs: "calc(100vh -  140px)", sm: "calc(100vh - 140px)" },
        }}
      >
        {children}
      </Container>
      <AppFooter />
    </>
  );
};

export default AuthorizedLayout;
