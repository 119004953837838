import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_LANGUAGE_CODE } from '../translations/translations';

export enum LanguageCode {
  ENGLISH = 'en',
  UKRAINIAN = 'uk',
}

interface IInitialState {
  currentLanguage: LanguageCode;
}

const initialState: IInitialState = { currentLanguage: DEFAULT_LANGUAGE_CODE };

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, { payload }) => {
      return { currentLanguage: payload };
    },
  },
});

export const { setLanguage } = languageSlice.actions;

export default languageSlice.reducer;
