import { LoadingSubject } from "../slices/loadingSlice";

export function getScreenDimensions() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

export const isLoading = (
  subject: LoadingSubject,
  subjects: LoadingSubject[]
) => {
  return !!subjects.find((l) => l === subject);
};

export const isObjectEmpty = (object: {}) => {
  return !!!Object.keys(object).length;
};
