import { Theme } from "@mui/material";

export default function Tab(theme: Theme) {
  return {
    MuiTabs: {
      styleOverrides: {
        scrollButtons: {
          color: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: theme.palette.primary.main,
          opacity: 1,
          fontFamily: "Malgun Gothic",
          fontSize: 16,
          fontWeight: "700",
          textTransform: "none",
          "&.Mui-disabled": {
            color: theme.palette.gray3.main,
          },
        },
        "&:focus": {
          backgroundColor: theme.palette.common.white,
        },
        "&:disabled": {
          backgroundColor: "transparent",
          color: theme.palette.gray3.main,
        },
      },
    },
  };
}
