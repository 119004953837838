import React, { useEffect, useState } from "react";
import styles from "./NewsPage.module.css";
import AppText from "../../components/AppText/AppText";
import AppButton from "../../components/AppButton/AppButton";
import ChangePlanModal from "./Modals/ChangePlanModal/ChangePlanModal";
import AuthorizedLayout from "layouts/AuthorizedLayout";
import { useDispatch, useSelector } from "react-redux";
import { getSubscriptionsList, getTariffsList } from "slices/tariffsSlice";
import { useParams } from "react-router-dom";
import { RootState } from "store/rootReducer";
import { INews, deleteNews, getNewsList } from "slices/newsSlice";
import moment from "moment";
import DeleteModal from "components/modals/DeleteModal/DeleteModal";
import { CreateEditNewsModal } from "./Modals/CreateEditNewsModal/CreateEditNewsModal";
import { isLoading } from "utils";
import { LoadingSubject } from "slices/loadingSlice";
import parse from "html-react-parser";
import LikeIcon from "@mui/icons-material/ThumbUp";
import DislikeIcon from "@mui/icons-material/ThumbDown";
import { Row } from "components/blocks";

enum ModalStateEnum {
  None,
  ChangePlan,
  CreateEdit,
  Delete,
}

function NewsPage() {
  const [modalState, setModalState] = useState(ModalStateEnum.None);
  const [selectedNews, setSelectedNews] = useState<INews | undefined>(
    undefined
  );

  const dispatch = useDispatch();

  const params = useParams();
  const club_id = params.id;

  const { subscriptionsList } = useSelector((s: RootState) => s.tariffs);
  const { list } = useSelector((s: RootState) => s.news);
  const { token } = useSelector((s: RootState) => s.auth);

  useEffect(() => {
    // @ts-ignore
    dispatch(getTariffsList());
    // @ts-ignore
    dispatch(getSubscriptionsList(club_id));
    // @ts-ignore
    dispatch(getNewsList(club_id));
  }, []);

  const { subjects } = useSelector((s: RootState) => s.loading);
  const isLoadingDeleteNews = isLoading(LoadingSubject.DeleteNews, subjects);
  const isLoadingGetNewsList = isLoading(LoadingSubject.GetNewsList, subjects);

  const [deleteId, setDeleteId] = useState<string>("");

  const openChangePlanModal = () => {
    setModalState(ModalStateEnum.ChangePlan);
  };

  const openCreateEditModal = () => {
    setModalState(ModalStateEnum.CreateEdit);
  };

  const openDeleteModal = (id: string) => {
    setDeleteId(id);
    setModalState(ModalStateEnum.Delete);
  };

  const closeModal = () => {
    setSelectedNews(undefined);
    setDeleteId("");
    setModalState(ModalStateEnum.None);
  };

  const renderItem = (item: INews) => {
    const contentHTML =
      item.content.length > 800
        ? `${item.content.slice(0, 800)} ...`
        : !item.content.length
        ? "No content"
        : item.content;

    return (
      <div className={styles["item-container"]}>
        <div className={styles["item-header"]}>
          <div className={styles["item-image-title"]}>
            <div className={styles["item-image-container"]}>
              <img
                className={styles["item-image"]}
                src={`${item.photo_url}&token=${token}`}
                alt="news-item"
              />
            </div>
            <AppText sx={{ ml: 3 }} variant="h4">
              {item.title}
            </AppText>
          </div>
          <div>
            <AppButton
              onClick={() => {
                setSelectedNews(item);
                openCreateEditModal();
              }}
              variant="outlined"
              text={"Edit news"}
              sx={{ mr: 3 }}
            />
            <AppButton
              onClick={() => openDeleteModal(item.id)}
              variant="outlined"
              color="error"
              text={"Delete news"}
            />
          </div>
        </div>
        <AppText sx={{ mt: 5 }} variant="body3">
          {parse(contentHTML)}
        </AppText>

        <div
          style={{
            marginTop: 15,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <AppText variant="body4">
            {moment(item.created_at).format("MMMM Do YYYY, h:mm:ss a")}
          </AppText>
          <AppText variant="body4">
            {`News type: ${item.is_global ? "Global" : "Local"}`}
          </AppText>
        </div>

        <Row maxWidth={100} sx={{ mt: 3 }}>
          <Row>
            <LikeIcon sx={{ width: 20, height: 20 }} color="action" />
            <AppText variant="body4" sx={{ ml: 1 }}>
              {item.likes_count}
            </AppText>
          </Row>
          <Row sx={{ ml: 3 }}>
            <DislikeIcon sx={{ width: 20, height: 20 }} color="action" />
            <AppText variant="body4" sx={{ ml: 1 }}>
              {item.dislikes_count}
            </AppText>
          </Row>
        </Row>
      </div>
    );
  };

  return (
    <AuthorizedLayout>
      <AppButton
        onClick={openChangePlanModal}
        color="gray2"
        variant="outlined"
        text={subscriptionsList.length ? "Change plan" : "Buy plan"}
      />

      <div className={styles["title-container"]}>
        <AppText color="white" variant="h2">
          {"News"}
        </AppText>
        <AppButton onClick={openCreateEditModal} text={"Create news"} />
      </div>

      <div className={styles["items-wrapper"]}>
        {!list.length && !isLoadingGetNewsList ? (
          <AppText
            sx={{
              textAlign: "center",
              marginTop: "20vh",
            }}
            variant="h2"
            color="gray2"
          >
            {"No news found"}
          </AppText>
        ) : (
          list.map((item) => renderItem(item))
        )}
      </div>

      <ChangePlanModal
        open={modalState === ModalStateEnum.ChangePlan}
        onClose={closeModal}
      />
      <CreateEditNewsModal
        open={modalState === ModalStateEnum.CreateEdit}
        onClose={closeModal}
        selectedItem={selectedNews}
        clubId={club_id!}
      />
      <DeleteModal
        open={modalState === ModalStateEnum.Delete}
        onClose={closeModal}
        loading={isLoadingDeleteNews}
        onDelete={() => {
          // @ts-ignore
          dispatch(deleteNews(deleteId, club_id, closeModal));
        }}
        title={"Delete news?"}
      />
    </AuthorizedLayout>
  );
}

export default NewsPage;
