import {
  IApplication,
  acceptApplication,
  declineApplication,
} from "slices/applicationSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { isLoading } from "utils";
import { LoadingSubject } from "slices/loadingSlice";
import { Button, Grid } from "@mui/material";
import { Row } from "components/blocks";
import ModalWrapper from "components/modals/ModalWrapper";
import AppText from "components/AppText/AppText";
import styles from "./UserApplicationModal.module.css";
import { useIntl } from "react-intl";

type Props = {
  open: boolean;
  onClose: () => void;
  applicationItem: IApplication;
  clubId: string;
};

const UserApplicationModal = (props: Props) => {
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const { open, onClose, applicationItem, clubId } = props;
  const { token } = useSelector((s: RootState) => s.auth);
  const dispatch = useDispatch();

  const subjects = useSelector((s: RootState) => s.loading.subjects);
  const isLoadingAccept = isLoading(LoadingSubject.AcceptApplication, subjects);
  const isLoadingDecline = isLoading(
    LoadingSubject.DeclineApplication,
    subjects
  );

  const submitting = isLoadingAccept || isLoadingDecline;

  const {
    customer: {
      birthdate,
      city,
      document_country,
      document_number,
      document_photo_url,
      email,
      full_name,
      photo_url,
    },
    id,
    status,
  } = applicationItem;

  // @ts-ignore
  const accept = () => dispatch(acceptApplication(id, clubId, onClose));
  // @ts-ignore
  const decline = () => dispatch(declineApplication(id, clubId, onClose));

  return (
    <ModalWrapper
      // title={<AppText variant="h3">{t("text.edit-user")}</AppText>}
      open={open}
      onClose={onClose}
      maxWidth={"450px"}
      actions={
        <Row sx={{ width: "100%", gap: 8 }}>
          <Button
            variant="contained"
            onClick={accept}
            children={t("general.accept")}
            disabled={submitting || status !== "pending"}
            fullWidth
          />
          <Button
            onClick={decline}
            variant="contained"
            color="error"
            children={t("general.decline")}
            disabled={submitting || status !== "pending"}
            fullWidth
          />
        </Row>
      }
    >
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <img
            className={styles["user-img"]}
            src={photo_url + "&token=" + token}
            alt={"user_pic"}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <div className={styles["user-initials-container"]}>
            <AppText className={styles["text-style"]}>{full_name}</AppText>
            <AppText className={styles["text-style"]}>{email}</AppText>
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>
          <img
            className={styles["document-img"]}
            src={document_photo_url + "&token=" + token}
            alt={"document_pic"}
          />
          <div className={styles["user-other-data-container"]}>
            <AppText className={styles["text-style"]}>
              {document_number}
            </AppText>
            <AppText className={styles["text-style"]}>
              {document_country}
            </AppText>
            <AppText className={styles["text-style"]}>{birthdate}</AppText>
            <AppText className={styles["text-style"]}>{city}</AppText>
          </div>
        </Grid>
      </Grid>
    </ModalWrapper>
  );
};

export default UserApplicationModal;
