import { createSlice } from '@reduxjs/toolkit';

export enum LoadingSubject {
  Login = 1,
  ClubList,
  CreateClub,
  DeleteClub,
  UpdateClub,
  UpdateClubImage,
  GetClub,
  CategoryList,
  CreateCategory,
  DeleteCategory,
  UpdateCategory,
  UpdateCategoryImage,
  GetCategory,
  ProductList,
  CreateProduct,
  DeleteProduct,
  DeleteSelectedProducts,
  UpdateProduct,
  UpdateProductImage,
  GetProductsByIds,
  GetClubApplication,
  AcceptApplication,
  DeclineApplication,
  GetClubOrders,
  CancelOrder,
  CompleteOrder,
  GetOrderById,
  CreateUser,
  UpdateUser,
  DeleteUser,
  GetUserList,
  GetMe,
  GetEmployeeList,
  CreateEmployee,
  DeleteEmployee,
  GetClubBalance,
  MakeTransaction,
  GetTariffsList,
  CreateTariff,
  DeleteTariff,
  UpdateTariff,
  GetSubscriptionsList,
  MakeSubscribe,
  GetClubMembers,
  SetClubSorting,
  GetNewsList,
  CreateEditNews,
  DeleteNews,
  UpdateNews,
  SetNewsPhoto,
  GetNewsAvailableAmount,
  PublishNews,
  RejectNews,
  GetClubStatistics,
  GetClubFeedbacksList,
  DeleteClubFeedback,
  GetProductFeedbacksList,
  DeleteProductFeedback,
  GetMessagesGroupedList,
  GetMessagesList,
  CreateMessage,
  Forgot,
  GetCommentsList,
  DeleteComment,
  Support
}

export interface ILoadingState {
  subjects: LoadingSubject[];
}

const initialState: ILoadingState = {
  subjects: [],
};

const loading = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    loadingStart: (state, { payload }) => {
      const newSubjects = JSON.parse(JSON.stringify(state.subjects));
      newSubjects.push(payload);
      return { ...state, ...{ subjects: newSubjects } };
    },
    loadingEnd: (state, { payload }) => {
      const newSubjects = state.subjects.filter(l => l !== payload);
      return { ...state, ...{ subjects: newSubjects } };
    },
  },
});

export const { loadingStart, loadingEnd } = loading.actions;

export default loading.reducer;
