import { useState } from "react";
import AppText from "../../components/AppText/AppText";
import AppTabs from "../../components/AppTabs/AppTabs";
import ApplicationsTab from "./tabs/ApplicationsTab/ApplicationsTab";
import TariffsTab from "./tabs/TariffsTab/TariffsTab";
import NewsTab from "./tabs/NewsTab/NewsTab";
import { UserRole } from "../../slices/usersSlice";
import GenericUsersTab from "./tabs/GenericUsersTab/GenericUsersTab";
import ClubsWithTopsTab from "./tabs/ClubsWithTopsTab/ClubsWithTopsTab";
import ClubsWithoutTopsTab from "./tabs/ClubsWithoutTopsTab/ClubsWithoutTopsTab";
import AuthorizedLayout from "layouts/AuthorizedLayout";
import { Row } from "components/blocks";
import { useRoles } from "hooks/useRoles";
import { useIntl } from "react-intl";
import StatisticsTab from "./tabs/StatisticsTab/StatisticsTab";

export enum TabsEnum {
  Owners,
  Clubs,
  Tariffs,
  Moderators,
  Administrators,
  Staff,
  Users,
  News,
  Logs,
}

function WelcomePage() {
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const [tabStep, setTabStep] = useState<TabsEnum>(TabsEnum.Owners);
  const roles = useRoles();

  const rolesAccess = {
    Applications:
      roles.SuperAdmin ||
      roles.Moderator ||
      roles.ClubOwner ||
      roles.ManagerAdmin ||
      roles.Staff,
    ClubsWithTops: roles.SuperAdmin || roles.Moderator,
    Tariffs: roles.SuperAdmin || roles.Moderator,
    Moderators: roles.SuperAdmin,
    ClubOwner: roles.SuperAdmin || roles.Moderator,
    Administrators: roles.SuperAdmin || roles.Moderator || roles.ClubOwner,
    Staff:
      roles.SuperAdmin ||
      roles.Moderator ||
      roles.ClubOwner ||
      roles.ManagerAdmin,
    Users: roles.SuperAdmin || roles.Moderator,
    News:
      roles.SuperAdmin ||
      roles.Moderator ||
      roles.ClubOwner ||
      roles.ManagerAdmin,
    Logs: roles.SuperAdmin,
    Statistics:
      roles.ClubOwner ||
      roles.ManagerAdmin ||
      roles.Moderator ||
      roles.SuperAdmin,
  };

  const pickClubsComponent = (isTop: boolean) => {
    if (isTop) {
      return <ClubsWithTopsTab />;
    } else {
      return <ClubsWithoutTopsTab />;
    }
  };

  const tabs = [
    {
      name: t("menu-tabs-name.applications"),
      children: <ApplicationsTab />,
      isTabShown: rolesAccess.Applications,
    },
    {
      name: t("menu-tabs-name.clubs"),
      children: pickClubsComponent(rolesAccess.ClubsWithTops),
      isTabShown: true,
    },
    {
      name: t("menu-tabs-name.tariffs"),
      children: <TariffsTab />,
      isTabShown: rolesAccess.Tariffs,
    },
    {
      name: t("menu-tabs-name.moderators"),
      children: (
        <GenericUsersTab userRole={UserRole.Moderator} excludeClubColumn />
      ),
      isTabShown: rolesAccess.Moderators,
    },
    {
      name: t("menu-tabs-name.club-owners"),
      children: <GenericUsersTab userRole={UserRole.ClubOwner} />,
      isTabShown: rolesAccess.ClubOwner,
    },
    {
      name: t("menu-tabs-name.administrators"),
      children: <GenericUsersTab userRole={UserRole.ManagerAdmin} />,
      isTabShown: rolesAccess.Administrators,
    },
    {
      name: t("menu-tabs-name.staff"),
      children: <GenericUsersTab userRole={UserRole.Staff} />,
      isTabShown: rolesAccess.Staff,
    },
    {
      name: t("menu-tabs-name.users"),
      children: <GenericUsersTab roleColumnShown />,
      isTabShown: rolesAccess.Users,
    },
    {
      name: t("menu-tabs-name.news"),
      children: <NewsTab />,
      isTabShown: rolesAccess.News,
    },
    {
      name: t("menu-tabs-name.statistics"),
      children: <StatisticsTab />,
      isTabShown: rolesAccess.Statistics,
    },
  ].filter((t) => t.isTabShown);

  return (
    <AuthorizedLayout>
      <Row wrap sx={{ py: 8 }}>
        <AppText color="gray1" variant="h2">
          {t("general.home")}
        </AppText>
      </Row>
      <AppTabs tabStep={tabStep} setTabStep={setTabStep} tabsItemArr={tabs} />
    </AuthorizedLayout>
  );
}

export default WelcomePage;
