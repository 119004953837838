import { useState } from "react";
import styles from "./ProductModal.module.css";
import AppInput from "../../../../components/AppInput/AppInput";
import AppButton from "../../../../components/AppButton/AppButton";
import AppForm from "../../../../forms/AppForm";
import { FormikValues } from "formik";
import { useSchemas } from "../../../../helpers/schemas";
import { AppColors } from "../../../../AppTheme";
import { useRef } from "react";
import TrashIcon from "@mui/icons-material/DeleteOutline";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/rootReducer";
import { LoadingSubject } from "../../../../slices/loadingSlice";
import { isLoading } from "../../../../utils";
import Icon from "../../../../assets/icons/svgs/Icon";
import {
  IProduct,
  createProduct,
  updateProduct,
  updateProductImage,
} from "../../../../slices/productSlice";
import AppText from "../../../../components/AppText/AppText";
import ModalWrapper from "components/modals/ModalWrapper";
import { Box, Grid } from "@mui/material";
import { Column, Row } from "components/blocks";
import { useRoles } from "hooks/useRoles";
import { useIntl } from "react-intl";

const s = (className: string) => styles[className];

type Props = {
  open: boolean;
  onClose: () => void;
  openDeleteModal: () => void;
  categoryId: string;
  catId: string;
  productItem?: IProduct;
};

function ProductModal(props: Props) {
  const {
    productItem,
    categoryId,
    onClose,
    openDeleteModal,
    open,
    //
    catId,
  } = props;
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const token = useSelector((s: RootState) => s.auth.token);
  const { productSchema } = useSchemas();
  const dispatch = useDispatch();
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [image, setImage] = useState<{
    preview: string | undefined;
    raw: File | undefined;
  }>({
    preview: "",
    raw: undefined,
  });

  const [showImageErr, setShowImageErr] = useState(false);

  const closeModal = () => {
    setImage({ preview: undefined, raw: undefined });
    setShowImageErr(false);
    onClose();
  };

  const roles = useRoles();

  const subjects = useSelector((s: RootState) => s.loading.subjects);
  const isLoadingCreateProduct = isLoading(
    LoadingSubject.CreateProduct,
    subjects
  );
  const isLoadingUpdateProduct = isLoading(
    LoadingSubject.UpdateProduct,
    subjects
  );
  const isLoadingUpdateProductImage = isLoading(
    LoadingSubject.UpdateProductImage,
    subjects
  );

  const onSubmit = (values: FormikValues) => {
    // update
    if (productItem) {
      const data = {
        category_id: productItem.category_id,
        name: values.name,
        description: values.description,
        amount: values.amount,
        price: values.price,
      };
      if (image.raw) {
        const formData = new FormData();
        formData.append("file", image.raw);
        dispatch(
          // @ts-ignore
          updateProductImage(productItem.id, productItem.category_id, formData)
        );
      }
      // @ts-ignore
      dispatch(updateProduct(productItem.id, data, closeModal));
    } // create
    else if (image.raw) {
      const formData = new FormData();
      formData.append("image", image.raw);
      formData.append("category_id", categoryId || catId);
      formData.append("name", values.name);
      formData.append("description", values.description);
      formData.append("amount", values.amount);
      formData.append("price", values.price);

      // @ts-ignore
      dispatch(createProduct(categoryId || catId, formData, closeModal));
    } else {
      setShowImageErr(true);
    }
  };

  const accessRoles =
    roles.SuperAdmin ||
    roles.ClubOwner ||
    roles.ManagerAdmin ||
    roles.Moderator;

  const onImageClick = () => {
    if (accessRoles) {
      inputFile?.current?.click();
    }
  };

  const renderProductForm = () => {
    return (
      <AppForm
        initialValues={{
          name: productItem?.name,
          description: productItem?.description,
          amount: productItem?.amount,
          price: productItem?.price,
        }}
        onSubmit={onSubmit}
        children={
          <>
            <Grid container spacing={8} sx={{ px: { xs: 0, lg: 8 } }}>
              <Grid item xs={12} lg={6}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", lg: "start" },
                  }}
                >
                  <div
                    onClick={onImageClick}
                    className={styles["left-container"]}
                  >
                    {image.preview || productItem?.image_url ? (
                      <img
                        className={styles["image-style"]}
                        src={
                          image.preview ||
                          productItem?.image_url + "&token=" + token
                        }
                        alt="category-img"
                      />
                    ) : (
                      <Icon name="Plus" className={s("plus-icon")} />
                    )}
                    <input
                      autoComplete="off"
                      onChange={(event) => {
                        const files = event?.target?.files;
                        if (files?.length) {
                          const image = files[0];
                          setImage({
                            preview: URL.createObjectURL(image),
                            raw: image,
                          });
                        }
                      }}
                      accept="image/*"
                      type="file"
                      id="file"
                      ref={inputFile}
                      style={{ display: "none" }}
                    />
                  </div>
                </Box>
                {showImageErr && (
                  <AppText
                    sx={{
                      color: AppColors.red,
                      display: "block",
                      textAlign: "center",
                      marginTop: "15px",
                    }}
                  >
                    {t("text.please-upload-a-picture")}
                  </AppText>
                )}
              </Grid>
              <Grid item xs={12} lg={6}>
                <Column>
                  <AppInput
                    name="name"
                    classNameContainer={styles["input-container-style"]}
                    classNameInput={styles["input-style"]}
                    placeholder={t("text.product-name")}
                    errorColor={AppColors.red}
                    isForm
                    disabled={!accessRoles}
                  />
                  <AppInput
                    name="description"
                    classNameContainer={styles["input-container-style"]}
                    classNameInput={styles["input-style"]}
                    placeholder={t("desc")}
                    errorColor={AppColors.red}
                    isForm
                    isTextArea
                    maxLength={256}
                    disabled={!accessRoles}
                    style={{
                      // width: 220,
                      marginTop: 10,
                      height: 180,
                      backgroundColor: AppColors.white,
                    }}
                  />
                  <AppInput
                    name="amount"
                    classNameContainer={styles["input-container-style"]}
                    classNameInput={styles["input-style"]}
                    placeholder={t("text.amount")}
                    errorColor={AppColors.red}
                    isForm
                  />
                  <AppInput
                    name="price"
                    classNameContainer={styles["input-container-style"]}
                    classNameInput={styles["input-style"]}
                    placeholder={t("text.price")}
                    errorColor={AppColors.red}
                    isForm
                    disabled={!accessRoles}
                  />
                </Column>
              </Grid>
            </Grid>
            <Row
              style={{ justifyContent: "space-around" }}
              sx={{ mt: 10, mb: 4, gap: 4 }}
            >
              <AppButton
                isForm
                fullWidth
                sx={{ maxWidth: 250 }}
                text={t("general.save")}
                loading={
                  isLoadingCreateProduct ||
                  isLoadingUpdateProduct ||
                  isLoadingUpdateProductImage
                }
              />
              <AppButton
                onClick={closeModal}
                fullWidth
                sx={{ maxWidth: 250 }}
                variant="outlined"
                color="error"
                text={t("general.cancel")}
              />
            </Row>
          </>
        }
        validationSchema={productSchema}
        enableReinitialize={true}
      />
    );
  };

  return (
    <ModalWrapper
      open={open}
      title={
        productItem &&
        accessRoles && (
          <AppButton
            sx={{ fontFamily: "Malgun-Bold", fontSize: 13 }}
            startIcon={<TrashIcon fontSize="large" />}
            variant="text"
            color="error"
            text={t("text.delete-product")}
            onClick={openDeleteModal}
          />
        )
      }
      maxWidth="750px"
      onClose={closeModal}
    >
      {renderProductForm()}
    </ModalWrapper>
  );
}

export default ProductModal;
