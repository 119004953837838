import React, { ReactNode } from "react";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
import Background from "./Background";
import { Breakpoint, Container } from "@mui/material";

export interface UnauthorizedLayoutProps {
  children?: ReactNode;
  noBackground?: boolean;
  maxWidth?: false | Breakpoint;
}

const UnauthorizedLayout: React.FC<UnauthorizedLayoutProps> = ({
  children,
  noBackground = false,
  maxWidth = "lg",
}) => {
  return (
    <>
      {!noBackground && <Background type="video" />}
      <Container
        maxWidth={maxWidth}
        sx={{
          py: 8,
          pt: "160px",
          minHeight: "100vh",
        }}
      >
        {children}
      </Container>
    </>
  );
};

export default UnauthorizedLayout;
