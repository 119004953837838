import { SxProps, Theme, Typography } from "@mui/material";
import { AppColors } from "../../AppTheme";

type AppTextVariant =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "body1"
  | "body2"
  | "body3"
  | "body4";

type AppTextProps = {
  children: React.ReactNode;
  variant?: AppTextVariant;
  color?: keyof typeof AppColors;
  className?: string;
  sx?: SxProps<Theme>;
  style?: React.CSSProperties;
};

const AppText = (props: AppTextProps) => {
  const { children, variant, color, className, sx, style } = props;

  return (
    <Typography
      variant={variant || "body3"}
      color={`var(--${color || "black"})`}
      className={className}
      sx={sx}
      style={style}
    >
      {children}
    </Typography>
  );
};

export default AppText;
