import styles from "./SupportPage.module.css";
import Text from "../../../components/AppText/AppText";
import AppInput from "../../../components/AppInput/AppInput";
import AppForm from "../../../forms/AppForm";
import { FormikValues } from "formik";
import { useIntl } from "react-intl";
import { useSchemas } from "../../../helpers/schemas";
import AppButton from "../../../components/AppButton/AppButton";
import { AppColors } from "../../../AppTheme";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNav } from "../../../hooks/useNav";
import { useDispatch, useSelector } from "react-redux";
import { login, support } from "../../../slices/authSlice";
import { RootState } from "../../../store/rootReducer";
import { isLoading } from "../../../utils";
import { LoadingSubject } from "../../../slices/loadingSlice";
import UnauthorizedLayout from "layouts/UnauthorizedLayout";
import { IconLogo } from "components/icons";
import { Row } from "components/blocks";
import { Link } from "react-router-dom";
import { ROUTES } from "navigation/navigation";

function SupportPage() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { viewLogin } = useNav();
  const subjects = useSelector((s: RootState) => s.loading.subjects);

  const t = (id: string) => intl.formatMessage({ id });
  const { supportAuthSchema } = useSchemas();

  const onSubmit = (values: FormikValues) => {
    // @ts-ignore
    dispatch(support(values.email, values.text, viewLogin));
  };

  return (
    <UnauthorizedLayout>
      <Row style={{ flexDirection: "row-reverse" }}>
        <img
          style={{ width: 300, height: 100, objectFit: "contain" }}
          src={require("../../../assets/images/raster/logo_white.png")}
        />
      </Row>
      <div className={styles["form-container"]}>
        <Text variant="h1" className={styles["login-title"]} color={"white"}>
          {t("Support")}
        </Text>

        <AppForm
          initialValues={{ email: "", text: "" }}
          onSubmit={onSubmit}
          children={
            <>
              <AppInput
                isForm
                classNameContainer={styles["input-field-container-email"]}
                name={"email"}
                placeholder={t("general.email")}
              />

              <AppInput
                textAreaClassname={styles["text-area"]}
                isTextArea
                isForm
                name={"text"}
                placeholder={t("Text")}
              />

              <AppButton
                className={styles["sign-in-button"]}
                text={t("Send")}
                endIcon={<ChevronRightIcon />}
                isForm
                loading={isLoading(LoadingSubject.Support, subjects)}
              />
            </>
          }
          validationSchema={supportAuthSchema}
          enableReinitialize={true}
        />
      </div>
    </UnauthorizedLayout>
  );
}
export default SupportPage;
