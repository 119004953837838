import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import LoginPage from "./../pages/Auth/LoginPage/LoginPage";
import ForgotPasswordPage from "./../pages/Auth/ForgotPasswordPage/ForgotPasswordPage";
import ClubMenuPage from "./../pages/ClubMenuPage/ClubMenuPage";
import CreateUserPage from "./../pages/CreateUserPage/CreateUserPage";
import ProfilePage from "./../pages/ProfilePage/ProfilePage";
import ClubProfilePage from "./../pages/ClubProfilePage/ClubProfilePage";
import WelcomePage from "../pages/WelcomePage/WelcomePage";
import SuperAdminClubsPage from "./../pages/SuperAdminClubsPage/SuperAdminClubsPage";
import FeedbacksPage from "./../pages/FeedbacksPage/FeedbacksPage";
import NewsPage from "./../pages/NewsPage/NewsPage";
import CreateNewsPage from "./../pages/CreateNewsPage/CreateNewsPage";
import AdminEditPage from "./../pages/AdminEditPage/AdminEditPage";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/rootReducer";
import CreateClubPage from "../pages/CreateClubPage/CreateClubPage";
import HelpChatPage from "pages/HelpChatPage/HelpChatPage";
import { getMessagesList } from "slices/messagesSlice";
import { useLoadings } from "hooks/useLoadings";
import SupportPage from "pages/Auth/SupportPage/SupportPage";
import TermsPage from "pages/TermsPage/TermsPage";
import PrivacyPolicyPage from "pages/PrivacyPolicyPage/PrivacyPolicyPage";
import StatisticsPage from "pages/StatisticsPage/StatisticsPage";
import { getMe } from "slices/authSlice";
import { useRoles } from "hooks/useRoles";
import { UserRole } from "slices/usersSlice";

export const ROUTES = {
  LOGIN: "/login",
  FORGOT: "/forgot",
  CLUB_MENU: "/club-menu",
  CREATE_CLUB: "/create-club",
  CREATE_USER: "/create-user",
  PROFILE: "/profile",
  CLUB_PROFILE: "/club-profile",
  WELCOME: "/welcome",
  FEEDBACKS: "/feedbacks",
  NEWS: "/news",
  CREATE_NEWS: "/create-news",
  ADMIN_EDIT: "/admin-edit",
  SUPER_ADMIN_CLUBS: "/super-admin-clubs",
  HELP_CHAT: "/help-chat",
  SUPPORT: "/support",
  TERMS: "/terms-and-conditions",
  PRIVACY_POLICY: "/privacy-policy",
  STATISTICS: "statistics",
};

const authRoutes = [
  { path: ROUTES.LOGIN, element: <LoginPage /> },
  { path: ROUTES.FORGOT, element: <ForgotPasswordPage /> },
  { path: ROUTES.SUPPORT, element: <SupportPage /> },
  { path: ROUTES.TERMS, element: <TermsPage /> },
  { path: ROUTES.PRIVACY_POLICY, element: <PrivacyPolicyPage /> },
  { path: "*", element: <Navigate to={ROUTES.LOGIN} replace /> },
];

const routes = [
  { path: `${ROUTES.CLUB_MENU}/:id`, element: <ClubMenuPage /> },
  { path: ROUTES.CREATE_CLUB, element: <CreateClubPage /> },
  { path: `${ROUTES.CREATE_USER}/:role?`, element: <CreateUserPage /> },
  { path: ROUTES.PROFILE, element: <ProfilePage /> },
  { path: `${ROUTES.CLUB_PROFILE}/:id`, element: <ClubProfilePage /> },
  { path: ROUTES.WELCOME, element: <WelcomePage /> },
  { path: `${ROUTES.FEEDBACKS}/:clubId?`, element: <FeedbacksPage /> },
  { path: `${ROUTES.NEWS}/:id`, element: <NewsPage /> },
  { path: ROUTES.CREATE_NEWS, element: <CreateNewsPage /> },
  { path: `${ROUTES.ADMIN_EDIT}/:id/:catType`, element: <AdminEditPage /> },
  { path: ROUTES.SUPER_ADMIN_CLUBS, element: <SuperAdminClubsPage /> },
  { path: `${ROUTES.HELP_CHAT}/:clubId?`, element: <HelpChatPage /> },
  // { path: ROUTES.STATISTICS, element: <StatisticsPage /> },
  { path: "*", element: <Navigate to={ROUTES.WELCOME} replace /> },
];

const AppNavigation = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { isGetMessagesListLoading } = useLoadings();
  const [startListening, setStartListening] = useState(false);
  const { Staff } = useRoles();
  const { token, me, loginReason } = useSelector((s: RootState) => s.auth);

  let allowLogin = false;

  if (me && token) {
    switch (me.role) {
      case UserRole.SuperAdmin:
        allowLogin = true;
        break;
      case UserRole.Moderator:
        allowLogin = true;
        break;
      case UserRole.ClubOwner:
        allowLogin = true;
        break;
      case UserRole.ManagerAdmin:
        allowLogin = true;
        break;
      case UserRole.Staff:
        allowLogin = !!loginReason;
        break;
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (token) {
      // @ts-ignore
      dispatch(getMe());
    }
  }, [token]);

  useEffect(() => {
    if (token && !startListening) {
      const onSuccess = () => setStartListening(true);
      // @ts-ignore
      dispatch(getMessagesList(undefined, undefined, onSuccess, undefined));
    }
  }, [token]);

  useEffect(() => {
    if (startListening) {
      setInterval(() => {
        const onSuccess = () => setStartListening(false);
        if (!isGetMessagesListLoading) {
          // @ts-ignore
          dispatch(getMessagesList(undefined, undefined, onSuccess, true));
        }
      }, 10000);
    }
  }, [startListening]);

  console.log("tokenzzz", token);

  return (
    <Routes>
      {token
        ? routes.map((el, i) => <Route {...el} key={`ROUTES-${i}`} />)
        : authRoutes.map((el, i) => <Route {...el} key={`AUTH-${i}`} />)}
    </Routes>
  );
};

export default AppNavigation;
