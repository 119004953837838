import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../store/store";
import { baseUrl } from "../constants";
import { loadingEnd, loadingStart, LoadingSubject } from "./loadingSlice";
import { makeTransaction } from "./depositSlice";
import qs from "qs";
import { UserRole } from "./usersSlice";
import toast from "react-hot-toast";

export enum CardStyleType {
  "full",
  "standard",
  "small",
  "tiny",
}

export interface IEmployee {
  id: string;
  employee_id: string;
  club_id: string;
}

export interface IClubMember {
  id: string;
  full_name: string;
  email: string;
  photo_url: string;
  document_photo_url: string;
  document_number: string;
  document_country: string;
  city: string;
  birthdate: string;
  balance: number;
}

export interface IClub {
  id: string;
  name: string;
  address: string;
  color_hex: string;
  card_style_type: CardStyleType;
  show_product_amount: boolean;
  gradient_angle: number;
  picture_url: string;
  description: string;
  is_top: boolean;
  sorting: number;
}

export interface IClubSorting {
  club_id: string;
  is_top: boolean;
  sorting: number;
}

export interface IClubState {
  clubList: IClub[];
  employeeList: IEmployee[];
  membersList: IClubMember[];
  club: IClub | undefined;
}

const initialState: IClubState = {
  clubList: [],
  employeeList: [],
  membersList: [],
  club: undefined,
};

const club = createSlice({
  name: "club",
  initialState,
  reducers: {
    _getClubList: (state, { payload }: PayloadAction<IClub[]>) => {
      return { ...state, clubList: payload };
    },
    _getClub: (state, { payload }: PayloadAction<IClub>) => {
      return { ...state, club: payload };
    },
    _getClubMembers: (state, { payload }: PayloadAction<IClubMember[]>) => {
      return { ...state, membersList: payload };
    },
    _getEmployeeList: (state, { payload }: PayloadAction<IEmployee[]>) => {
      return { ...state, employeeList: payload };
    },
  },
});

export const getClubList = (
  club_ids?: string[],
  onSuccess?: (data?: IClub[]) => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.ClubList));
    const { token } = getState().auth;
    const params = { club_ids };
    const queryParams = params
      ? `?${qs.stringify(params, { arrayFormat: "repeat", encode: false })}`
      : "";
    const url = `${baseUrl}/api/club${queryParams}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonData = await response.json();
    if (response.status === 200) {
      console.log('getClubList200', jsonData)
      dispatch(_getClubList(jsonData));
      onSuccess && onSuccess(jsonData);
    } else {
    }
  } catch (e) {
  } finally {
    dispatch(loadingEnd(LoadingSubject.ClubList));
  }
};

export const createClub = (
  data: FormData,
  onSuccess?: (clubId?: string) => void
): AppThunk => async (dispatch, getState) => {
  try {
    const { me } = getState().auth;
    dispatch(loadingStart(LoadingSubject.CreateClub));
    const token = getState().auth.token;
    const response = await fetch(`${baseUrl}/api/club`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: data,
    });
    const jsonData = await response.json();
    if (response.status === 200) {
      if (
        me?.role === UserRole.ClubOwner ||
        me?.role === UserRole.ManagerAdmin ||
        me?.role === UserRole.Staff
      ) {
        dispatch(
          createEmployee(
            me?.id!,
            jsonData.id,
            () => onSuccess && onSuccess(jsonData.id)
          )
        );
      } else {
        dispatch(getEmployeeList([me?.id!], undefined, true));
        onSuccess && onSuccess(jsonData.id);
      }
    } else {
    }
  } catch (e) {
  } finally {
    dispatch(loadingEnd(LoadingSubject.CreateClub));
  }
};

export const deleteClub = (
  clubId: string,
  onSuccess?: () => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.DeleteClub));
    const token = getState().auth.token;
    const response = await fetch(`${baseUrl}/api/club/${clubId}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    const jsonData = await response.json();
    if (response.status === 200) {
      dispatch(getClubList());
      onSuccess && onSuccess();
    } else {
    }
  } catch (e) {
  } finally {
    dispatch(loadingEnd(LoadingSubject.DeleteClub));
  }
};

export const getClub = (
  clubId: string,
  callback?: () => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.GetClub));
    const token = getState().auth.token;
    const response = await fetch(`${baseUrl}/api/club/${clubId}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    const jsonData = await response.json();
    if (response.status === 200) {
      dispatch(_getClub(jsonData));
      callback && callback();
    } else {
    }
  } catch (e) {
  } finally {
    dispatch(loadingEnd(LoadingSubject.GetClub));
  }
};

type UpdateClubType = {
  name: string;
  color_hex: string;
  card_style_type: CardStyleType;
  show_product_amount: boolean;
  gradient_angle: number;
  address: string;
  description: string;
};
export const updateClub = (
  clubId: string,
  data: UpdateClubType,
  amount?: number,
  callback?: () => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.UpdateClub));
    const token = getState().auth.token;
    const response = await fetch(`${baseUrl}/api/club/${clubId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });
    const jsonData = await response.json();
    if (response.status === 200) {
      if (amount) {
        dispatch(makeTransaction({ amount: amount, club_id: clubId }));
      }
      dispatch(getClub(clubId));
      dispatch(getClubList());
      callback && callback();
    } else {
    }
  } catch (e) {
  } finally {
    dispatch(loadingEnd(LoadingSubject.UpdateClub));
  }
};

export const updateClubImage = (
  clubId: string,
  data: FormData,
  callback?: () => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.UpdateClubImage));
    const token = getState().auth.token;
    const response = await fetch(`${baseUrl}/api/club/${clubId}/picture`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: data,
    });
    const jsonData = await response.json();
    if (response.status === 200) {
      dispatch(getClub(clubId));
      callback && callback();
    } else {
    }
  } catch (e) {
  } finally {
    dispatch(loadingEnd(LoadingSubject.UpdateClubImage));
  }
};

export const getEmployeeList = (
  admin_ids?: string[],
  club_ids?: string[],
  refreshClubList?: boolean,
  onSuccess?: () => void,
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.GetEmployeeList));
    const { token } = getState().auth;
    const params = { admin_ids, club_ids };
    const queryParams = params
      ? `?${qs.stringify(params, { arrayFormat: "repeat", encode: false })}`
      : "";
    const url = `${baseUrl}/api/club/employee${queryParams}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonData = await response.json();
    if (response.status === 200) {
      dispatch(_getEmployeeList(jsonData));
      if (refreshClubList) {
        dispatch(getClubList(jsonData?.map((e: IEmployee) => e.club_id)));
      }
      onSuccess && onSuccess();
    } else {
      // 
    }
  } catch (e) {
    // 
  } finally {
    dispatch(loadingEnd(LoadingSubject.GetEmployeeList));
  }
};

export const createEmployee = (
  employee_id: string,
  club_id: string,
  onSuccess?: () => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.CreateEmployee));
    const { token, me } = getState().auth;
    const params = { employee_id, club_id };
    const response = await fetch(`${baseUrl}/api/club/employee`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(params),
    });
    const jsonData = await response.json();
    if (response.status === 200) {
      dispatch(getEmployeeList([me?.id!], undefined, true));
      toast.success('Club was successfully added to the user!')
      onSuccess && onSuccess();
    } else {
    }
  } catch (e) {
  } finally {
    dispatch(loadingEnd(LoadingSubject.CreateEmployee));
  }
};

export const deleteEmployee = (
  id: string,
  onSuccess?: () => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.DeleteEmployee));
    const { token } = getState().auth;
    const response = await fetch(`${baseUrl}/api/employee/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonData = await response.json();
    if (response.status === 200) {
      console.log('deleteEmployeeJson', jsonData)
      toast.success(`Club has been successfully\n disconnected from the user!`);
      onSuccess && onSuccess();
    } else {
      console.log('deleteEmployeeNot200', jsonData)
    }
  } catch (e) {
    console.log('deleteEmployeeErr', e)
  } finally {
    dispatch(loadingEnd(LoadingSubject.DeleteEmployee));
  }
};

export const getClubMembers = (
  clubId: string
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.GetClubMembers));
    const { token } = getState().auth;
    const url = `${baseUrl}/api/club/${clubId}/members`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonData: IClubMember[] = await response.json();
    if (response.status === 200) {
      dispatch(_getClubMembers(jsonData));
    } else {
    }
  } catch (e) {
  } finally {
    dispatch(loadingEnd(LoadingSubject.GetClubMembers));
  }
};

export const setClubSorting = (
  clubs: IClubSorting[],
  onSuccess: () => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.SetClubSorting));
    const { token } = getState().auth;
    const url = `${baseUrl}/api/club/set-top-sorting`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(clubs)
    });
    const jsonData = await response.json();
    if (response.status === 200) {
      console.log('setClubSortingJson', jsonData)
      onSuccess && onSuccess()
    } else {
      console.log('setClubSortingNot200', jsonData)
    }
  } catch (e) {
    console.log('setClubSortingErr', e)
  } finally {
    dispatch(loadingEnd(LoadingSubject.SetClubSorting));
  }
};

export const { _getClubList, _getClub, _getEmployeeList, _getClubMembers } =
  club.actions;

export default club.reducer;
