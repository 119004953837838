import React, { useEffect, useState } from "react";
import styles from "./ClubProfilePage.module.css";
import { isLoading } from "./../../utils/";
import AppText from "../../components/AppText/AppText";
import AppButton from "../../components/AppButton/AppButton";
import Icon from "../../assets/icons/svgs/Icon";
import { Grid, Rating } from "@mui/material";
import { AppColors } from "../../AppTheme";
import { useNav } from "../../hooks/useNav";
import { deleteClub, getClub } from "../../slices/clubSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../store/rootReducer";
import { LoadingSubject } from "../../slices/loadingSlice";
import EditClubForm, { handleParseAddress } from "./forms/EditClubForm";
import { getClubBalance } from "../../slices/depositSlice";
import AuthorizedLayout from "layouts/AuthorizedLayout";
import { Column } from "components/blocks";
import ModalWrapper from "components/modals/ModalWrapper";
import DeleteModal from "components/modals/DeleteModal/DeleteModal";
import { INews, getNewsList } from "slices/newsSlice";
import moment from "moment";
import parse from "html-react-parser";
import { useRoles } from "hooks/useRoles";
import { useIntl } from "react-intl";
import { IClubFeedback, getClubFeedbacksList } from "slices/feedbacksSlice";
import { useLoadings } from "hooks/useLoadings";

enum ModalStepsEnum {
  None,
  Delete,
  Edit,
  QRCode,
}

function ClubProfilePage() {
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const { viewClubMenu, viewFeedbacks, viewNews, viewCreateNews, viewWelcome } =
    useNav();

  const dispatch = useDispatch();

  const clubItem = useSelector((s: RootState) => s.club.club);
  const subjects = useSelector((s: RootState) => s.loading.subjects);
  const { balance } = useSelector((s: RootState) => s.balance);
  const { token } = useSelector((s: RootState) => s.auth);
  const { clubFeedbacksList } = useSelector((s: RootState) => s.feedbacks);

  const newsList = useSelector((s: RootState) => s.news.list);

  const { isClubFeedbacksListLoading, isDeleteClubLoading } = useLoadings();

  const isLoadingGetNewsList = isLoading(LoadingSubject.GetNewsList, subjects);
  const parsedAddress = handleParseAddress(clubItem?.address);
  const [modalStep, setModalStep] = useState<ModalStepsEnum>(
    ModalStepsEnum.None
  );
  const openDeleteModal = () => setModalStep(ModalStepsEnum.Delete);
  const openEditModal = () => setModalStep(ModalStepsEnum.Edit);
  const openQRCodeModal = () => setModalStep(ModalStepsEnum.QRCode);

  const closeModal = () => setModalStep(ModalStepsEnum.None);

  const params = useParams();

  useEffect(() => {
    // @ts-ignore
    dispatch(getClub(params.id));
    // @ts-ignore
    dispatch(getClubBalance(params.id));
    // @ts-ignore
    dispatch(getNewsList(params.id));
    // @ts-ignore
    dispatch(getClubFeedbacksList([params.id]));
  }, []);

  const renderFeedbackItem = (item: IClubFeedback, index: number) => {
    const { club_id, customer, customer_id, id, score, text, created_at } =
      item;

    const feedbackContent = parse(
      text.length > 100 ? text.slice(0, 100) + "..." : text
    );

    const customerFullName =
      customer.full_name.length > 30
        ? customer.full_name.slice(0, 30) + "..."
        : customer.full_name;

    return (
      <div className={styles["feedback-item"]}>
        <div className={styles["feedback-item-user-container"]}>
          <img
            className={styles["user-image"]}
            src={`${customer.photo_url}&token=${token}`}
            alt={"user_image"}
          />
          <div className={styles["user-desc"]}>
            <AppText variant="h4" color="white">
              {`${t("text.user")}: ${customerFullName}`}
            </AppText>
            {/* <AppText variant="body3" color="white">
              {`${t("text.order")}: ${"orderId"}`}
            </AppText> */}
          </div>
        </div>
        <AppText variant="body1" style={{ marginTop: 20 }} color="white">
          {feedbackContent}
        </AppText>
        <div className={styles["bottom-block-container"]}>
          <Rating
            style={{ opacity: 1 }}
            disabled
            value={score}
            // onChange={(event, newValue) => {}}
            size="small"
            sx={{
              "& .MuiRating-iconFilled": {
                color: AppColors.red,
              },
              "& .MuiRating-iconEmpty": {
                color: AppColors.gray2,
              },
            }}
          />
          <AppText variant={"body3"} color="white">
            {moment(created_at).format("MMMM Do YYYY, h:mm:ss a")}
          </AppText>
        </div>
      </div>
    );
  };

  const renderNewsItem = (item: INews, index: number) => {
    const newsContent = parse(
      item.content.length > 100
        ? item.content.slice(0, 100) + "..."
        : item.content
    );

    const newsTitle =
      item.title.length > 30 ? item.title.slice(0, 30) + "..." : item.title;

    return (
      <div className={styles["news-item"]}>
        <AppText color="white" variant="h4">
          {newsTitle}
        </AppText>
        <img
          className={styles["news-image"]}
          src={`${item.photo_url}&token=${token}`}
          alt={"news_item"}
        />
        <AppText style={{ marginTop: 10 }} variant="body1" color="white">
          {newsContent}
        </AppText>
        <AppText style={{ marginTop: 20 }} variant={"body4"} color="white">
          {moment(item.created_at).format("MMMM Do YYYY, h:mm:ss a")}
        </AppText>
      </div>
    );
  };

  const handleDelete = () => {
    const callback = () => {
      closeModal();
      viewWelcome();
    };
    // @ts-ignore
    dispatch(deleteClub(clubItem?.id, callback));
  };

  const renderQR = () => {
    return (
      <div>
        <AppText sx={{ textAlign: "center" }} color="black" variant="h2">
          {t("text.about-the-club")}
        </AppText>
        <img
          className={styles["qr-img"]}
          src={require("./../../assets/images/raster/qr.png")}
          alt="qr"
        />
        <AppButton
          text={t("general.close")}
          onClick={closeModal}
          sx={{
            width: "150px",
            margin: "15px auto",
            outline: "1px solid red",
            display: "flex",
          }}
        />
      </div>
    );
  };

  const roles = useRoles();

  return (
    <AuthorizedLayout>
      <Grid container spacing={8} sx={{ mt: { xs: 0, md: 10 } }}>
        <Grid item xs={12}>
          <Column>
            <div className={styles["content-header-top-container"]}>
              <div className={styles["content-header-buttons-top-container"]}>
                {roles.SuperAdmin || roles.Moderator || roles.ClubOwner ? (
                  <AppButton
                    onClick={openDeleteModal}
                    sx={{ ml: 2, mt: 1 }}
                    startIcon={<Icon name="Trash" />}
                    variant="text"
                    text={t("text.delete-club")}
                    color="error"
                  />
                ) : (
                  <div />
                )}
                <AppButton
                  onClick={openEditModal}
                  sx={{ mr: 2, mt: 1 }}
                  startIcon={<Icon name="Setting" />}
                  variant="text"
                  text={t("text.club-settings")}
                />
              </div>
              <img
                className={styles["club-profile-image"]}
                src={clubItem?.picture_url}
                alt="club_profile_image"
              />
            </div>
            <div
              style={{ position: "relative" }}
              className={styles["content-header-bottom-container"]}
            >
              <Grid container spacing={8} sx={{ p: 4 }}>
                <Grid item xs={12} md={3}>
                  <Column
                    style={{
                      height: "100%",
                      alignItems: "start",
                      justifyContent: "end",
                    }}
                  >
                    <AppButton
                      variant="text"
                      color="white1"
                      onClick={() => viewClubMenu(params.id)}
                      text={t("text.club-menu")}
                      startIcon={<Icon name="Menu" />}
                    />
                  </Column>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Column center>
                    <AppText variant="h1" color="white">
                      {clubItem?.name}
                    </AppText>

                    <AppText
                      variant="h4"
                      color="white"
                      sx={{ mt: 2, textAlign: "center" }}
                    >
                      {parsedAddress?.address}
                    </AppText>
                    {/* <Rating
                      disabled
                      value={3}
                      onChange={(event, newValue) => {}}
                      size="small"
                      sx={{
                        "& .MuiRating-iconFilled": {
                          color: AppColors.red,
                        },
                        "& .MuiRating-iconEmpty": {
                          color: AppColors.gray2,
                        },
                        mt: 2,
                      }}
                    /> */}

                    <div className={styles["deposit-container"]}>
                      <AppText variant="body1" color="white">
                        {t("text.deposit") + ":"}
                      </AppText>
                      <AppText variant="body1" color="white">
                        {`€ ${balance?.amount || 0}`}
                      </AppText>
                    </div>
                  </Column>
                </Grid>
                {/* <Grid item xs={12} md={3}>
                  <Column
                    style={{
                      height: "100%",
                      alignItems: "end",
                      justifyContent: "end",
                    }}
                  >
                    <AppButton
                      variant="text"
                      color="white1"
                      onClick={openQRCodeModal}
                      text={"About the Club"}
                      startIcon={<Icon name="QrCode" />}
                    />
                  </Column>
                </Grid> */}
              </Grid>
            </div>
          </Column>
        </Grid>
      </Grid>

      <div className={styles["items-1-block-container"]}>
        <div className={styles["items-1-header-container"]}>
          <AppText variant="h2" color="white">
            {"Last Feedbacks"}
          </AppText>
          <AppButton
            onClick={() => viewFeedbacks(clubItem?.id)}
            text={"All feedbacks"}
          />
        </div>
        {!isClubFeedbacksListLoading && !clubFeedbacksList.length ? (
          <AppText
            sx={{ alignSelf: "center", marginTop: 5 }}
            variant="h3"
            color="gray3"
          >
            {t("No feedbacks found...")}
          </AppText>
        ) : (
          <Grid container sx={{ mt: 1 }} spacing={8}>
            {clubFeedbacksList?.slice(0, 2)?.map((item, index) => (
              <Grid item xs={12} md={4}>
                {renderFeedbackItem(item, index)}
              </Grid>
            ))}
          </Grid>
        )}
      </div>

      <div className={styles["items-2-block-container"]}>
        <div className={styles["items-2-header-container"]}>
          <AppText variant="h2" color="white">
            {t("menu-tabs-name.news")}
          </AppText>
          <div className={styles["news-button-container"]}>
            <AppButton
              onClick={() => viewNews(clubItem?.id)}
              variant="contained"
              text={t("text.all-news")}
            />
          </div>
        </div>
        {!isLoadingGetNewsList && !newsList.length ? (
          <AppText
            sx={{
              alignSelf: "center",
              marginTop: 5,
            }}
            variant="h3"
            color="gray3"
          >
            {t("text.no-news-found") + "..."}
          </AppText>
        ) : (
          <Grid container spacing={8} sx={{ mt: 1 }}>
            {newsList?.slice(0, 3)?.map((item, index) => (
              <Grid item xs={12} md={4}>
                {renderNewsItem(item, index)}
              </Grid>
            ))}
          </Grid>
        )}
      </div>

      <DeleteModal
        open={modalStep === ModalStepsEnum.Delete}
        loading={isDeleteClubLoading}
        onClose={closeModal}
        onDelete={handleDelete}
        title={t("text.delete-club")}
        desc={t("text.delete-club-desc")}
      />
      <ModalWrapper
        open={
          modalStep === ModalStepsEnum.Edit ||
          modalStep === ModalStepsEnum.QRCode
        }
        onClose={closeModal}
        maxWidth="750px"
      >
        {modalStep === ModalStepsEnum.Edit && (
          <EditClubForm closeModal={closeModal} clubItem={clubItem} />
        )}
        {modalStep === ModalStepsEnum.QRCode && renderQR()}
      </ModalWrapper>
    </AuthorizedLayout>
  );
}
export default ClubProfilePage;
