import AppText from "../../../../components/AppText/AppText";
import styles from "./QrScanner.module.css";
import { Html5QrcodeScan } from "./Html5QrcodeScanner";
import { IOrder, getOrderById } from "../../../../slices/ordersSlice";
import { useDispatch } from "react-redux";
import ModalWrapper from "components/modals/ModalWrapper";
import { useIntl } from "react-intl";

type Props = {
  open: boolean;
  onClose: () => void;
  setSelectedOrder: (order: IOrder) => void;
};

export default function QrScannerModal(props: Props) {
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const { open, onClose, setSelectedOrder } = props;
  const dispatch = useDispatch();

  const onNewScanResult = (decodedText: string, decodedResult: any) => {
    type QRDataType = { data: { id: string }; type: string };
    const qrData: QRDataType = JSON.parse(decodedText);
    const orderId = qrData.data.id;

    const onSuccess = (order: IOrder) => {
      setSelectedOrder(order);
      onClose();
    };
    // @ts-ignore
    dispatch(getOrderById(orderId, onSuccess));
  };

  return (
    <ModalWrapper open={open} onClose={onClose} maxWidth="390px">
      <div className={styles["qr-result-container"]}>
        <AppText variant="h3">{t("text.qr-code-scanner-result") + ":"}</AppText>
        <AppText sx={{ mt: 1 }} variant="body3">
          {t("text.no-result")}
        </AppText>
      </div>
      <Html5QrcodeScan
        qrCodeSuccessCallback={onNewScanResult}
        qrCodeErrorCallback={() => {}}
      />
    </ModalWrapper>
  );
}
