import NewTable from "../../../../components/NewTable/NewTable";
import { IOrder } from "../../../../slices/ordersSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/rootReducer";
import { isLoading } from "../../../../utils";
import { LoadingSubject } from "../../../../slices/loadingSlice";
import ModalWrapper from "components/modals/ModalWrapper";

type Props = {
  open: boolean;
  onClose: () => void;
  ordersList: IOrder[];
  returnOrderId: (id: string) => void;
};

export default function OrdersModalTable(props: Props) {
  const { open, onClose, ordersList, returnOrderId } = props;

  let headCells = ["Customer", "Products count", "Status"];
  let rowsData: string[][] = [];

  const subjects = useSelector((s: RootState) => s.loading.subjects);
  const isLoadingCancelOrder = isLoading(LoadingSubject.CancelOrder, subjects);

  ordersList?.map((order) =>
    rowsData.push([
      "id_" + order.id,
      order.customer_fullname,
      order.products?.length.toString(),
      order.status,
    ])
  );

  return (
    <ModalWrapper open={open} onClose={onClose}>
      <NewTable
        loading={isLoadingCancelOrder}
        headCells={headCells}
        rows={rowsData}
        actionButton={{
          onClick: (id: string) => {
            onClose();
            returnOrderId(id);
          },
          title: "Select",
        }}
      />
    </ModalWrapper>
  );
}
