import React, { useCallback } from "react";
import styles from "./AppSelect.module.css";
import { useFormikContext } from "formik";
import ErrorText from "../../forms/ErrorText";

type Props = {
  name: string;
  data: { title: string; value: string | number }[];
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onChangeCallback?: (value?: string) => void;
  placeholder?: string;
  isForm?: boolean;
  classNameContainer?: string;
  classNameSelect?: string;
  errorColor?: string;
  isErrorHidden?: boolean;
  noCheck?: boolean;
  disabled?: boolean;
};

const s = (className: string) => styles[className];

function AppSelect(props: Props) {
  const {
    name,
    data,
    value,
    onChange,
    onChangeCallback,
    placeholder,
    isForm,
    classNameContainer,
    classNameSelect,
    errorColor,
    isErrorHidden,
    noCheck,
    disabled,
  } = props;

  const formikHook = useFormikContext();
  let values: any, setFieldValue: any, errors: any, touched: any;

  if (isForm) {
    values = formikHook.values;
    setFieldValue = formikHook.setFieldValue;
    errors = formikHook.errors;
    touched = formikHook.touched;
  }

  let fValue: any, fError: any, fVisible: any;

  if (isForm) {
    fValue = values[name];
    fError = errors[name];
    fVisible = touched[name];
  }

  const onChangeFormik = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const text = e.target.value || "";
      setFieldValue(name, text);
    },
    [name, setFieldValue]
  );

  return (
    <div>
      <div
        className={`${s("select-container-default")} ${
          classNameContainer || ""
        }`}
      >
        <select
          disabled={disabled}
          className={`${s("select-default")} ${classNameSelect || ""}`}
          name={name}
          value={isForm ? fValue : value}
          onChange={(e) => {
            if (isForm) {
              onChangeFormik(e);
            } else if (onChange) {
              onChange(e);
            }
            onChangeCallback && onChangeCallback(e.target.value);
          }}
        >
          {placeholder && (
            <option className={s("placeholder-default")} value="" selected>
              {placeholder}
            </option>
          )}
          {data.map((o) => (
            <option value={o.value}>{o.title}</option>
          ))}
        </select>
      </div>
      {!isErrorHidden && !noCheck && (
        <ErrorText error={fError} visible={fVisible} errorColor={errorColor} />
      )}
    </div>
  );
}

export default AppSelect;
