import AppForm from "../../../forms/AppForm";
import { FormikValues } from "formik";
import { useSchemas } from "../../../helpers/schemas";
import Icon from "../../../assets/icons/svgs/Icon";
import AppInput from "../../../components/AppInput/AppInput";
import styles from "./CreateClubForm.module.css";
import AppButton from "../../../components/AppButton/AppButton";
import { AppColors } from "../../../AppTheme";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createClub } from "../../../slices/clubSlice";
import { RootState } from "../../../store/rootReducer";
import { isLoading } from "../../../utils";
import { LoadingSubject } from "../../../slices/loadingSlice";
import { useNav } from "../../../hooks/useNav";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { Row } from "components/blocks";
import { useIntl } from "react-intl";
import { handleParseAddress } from "pages/ClubProfilePage/forms/EditClubForm";
import { baseUrl } from "./../../../constants";

type Props = {
  onClose?: () => void;
  addCreatedClubId?: (clubId: string) => void;
};

function CreateClubForm(props: Props) {
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const { onClose, addCreatedClubId } = props;
  const { createClubSchema } = useSchemas();
  const dispatch = useDispatch();
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [image, setImage] = useState<any>({ preview: "", raw: "" });
  const subjects = useSelector((s: RootState) => s.loading.subjects);
  const { viewGoBack } = useNav();
  const { token } = useSelector((s: RootState) => s.auth);

  //-------------------------------------------------------------------------------------------
  const parsedAddress = handleParseAddress("");
  const [addressState, setAddressState] = useState(parsedAddress);

  const [inputAddressValue, setInputAddressValue] = useState(
    parsedAddress.address
  );
  const [loading, setLoading] = useState(false);
  const [addressList, setAddressList] = useState([]);

  useEffect(() => {
    if (inputAddressValue) {
      const timeoutId = setTimeout(
        () => getLocationByQuery(inputAddressValue),
        800
      );
      return () => clearTimeout(timeoutId);
    }
  }, [inputAddressValue]);

  const getLocationByQuery = async (textQuery: string) => {
    if (!textQuery) {
      return;
    }
    try {
      setLoading(true);
      const url = `${baseUrl}/api/google/places/search?query=${textQuery}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      if (response?.status === 200) {
        const jsonData = await response.json();
        const addressList = jsonData?.results.map((el: any) => ({
          lat: el?.geometry?.location?.lat,
          lng: el?.geometry?.location?.lng,
          address: el?.formatted_address,
        }));
        setAddressList(addressList);
      }
    } catch (error) {
      console.error("getLocationByQueryERR", error);
    } finally {
      setLoading(false);
    }
  };
  //-------------------------------------------------------------------------------------------

  const onSubmit = (values: FormikValues) => {
    const formData = new FormData();
    formData.append("picture", image.raw);
    formData.append("name", values.name);
    formData.append("address", JSON.stringify(addressState));
    formData.append("color_hex", "#ffffff");
    formData.append("card_style_type", "full");
    formData.append("show_product_amount", "false");
    formData.append("gradient_angle", "0");
    formData.append("description", values.description);

    const onSuccess = (clubId?: string) => {
      onClose ? onClose() : viewGoBack();
      addCreatedClubId && clubId ? addCreatedClubId(clubId) : (() => {})();
    };

    // @ts-ignore
    dispatch(createClub(formData, onSuccess));
  };

  const onImageClick = () => {
    inputFile?.current?.click();
  };

  const onCancel = () => {
    onClose ? onClose() : viewGoBack();
  };

  return (
    <AppForm
      initialValues={{ name: "", address: "", description: "" }}
      onSubmit={onSubmit}
      children={
        <Grid container>
          <Grid item xs={12} sm={6}>
            <div onClick={onImageClick} className={styles["left-container"]}>
              {image.preview ? (
                <img
                  className={styles["image-style"]}
                  src={image.preview}
                  alt="club-img"
                />
              ) : (
                <Icon name="Plus" />
              )}
              <input
                autoComplete="off"
                onChange={(event) => {
                  const files = event?.target?.files;
                  if (files?.length) {
                    const image = files[0];
                    setImage({
                      preview: URL.createObjectURL(image),
                      raw: image,
                    });
                  }
                }}
                accept="image/*"
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: "none" }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppInput
              name="name"
              classNameInput={styles["input-style"]}
              classNameContainer={styles["input-container-style"]}
              placeholder={t("text.club-name")}
              errorColor={AppColors.red}
              isForm
            />
            <Autocomplete
              options={addressList}
              filterOptions={(options) => options}
              getOptionLabel={(option: { address: string }) => option?.address}
              value={addressState}
              onChange={(event, value: any) => {
                if (value) {
                  setAddressState(value);
                }
              }}
              loading={loading}
              inputValue={inputAddressValue}
              onInputChange={(event, newInputValue) => {
                setInputAddressValue(newInputValue);
              }}
              onClose={() => {
                setInputAddressValue("");
                setAddressList([]);
              }}
              renderOption={(props, option) => (
                <li {...props}>{option?.address}</li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="address"
                  placeholder={t("text.address")}
                  variant="standard"
                />
              )}
            />
            <AppInput
              name="description"
              textAreaClassname={styles["description-area"]}
              placeholder={t("desc")}
              errorColor={AppColors.red}
              isForm
              isTextArea
              maxLength={256}
              style={{
                width: "90%",
                marginTop: 10,
                height: 180,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Row
              sx={{ gap: { xs: 4, md: 8 } }}
              className={styles["buttons-container"]}
            >
              <AppButton
                sx={{ width: 200 }}
                disabled={!image.preview}
                isForm
                text={t("general.save")}
                loading={isLoading(LoadingSubject.CreateClub, subjects)}
              />
              <AppButton
                onClick={onCancel}
                sx={{ width: 200 }}
                variant="outlined"
                color="error"
                text={t("general.cancel")}
              />
            </Row>
          </Grid>
        </Grid>
      }
      validationSchema={createClubSchema}
      enableReinitialize={true}
    />
  );
}
export default CreateClubForm;
