import storage from 'redux-persist/lib/storage';
import { configureStore, Action } from '@reduxjs/toolkit';
import thunk, { ThunkAction } from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import rootReducer, { RootState } from './rootReducer';
import autoMerge from 'redux-persist/lib/stateReconciler/autoMergeLevel1';

const persistConfig = {
    key: 'root',
    version: 7,
    storage: storage,
    whitelist: ['auth'],
    blacklist: [],
    stateReconciler: autoMerge,
};

const store = configureStore({
    // @ts-ignore
    reducer: persistReducer(persistConfig, rootReducer),
    middleware: [thunk],
});

const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export { store, persistor };
