import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../store/store";
import { baseUrl } from "../constants";
import { loadingEnd, loadingStart, LoadingSubject } from "./loadingSlice";
import { UserRole } from "./usersSlice";
import toast from "react-hot-toast";

export enum LoginReasonEnum {
  Shift = 1,
  NeedLogin,
}

export interface IMe {
  id: string;
  role: UserRole;
  email: string;
  first_name: string;
  last_name: string;
}

interface IAuthState {
  token: string;
  me?: IMe;
  loginReason?: LoginReasonEnum;
}

const initialState: IAuthState = {
  token: "",
};

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    _login: (state, { payload }: PayloadAction<IAuthState>) => {
      return { ...state, token: payload.token };
    },
    _logout: () => {
      return initialState;
    },
    _getMe: (state, { payload }: PayloadAction<IMe>) => {
      return { ...state, me: payload };
    },
    _loginReason: (state, { payload }: PayloadAction<LoginReasonEnum>) => {
      return { ...state, loginReason: payload };
    },
  },
});

type LoginData = {
  login: string;
  password: string;
};
export const login = (
  loginData: LoginData
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.Login));
    const response = await fetch(`${baseUrl}/api/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginData),
    });
    const jsonData = await response.json();

    if (response.status === 200) {
      dispatch(_login(jsonData));
    } else {
      toast.error("Login or password is incorrect");
    }
  } catch (e) {
    toast.error("Something went wrong, please check your internet connection");
  } finally {
    dispatch(loadingEnd(LoadingSubject.Login));
  }
};

export const getMe = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.GetMe));
    const { token } = getState().auth;

    const response = await fetch(`${baseUrl}/api/admin/me`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonData: IMe = await response.json();

    if (response.status === 200) {
      dispatch(_getMe(jsonData));
    } else {
    }
  } catch (e) {
  } finally {
    dispatch(loadingEnd(LoadingSubject.GetMe));
  }
};

export const forgot = (
  email: string,
  onSuccess?: () => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.Forgot));
    const response = await fetch(`${baseUrl}/api/admin/reset-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
    const jsonData = await response.json();

    if (response.status === 200) {
      console.log('forgotJson', jsonData);
      onSuccess && onSuccess();
      toast.success("Your password has been reset, check your email for a new password", { duration: 10000 });
    } else {
      console.log('forgotNot200', jsonData);
      toast.error("Something went wrong, please try later");
    }
  } catch (e) {
    console.log('forgotErr', e);
    toast.error("Something went wrong, please check your internet connection");
  } finally {
    dispatch(loadingEnd(LoadingSubject.Forgot));
  }
};

export const support = (
  email: string,
  text: string,
  onSuccess?: () => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.Support));
    const response = await fetch(`${baseUrl}/api/support/message`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, text }),
    });
    const jsonData = await response.json();

    if (response.status === 200) {
      console.log('supportJson', jsonData);
      toast.success("Your message has been successfully send to support", { duration: 10000 });
      onSuccess && onSuccess();
    } else {
      console.log('supportNot200', jsonData);
      toast.error("Something went wrong, please try later");
    }
  } catch (e) {
    console.log('supportErr', e);
    toast.error("Something went wrong, please check your internet connection");
  } finally {
    dispatch(loadingEnd(LoadingSubject.Support));
  }
};

export const { _login, _logout, _getMe, _loginReason } = auth.actions;

export default auth.reducer;
