import { Fragment, useState } from "react";
import styles from "./Products.module.css";
import Icon from "../../../assets/icons/svgs/Icon";
import AppButton from "../../../components/AppButton/AppButton";
import { AppColors } from "../../../AppTheme";
import AppText from "../../../components/AppText/AppText";
import cn from "classnames";
import ProductModal from "../Modals/ProductModal/ProductModal";
import {
  IProduct,
  deleteProduct,
  deleteSelectedProducts,
} from "../../../slices/productSlice";
import { RootState } from "../../../store/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { isLoading } from "../../../utils";
import { LoadingSubject } from "../../../slices/loadingSlice";
import DeleteModal from "../../../components/modals/DeleteModal/DeleteModal";
import { CircularProgress, Grid } from "@mui/material";
import { Row } from "components/blocks";
import { useRoles } from "hooks/useRoles";
import { useIntl } from "react-intl";

type Props = {
  products: IProduct[];
  categoryId: string;
  //
  catId: string;
};

enum ModalEnum {
  None,
  DeleteSelected,
  Product,
  DeleteProduct,
}

const s = (className: string) => styles[className];

function Products(props: Props) {
  const {
    products,
    categoryId,
    //
    catId,
  } = props;
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const [isDeleteMode, setDeleteMode] = useState(false);

  const [deleteList, setDeleteList] = useState<string[]>([]);

  const roles = useRoles();

  const dispatch = useDispatch();

  const token = useSelector((s: RootState) => s.auth.token);
  const [productItem, setProductItem] = useState<IProduct | undefined>();
  const [smallCard, setSmallCard] = useState(false);
  const [isModal, setModal] = useState(ModalEnum.None);

  const subjects = useSelector((s: RootState) => s.loading.subjects);

  const isLoadingDeleteProduct = isLoading(
    LoadingSubject.DeleteProduct,
    subjects
  );
  const isLoadingDeleteSelectedProducts = isLoading(
    LoadingSubject.DeleteSelectedProducts,
    subjects
  );

  const isLoadingProductList = isLoading(LoadingSubject.ProductList, subjects);

  const openProductModal = () => setModal(ModalEnum.Product);
  const openDeleteModal = () => setModal(ModalEnum.DeleteProduct);
  const openDeleteSelectedModal = () => setModal(ModalEnum.DeleteSelected);

  const onProductDelete = () => {
    // @ts-ignore
    dispatch(deleteProduct(productItem.id, categoryId, closeModal));
  };

  const closeModal = () => {
    setModal(ModalEnum.None);
    setProductItem(undefined);
  };

  const toggleSelected = () => {
    setDeleteMode(!isDeleteMode);
    setDeleteList([]);
  };

  const deleteSelected = () => {
    const onSuccess = () => {
      toggleSelected();
      closeModal();
    };
    // @ts-ignore
    dispatch(deleteSelectedProducts(deleteList, categoryId, onSuccess));
  };

  const toDeleteCount = deleteList.length;

  const renderAddProductItem = () => {
    const containerStyle2 = cn(
      s("add-product-item"),
      smallCard && s("add-product-item-small")
    );

    const leftStyle = cn(
      s("add-product-left"),
      smallCard && s("add-product-left-small")
    );

    return (
      <div onClick={openProductModal} className={containerStyle2}>
        <div className={leftStyle}>
          <Icon className={s("plus-icon")} name="Plus" />
        </div>
        {!smallCard && (
          <div className={s("add-product-right")}>
            <AppText color="gray1" variant="h4">
              {t("text.add-new-product")}
            </AppText>
          </div>
        )}
      </div>
    );
  };

  const renderProductItem = (item: IProduct) => {
    const isDelItem = deleteList.find((d) => d === item.id);

    const bold = { fontFamily: "Malgun-Bold" };
    const regular = { fontFamily: "Malgun-Regular" };

    const markToDelete = () => {
      const newList = [...deleteList];

      if (isDelItem) {
        setDeleteList(newList.filter((d) => d !== item.id));
      } else if (isDeleteMode) {
        newList.push(item.id);
        setDeleteList(newList);
      }
    };

    return (
      <Grid item xs={!smallCard ? 12 : 6} md={!smallCard ? 6 : 3}>
        <div
          onClick={markToDelete}
          style={isDeleteMode ? { cursor: "pointer" } : {}}
          className={cn(
            s("product-item"),
            isDeleteMode && isDelItem && s("item-delete"),
            smallCard && s("product-item-small")
          )}
        >
          {smallCard ? (
            <img
              className={s("product-image-small")}
              src={item.image_url + "&token=" + token}
              alt={item.id.toString()}
            />
          ) : (
            <div
              style={{ backgroundColor: AppColors.gray2 }}
              className={cn(s("product-left"))}
            >
              <img
                className={s("product-image")}
                src={item.image_url + "&token=" + token}
                alt={item.id.toString()}
              />
            </div>
          )}
          <div
            className={cn(
              s("product-right"),
              smallCard && s("product-right-small")
            )}
          >
            <AppText
              variant={smallCard ? "body2" : "h4"}
              color={smallCard ? "white" : "black"}
              sx={smallCard ? { mt: 2.5 } : {}}
            >
              {item.name.length > 18
                ? item.name.slice(0, 18) + "..."
                : item.name}
            </AppText>
            {!smallCard && (
              <AppText sx={{ fontSize: 12, mt: 1 }} variant="body3">
                {item.description.length > 100
                  ? item.description.slice(0, 100) + "..."
                  : item.description}
              </AppText>
            )}
            {!smallCard && <div style={{ marginTop: 10 }} />}
            <div
              className={cn(
                s("desc-container"),
                smallCard && s("desc-container-small")
              )}
            >
              <AppText
                sx={smallCard ? regular : {}}
                variant={smallCard ? "body4" : "body3"}
                color={smallCard ? "white" : "black"}
              >
                {`${t("text.amount")}: `}
              </AppText>
              <AppText
                sx={bold}
                variant={smallCard ? "body4" : "body3"}
                color={smallCard ? "white" : "black"}
              >
                {`${item.amount} (g)`}
              </AppText>
            </div>

            <div
              className={cn(
                s("desc-container"),
                smallCard && s("desc-container-small")
              )}
            >
              <AppText
                sx={smallCard ? regular : {}}
                variant={smallCard ? "body4" : "body3"}
                color={smallCard ? "white" : "black"}
              >
                {`${t("text.price")}: `}
              </AppText>
              <AppText
                sx={bold}
                variant={smallCard ? "body4" : "body3"}
                color={smallCard ? "white" : "black"}
              >
                {`€${item.price} (per g)`}
              </AppText>
            </div>

            <AppButton
              onClick={() => {
                setProductItem(item);
                openProductModal();
              }}
              sx={{ alignSelf: "flex-end" }}
              text={t("general.edit")}
            />
          </div>
        </div>
      </Grid>
    );
  };

  const renderLoader = () => (
    <CircularProgress
      sx={{ display: "flex", margin: "0 auto", marginTop: "50px" }}
    />
  );

  return (
    <Fragment>
      <Row wrap sx={{ mt: 8, gap: 8 }}>
        {/* <AppInput
          isErrorHidden
          classNameContainer={s("input-container")}
          classNameInput={s("input-element")}
          customIcon={<Icon name="Search" />}
          style={{ color: AppColors.gray1 }}
          name="search"
          placeholder={t("general.search")}
        /> */}

        <div />

        <div>
          <AppButton
            onClick={() => setSmallCard(!smallCard)}
            text={"Change card style"}
            sx={{ mr: 3 }}
          />
          {!roles.Staff && (
            <AppButton
              onClick={
                isDeleteMode && toDeleteCount
                  ? openDeleteSelectedModal
                  : toggleSelected
              }
              sx={{ borderRadius: 10 }}
              variant={isDeleteMode ? "contained" : "outlined"}
              color={isDeleteMode ? "error" : "white1"}
              text={
                isDeleteMode
                  ? `${
                      toDeleteCount
                        ? `${t("general.delete")} (${toDeleteCount})`
                        : t("general.cancel")
                    }`
                  : t("general.select")
              }
            />
          )}
        </div>
      </Row>

      <Grid container spacing={8}>
        {!roles.Staff && (
          <Grid item xs={!smallCard ? 12 : 6} md={!smallCard ? 6 : 3}>
            {renderAddProductItem()}
          </Grid>
        )}
        {isLoadingProductList && renderLoader()}
        {products?.map((item) => renderProductItem(item))}
      </Grid>

      <ProductModal
        open={isModal === ModalEnum.Product}
        categoryId={categoryId}
        onClose={closeModal}
        openDeleteModal={openDeleteModal}
        productItem={productItem}
        catId={catId}
      />

      <DeleteModal
        loading={isLoadingDeleteProduct}
        open={isModal === ModalEnum.DeleteProduct}
        onClose={closeModal}
        onDelete={onProductDelete}
        title={t("text.delete-product") + "?"}
        desc={t("text.delete-product-desc")}
      />

      <DeleteModal
        loading={isLoadingDeleteSelectedProducts}
        open={isModal === ModalEnum.DeleteSelected}
        onClose={closeModal}
        onDelete={deleteSelected}
        title={t("text.delete-selected")}
        desc={t("text.delete-selected-desc")}
      />
    </Fragment>
  );
}

export default Products;
