import React from "react";
import Roundy from "roundy";
import styled from "@emotion/styled";
import { Box, Button, Grid, Theme } from "@mui/material";
import {
  Center,
  Column,
  DesktopView,
  DividedContent,
  MobileView,
  Row,
} from "components/blocks";
import AppText from "components/AppText/AppText";
import palette from "theme/palette";
import ModalWrapper from "components/modals/ModalWrapper";
import { useIntl } from "react-intl";

export interface BuyProModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  tariffStep: any;
  setTariffStep: (step: number) => void;
}

const BuyProModal: React.FC<BuyProModalProps> = ({
  open,
  onClose,
  onSubmit,
  tariffStep,
  setTariffStep,
}) => {
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const content = {
    title: t("pro-modal.title"),
    description: t(`pro-modal.desc`),
  };

  return (
    <ModalWrapper open={open} onClose={onClose}>
      <Box>
        <DesktopView>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={7}>
              <Column>
                <AppText variant="h1">{`Buy ${content.title}`}</AppText>
                <Center sx={{ width: "100%", position: "relative" }}>
                  <Roundy
                    style={{ marginTop: 50 }}
                    value={tariffStep}
                    onChange={(step: number) => setTariffStep(step)}
                    stepSize={25}
                    rotationOffset={-25}
                    radius={160}
                    color={palette.primary.main}
                    arcSize={230}
                    strokeWidth={15}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 80,
                      left: "calc(50% - 70px)",
                      textAlign: "center",
                      maxWidth: 150,
                    }}
                  >
                    <AppText variant={"body3"} color={"gray3"}>
                      {t("pro-modal.question") + "?"}
                    </AppText>
                    <AppText variant={"h1"}>{tariffStep}</AppText>
                    <AppText color={"gray3"} variant={"body2"}>
                      {t("pro-modal.units-per-day")}
                    </AppText>
                  </Box>
                </Center>
              </Column>
            </Grid>
            <Grid item xs={12} lg={5}>
              <Column>
                <AppText variant="body3" color="gray3" sx={{ mb: 8 }}>
                  {content.description}
                </AppText>
                <AppText variant="body4">{t("pro-modal.pricing-plan")}</AppText>

                <DividedContent>
                  <Row start>
                    <DotPrimary />
                    <AppText variant="h3">{content.title}</AppText>
                  </Row>
                </DividedContent>

                <Row start sx={{ display: "inline-flex", gap: 3 }}>
                  <AppText variant="h1">{tariffStep * 2}</AppText>
                  <AppText variant="h3" color="gray2">
                    {t("pro-modal.per-month")}
                  </AppText>
                </Row>

                <DividedContent>
                  <FeaturedList>
                    <li>{t("pro-modal.number-of-publications")}</li>
                    <li>{t("pro-modal.available-styles")}</li>
                    <li>{t("pro-modal.advantages")}</li>
                  </FeaturedList>
                </DividedContent>

                <Row start sx={{ gap: 4, mt: 4 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}
                  >
                    {t("general.buy")}
                  </Button>
                  <Button variant="outlined" color="error" onClick={onClose}>
                    {t("general.cancel")}
                  </Button>
                </Row>
              </Column>
            </Grid>
          </Grid>
        </DesktopView>

        <MobileView>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Column>
                <DividedContent>
                  <Row start>
                    <DotPrimary />
                    <AppText variant="h3">{content.title}</AppText>
                  </Row>
                </DividedContent>
                <Center sx={{ width: "100%", position: "relative" }}>
                  <Roundy
                    style={{ marginTop: 50 }}
                    value={tariffStep}
                    onChange={(step: number) => setTariffStep(step)}
                    stepSize={25}
                    rotationOffset={-25}
                    radius={120}
                    color={palette.primary.main}
                    arcSize={230}
                    strokeWidth={15}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 80,
                      left: "calc(50% - 70px)",
                      textAlign: "center",
                      maxWidth: 150,
                    }}
                  >
                    <AppText variant={"body3"} color={"gray3"}>
                      {t("pro-modal.question") + "?"}
                    </AppText>
                    <AppText variant={"h1"}>{tariffStep}</AppText>
                    <AppText color={"gray3"} variant={"body2"}>
                      {t("pro-modal.units-per-day")}
                    </AppText>
                  </Box>
                </Center>
                <Row start sx={{ display: "inline-flex", gap: 3 }}>
                  <AppText variant="h1">{tariffStep * 2}</AppText>
                  <AppText variant="h3" color="gray2">
                    {t("pro-modal.per-month")}
                  </AppText>
                </Row>
              </Column>
            </Grid>
            <Grid item xs={12}>
              <Column>
                <AppText variant="body3" color="gray3" sx={{ mb: 8 }}>
                  {content.description}
                </AppText>
                <DividedContent>
                  <FeaturedList>
                    <li>{t("pro-modal.number-of-publications")}</li>
                    <li>{t("pro-modal.available-styles")}</li>
                    <li>{t("pro-modal.advantages")}</li>
                  </FeaturedList>
                </DividedContent>
                <Row start sx={{ gap: 4, mt: 4 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}
                  >
                    {t("general.buy")}
                  </Button>
                  <Button variant="outlined" color="error" onClick={onClose}>
                    {t("general.cancel")}
                  </Button>
                </Row>
              </Column>
            </Grid>
          </Grid>
        </MobileView>
      </Box>
    </ModalWrapper>
  );
};

export default BuyProModal;

export const FeaturedList = styled("ul")(({ theme }: { theme?: Theme }) => ({
  color: theme?.palette?.gray3?.main,
  fontFamily: "Malgun-Regular",
  fontSize: "0.8rem",
}));

export const DotPrimary = styled(Box)(({ theme }: { theme?: Theme }) => ({
  height: 20,
  width: 20,
  borderRadius: "50%",
  backgroundColor: theme?.palette?.primary?.main,
  marginRight: 10,
}));
