import CreateClubForm from "./forms/CreateClubForm";
import AuthorizedLayout from "layouts/AuthorizedLayout";

function CreateClubPage() {
  return (
    <AuthorizedLayout noBackground>
      <CreateClubForm />
    </AuthorizedLayout>
  );
}

export default CreateClubPage;
