import { Column } from "components/blocks";
import UnauthorizedLayout from "layouts/UnauthorizedLayout";
import { termsText } from "./termsText";
import { AppColors } from "AppTheme";

function TermsPage() {
  return (
    <UnauthorizedLayout>
      <Column>
        <pre
          style={{ color: AppColors.white, alignSelf: "center", fontSize: 13 }}
        >
          {termsText}
        </pre>
      </Column>
    </UnauthorizedLayout>
  );
}
export default TermsPage;
