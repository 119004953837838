import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "store/store";
import { LoadingSubject, loadingEnd, loadingStart } from "./loadingSlice";
import { baseUrl } from "../constants";
import qs from "qs";
import toast from "react-hot-toast";

export interface IComment {
  text: string;
  news_id: string;
  author: {
    full_name: string;
    photo_url: string;
    id: string
  };
  id: string;
  created_at: string;
}

export interface ICommentsState {
  commentsList: IComment[];
}

const initialState: ICommentsState = {
  commentsList: [],
};

const comments = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    _getCommentsList: (state, { payload }: PayloadAction<IComment[]>) => {
      return { ...state, commentsList: payload };
    },
    resetCommentsState: () => initialState,
  },
});

export const getCommentsList = (
  news_id: string[],
  author_id: string[],
  onSuccess?: () => void,
  onError?: () => void,
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.GetCommentsList));
    const { token } = getState().auth;
    const params = qs.stringify({ news_id, author_id }, { arrayFormat: "repeat" })
    const queryParams = params ? `?${params}` : '';
    const url = `${baseUrl}/api/news/comment${queryParams ? '?' + queryParams : ''}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonData = await response.json();
    if (response.status === 200) {
      dispatch(_getCommentsList(jsonData))
      onSuccess && onSuccess();
    } else {
      onError && onError()
    }
  } catch (e) {
    onError && onError()
  } finally {
    dispatch(loadingEnd(LoadingSubject.GetCommentsList));
  }
};

export const deleteComment = (
  id: string,
  onSuccess?: () => void,
  onError?: () => void,
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.DeleteComment));
    const { token } = getState().auth;
    const url = `${baseUrl}/api/news/comment/${id}`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonData = await response.json();
    if (response.status === 200) {
      onSuccess && onSuccess();
      toast.success('Comment successfully deleted');
    } else {
      onError && onError()
    }
  } catch (e) {
    onError && onError()
    console.warn('deleteComment Error: ', e);
  } finally {
    dispatch(loadingEnd(LoadingSubject.DeleteComment));
  }
}

export const { _getCommentsList } = comments.actions;

export default comments.reducer;
