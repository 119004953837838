import { createTheme } from "@mui/material/styles";
import ComponentsOverrides from "./overrides";
import palette from "./palette";
import typography from "./typography";

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    white1: true;
    gray2: true;
    gray3: true;
    black: true;
  }
}

const spacing = [
  0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 48, 64, 80, 100, 120, 140, 160,
];

export const breakpoints = {
  values: {
    xs: 0,
    sm: 540,
    md: 768,
    lg: 1200,
    xl: 1440,
  },
};

const theme = createTheme({
  spacing: spacing,
  breakpoints: breakpoints,
  typography: typography,
  palette: palette,
});

// @ts-ignore
theme.components = ComponentsOverrides(theme);

export default theme;
