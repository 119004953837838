import styles from "./UserInfoModal.module.css";
import AppText from "../../../../components/AppText/AppText";
import AppButton from "../../../../components/AppButton/AppButton";
import ModalWrapper from "components/modals/ModalWrapper";
import { Column, Row } from "components/blocks";
import { useIntl } from "react-intl";

type Props = {
  open: boolean;
  onClose: () => void;
};

export default function UserInfoModal(props: Props) {
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const { open, onClose } = props;

  const renderInfoString = (title: string, value: string) => {
    return (
      <AppText sx={{ mt: 1 }}>
        {title + ": "}
        <span style={{ fontFamily: "Malgun-Bold" }}>{value}</span>
      </AppText>
    );
  };

  return (
    <ModalWrapper open={open} onClose={onClose}>
      <Column sx={{ gap: 4 }}>
        <Row wrap start sx={{ gap: 4 }}>
          <div>
            <img
              className={styles["user-photo"]}
              src={require("./../../../../assets/images/raster/avatar2.jpg")}
              alt={"user"}
            />
          </div>
          <div className={styles["user-main-data-container"]}>
            <AppText sx={{ mb: 3 }} variant="h2">
              {"John Snow"}
            </AppText>
            {renderInfoString(t("text.registered-date"), "04.02.2018")}
            {renderInfoString(t("fields.e-mail"), "john_snow@mail.com")}
            {renderInfoString(t("text.date-of-birth"), "20.04.1995")}
          </div>
        </Row>
        <Row wrap start sx={{ gap: 4 }}>
          <div>
            <img
              className={styles["user-passport-photo"]}
              src={require("./../../../../assets/images/raster/passport.png")}
              alt={t("text.passport")}
            />
          </div>
          <div className={styles["user-main-data-container"]}>
            {renderInfoString(t("text.number-of-document"), "69 6734523")}
            {renderInfoString(t("text.country-of-document"), "The Nord")}
            {renderInfoString(t("text.city"), "Winterfell")}
          </div>
        </Row>
      </Column>
      <div className={styles["buttons-block"]}>
        <AppButton
          onClick={onClose}
          sx={{ width: "25%" }}
          text={t("general.approve")}
        />
        <AppButton
          onClick={onClose}
          sx={{ width: "25%", marginLeft: 10 }}
          variant="outlined"
          color="error"
          text={t("general.cancel")}
        />
      </div>
    </ModalWrapper>
  );
}
