import { useState } from "react";
import styles from "./CreateNewsPage.module.css";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { AppColors } from "../../AppTheme";
import AppInput from "../../components/AppInput/AppInput";
import ReactQuill from "react-quill";
import AuthorizedLayout from "layouts/AuthorizedLayout";
import { Button } from "@mui/material";
import { Column, Row } from "components/blocks";
import { useIntl } from "react-intl";

function CreateNewsPage() {
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const [newsValues, setNewsValues] = useState<{ name: string; desc: string }>({
    name: "News One",
    desc: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore.",
  });

  return (
    <AuthorizedLayout>
      <div className={styles["add-photo-container"]}>
        <AddCircleOutlineIcon
          sx={{ fill: AppColors.green, width: 100, height: 100 }}
        />
      </div>
      <div>
        <AppInput
          classNameContainer={styles["input-container"]}
          classNameInput={styles["input-styles"]}
          name="title"
          placeholder={t("text.type-header")}
          value={newsValues.name}
          onChange={(e) =>
            setNewsValues({ ...newsValues, name: e.target.value })
          }
        />
      </div>

      <Column center>
        <Column sx={{ width: { xs: "100%", md: "90%" } }}>
          <ReactQuill
            className={styles["quill-styles"]}
            theme="snow"
            value={newsValues.desc}
            onChange={(text) => setNewsValues({ ...newsValues, desc: text })}
          />

          <Row sx={{ mt: 8, gap: { xs: 4, md: 8 } }}>
            <Button fullWidth variant="contained" children="Send newsletter" />
            <Button
              fullWidth
              variant="contained"
              color="error"
              children={t("general.cancel")}
            />
          </Row>
        </Column>
      </Column>
    </AuthorizedLayout>
  );
}

export default CreateNewsPage;
