import styles from "./ProfilePage.module.css";
import AppText from "../../components/AppText/AppText";
import AppInput from "../../components/AppInput/AppInput";
import { AppColors } from "../../AppTheme";
import Icon from "../../assets/icons/svgs/Icon";
import NewTable from "../../components/NewTable/NewTable";
import AuthorizedLayout from "layouts/AuthorizedLayout";
import { Grid } from "@mui/material";
import { Column, Row } from "components/blocks";
import { useIntl } from "react-intl";

function ProfilePage() {
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const userPic = require("./../../assets/images/raster/avatar2.jpg");

  const userTextData = (name: string, value: string) => {
    return (
      <AppText variant="body1" className={styles["user-text"]}>
        {name}: <span className={styles["user-text-bold"]}>{value}</span>
      </AppText>
    );
  };

  return (
    <AuthorizedLayout noBackground>
      <AppText sx={{ mt: 2 }} variant="h3">
        {t("profile")}
      </AppText>
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <Row wrap start alignStart sx={{ mt: 8, gap: 8 }}>
            <img
              src={userPic}
              className={styles["user-image"]}
              alt={"user_image"}
            />
            <div className={styles["user-data-container"]}>
              <AppText sx={{ mt: 2, fontSize: 32 }} variant="h2">
                {"John Doe"}
              </AppText>
              {userTextData(t("fields.position"), "Manager")}
              {userTextData(t("text.registered-date"), "20.04.2020")}
              {userTextData(t("text.phone-or-e-mail"), "john_doe@mail.com")}
              {userTextData(t("text.passport-number"), "63 6734523")}
              {userTextData(t("text.citizenship"), "Spain")}
              {userTextData(t("text.date-of-birth"), "20.04.1996")}
              {userTextData(t("text.account-id"), ".... 0002852")}
            </div>
          </Row>
        </Grid>
        <Grid item xs={12}>
          <Row wrap sx={{ gap: 8 }}>
            <Column>
              <AppText
                className={styles["account-payment-title"]}
                color="green"
                variant="body2"
              >
                {t("text.account-payment")}
              </AppText>
              <AppText variant="body3" className={styles["period-text"]}>
                {t("text.time-period") + ": "}
                <span className={styles["period-date"]}>{"05.07.2023"}</span>
              </AppText>
            </Column>

            <AppInput
              isErrorHidden
              classNameContainer={styles["input-container"]}
              customIcon={<Icon name="Search" />}
              style={{ color: AppColors.gray2 }}
              name="search"
              placeholder={t("general.search")}
            />
          </Row>
        </Grid>
        <Grid item xs={12}>
          <NewTable
            headCells={[
              t("text.order-name"),
              t("Order number"),
              t("text.date"),
              t("text.amount"),
            ]}
            rows={[]}
          />
        </Grid>
      </Grid>
    </AuthorizedLayout>
  );
}
export default ProfilePage;
