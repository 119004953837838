import styled from "@emotion/styled";
import { Box, BoxProps, Theme } from "@mui/material";

export const Row = styled(Box)(
  ({
    theme,
    start,
    wrap,
    alignStart,
    fullWidth,
  }: {
    theme?: Theme;
    start?: boolean;
    alignStart?: boolean;
    wrap?: boolean;
    fullWidth?: boolean;
  }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: alignStart ? "start" : "center",
    justifyContent: start ? "initial" : "space-between",
    ...(wrap && { flexWrap: "wrap" }),
    ...(fullWidth && { width: "100%" }),
  })
);

export const Column = styled(Box)(
  ({ theme, center }: { theme?: Theme; center?: boolean }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: center ? "center" : "initial",
    justifyContent: center ? "center" : "initial",
  })
);

export const Center = styled(Box)(({ theme }: { theme?: Theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const Card = styled(Box)(({ theme }: { theme?: Theme }) => ({
  display: "flex",
  flexDirection: "column",
  background: theme?.palette.grey[50],
  border: "1px solid #DBE2E9",
  borderRadius: "10px",
  padding: "20px",
}));

export const Divider = styled(Box)(({ theme }: { theme?: Theme }) => ({
  borderTop: "1px solid #afafaf",
  marginTop: 12,
  marginBottom: 12,
}));

export const DividerVertical = styled(Box)(({ theme }: { theme?: Theme }) => ({
  borderLeft: "1px solid #afafaf",
  marginLeft: 8,
  marginRight: 8,
}));

export const DividedContent = styled(Box)(({ theme }: { theme?: Theme }) => ({
  borderTop: "1px solid #afafaf",
  borderBottom: "1px solid #afafaf",
  marginTop: 12,
  marginBottom: 12,
  paddingTop: 12,
  paddingBottom: 12,
}));

export const ScrollWrapper = styled(Box)(() => ({
  overflow: "scroll",
  overflowX: "hidden",
  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

export const DesktopView = ({ sx, ...props }: BoxProps) => {
  return <Box sx={{ display: { xs: "none", md: "flex" }, ...sx }} {...props} />;
};

export const MobileView = ({ sx, ...props }: BoxProps) => {
  return <Box sx={{ display: { xs: "flex", md: "none" }, ...sx }} {...props} />;
};
