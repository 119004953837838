import { useEffect, useState } from "react";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
  move,
} from "react-grid-dnd";
import styles from "./ClubsWithTopsTab.module.css";
import "./styles.css";
import { AppColors } from "../../../../AppTheme";
import AppText from "../../../../components/AppText/AppText";
import AppButton from "../../../../components/AppButton/AppButton";
import { useNav } from "../../../../hooks/useNav";
import { useDispatch, useSelector } from "react-redux";
import { getClubList, setClubSorting } from "../../../../slices/clubSlice";
import { isLoading } from "../../../../utils";
import { LoadingSubject } from "../../../../slices/loadingSlice";
import CircularProgress from "@mui/material/CircularProgress";
import { useWindowDimensions } from "hooks/useWindowDimensions";
import { breakpoints } from "theme";
import { useIntl } from "react-intl";
import { useLoadings } from "hooks/useLoadings";

export default function ClubsWithTopsTab() {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  // const clubList = useSelector((s) => {
  //   const clubz = JSON.parse(JSON.stringify(s.club.clubList));
  //   if (clubz?.length) {
  //     const half = Math.round(clubz?.length / 2);
  //     clubz.forEach((club, index) => {
  //       club.isTop = index + 1 > half;
  //     });
  //   }
  //   return clubz;
  // });

  const [toggleSort, setToggleSort] = useState(false)

  const { clubList } = useSelector((s) => s.club);

  const [clubs, setClubs] = useState({ top: [], other: [] });

  const subjects = useSelector((s) => s.loading.subjects);
  const isLoadingClubs = isLoading(LoadingSubject.ClubList, subjects);

  const { isSetClubSortingLoading } = useLoadings()

  useEffect(() => {
    dispatch(getClubList());
  }, [toggleSort]);

  useEffect(() => {
    setClubs({
      top: clubList?.filter((c) => c.is_top)?.sort((a, b) => a.sorting - b.sorting),
      other: clubList?.filter((c) => !c.is_top)?.sort((a, b) => a.sorting - b.sorting),
    });
  }, [isLoadingClubs]);

  const { width: screenWidth } = useWindowDimensions();

  const calcGrid = () => {
    const startResizing = breakpoints.values.lg;
    if (screenWidth < breakpoints.values.sm) {
      const blockWidth = screenWidth - 24;
      return {
        rows: 2,
        cardWidth: blockWidth / 2,
      };
    }
    if (screenWidth < breakpoints.values.md) {
      const blockWidth = screenWidth - 24;
      return {
        rows: 3,
        cardWidth: blockWidth / 3,
      };
    }
    if (screenWidth < startResizing) {
      const blockWidth = screenWidth - 24;
      return {
        rows: 4,
        cardWidth: blockWidth / 4,
      };
    }
    return {
      rows: 4,
      cardWidth: 290,
    };
  };

  const { viewClubProfile, viewCreateClub } = useNav();
  const [editMode, setEditMode] = useState(false);

  const toggleEditMode = () => setEditMode(!editMode);

  const onResetClick = () => {
    setClubs({
      top: clubList?.filter((c) => c.is_top)?.sort((a, b) => a.sorting - b.sorting),
      other: clubList?.filter((c) => !c.is_top)?.sort((a, b) => a.sorting - b.sorting),
    });
    toggleEditMode();
  };

  const onChange = (sourceId, sourceIndex, targetIndex, targetId) => {
    if (targetId) {
      const result = move(
        clubs[sourceId],
        clubs[targetId],
        sourceIndex,
        targetIndex
      );
      return setClubs({
        ...clubs,
        [sourceId]: result[0],
        [targetId]: result[1],
      });
    }
    const result = swap(clubs[sourceId], sourceIndex, targetIndex);
    return setClubs({
      ...clubs,
      [sourceId]: result,
    });
  };

  const { cardWidth, rows } = calcGrid();

  const renderClubItem = (item) => {
    return (
      <GridItem
        style={{ cursor: editMode ? "grab" : "default" }}
        key={item.title}
      >
        <div
          className="grid-item-container"
          style={{ width: cardWidth, height: cardWidth }}
        >
          <img
            style={{
              border: editMode ? `4px dashed ${AppColors.green}` : "none",
              width: cardWidth - 15,
              height: cardWidth - 15,
            }}
            className={styles["club-item-image"]}
            src={item.picture_url}
            alt="club-item"
          />
          <div className={styles["club-item-info-container"]}>
            <AppText sx={{ textAlign: "center" }} color="gray1" variant="h4">
              {item.name}
            </AppText>
            <AppButton
              onClick={() => viewClubProfile(item.id)}
              className={styles["more-info-button"]}
              text={t("text.more-info")}
            />
          </div>
        </div>
      </GridItem>
    );
  };

  const renderDropzone = (clubType, clubData) => {
    return (
      <GridDropZone
        className={`dropzone ${clubType}`}
        id={clubType}
        disableDrag={!editMode}
        disableDrop={!editMode}
        boxesPerRow={rows}
        rowHeight={cardWidth}
        style={{
          height: cardWidth * Math.ceil(clubData?.length / rows),
          minHeight: cardWidth,
          marginTop: 20,
          border: !clubData?.length ? `3px dashed ${AppColors.gray2}` : "none",
        }}
      >
        {!isLoadingClubs && !clubData?.length ? (
          <AppText
            sx={{ textAlign: "center", mt: 15 }}
            variant="h2"
            color="gray2"
          >
            {t("text.drop-zone") + "..."}
          </AppText>
        ) : (
          clubData?.map((club) => renderClubItem(club))
        )}
      </GridDropZone>
    );
  };

  const renderLoader = () => (
    <CircularProgress
      sx={{ display: "flex", margin: "0 auto", marginTop: "50px" }}
    />
  );

  const sendClubsSorting = () => {
    const clubsCopy = JSON.parse(JSON.stringify(clubList));

    const clubsToSend = clubsCopy.map(c => {
      const is_top = !!clubs.top.find(f => f.id === c.id);
      const sorting = clubs[is_top ? 'top' : 'other'].findIndex(i => i.id === c.id) + 1
      return {
        club_id: c.id,
        is_top,
        sorting
      }
    })

    const onSuccess = () => {
      setToggleSort(!toggleSort);
      toggleEditMode()
    }

    dispatch(setClubSorting(clubsToSend, onSuccess))
  }

  const renderContent = () => {
    return (
      <>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <AppButton onClick={viewCreateClub} text={t("text.create-club")} />
        </div>
        <div className={styles["top-clubs-header"]}>
          <AppText variant="h3" color="gray1">
            {t("text.top-clubs")}
          </AppText>
          <div className={styles["controls-container"]}>
            {editMode && (
              <AppButton
                sx={{ mr: 3 }}
                text={t("general.reset")}
                variant="contained"
                color="error"
                onClick={onResetClick}
              />
            )}
            <AppButton
              text={editMode ? t("general.apply") : t("general.edit")}
              variant="outlined"
              color="white1"
              loading={isSetClubSortingLoading}
              onClick={() => {
                if (editMode) {
                  sendClubsSorting()
                } else {
                  toggleEditMode()
                }
              }}
            />
          </div>
        </div>
        {renderDropzone("top", clubs.top)}

        <div className={styles["top-clubs-header"]}>
          <AppText variant="h3" color="gray1">
            {t("text.other-clubs")}
          </AppText>
        </div>
        {renderDropzone("other", clubs.other)}
      </>
    );
  };

  return (
    <GridContextProvider onChange={onChange}>
      {isLoadingClubs ? renderLoader() : renderContent()}
    </GridContextProvider>
  );
}
