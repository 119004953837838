import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../store/store";
import { baseUrl } from "../constants";
import { loadingEnd, loadingStart, LoadingSubject } from "./loadingSlice";
import { getProductList, IProduct } from "./productSlice";
import { getClubMembers } from "./clubSlice";
import toast from "react-hot-toast";

export interface IOrder {
  id: string;
  club_id: string;
  customer_id: string;
  status: string;
  products: IProduct[];
  customer_fullname: string;
}

export interface IOrdersState {
  list: IOrder[];
}

const initialState: IOrdersState = {
  list: [],
};

const orders = createSlice({
  name: "orders",
  initialState,
  reducers: {
    _getClubOrders: (state, { payload }: PayloadAction<IOrder[]>) => {
      return { ...state, list: payload };
    },
  },
});

export const getClubOrders = (
  clubId: string,
  status: string = "placed"
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.GetClubOrders));
    const token = getState().auth.token;
    const response = await fetch(
      `${baseUrl}/api/order?club_id=${clubId}&status=${status}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    const jsonData = await response.json();

    if (response.status === 200) {
      console.log("getClubOrdersJson", jsonData);
      dispatch(
        _getClubOrders(jsonData.filter((o: IOrder) => o.products.length > 0))
      );
    } else {
      console.log("getClubOrdersNot200", jsonData);
    }
  } catch (e) { }
  finally {
    dispatch(loadingEnd(LoadingSubject.GetClubOrders));
  }
};

export const cancelOrder = (
  orderId: string,
  clubId: string,
  onSuccess?: () => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.CancelOrder));
    const token = getState().auth.token;

    const response = await fetch(
      `${baseUrl}/api/order/${clubId}/cancel?id=${orderId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    const jsonData = await response.json();

    if (response.status === 200) {
      dispatch(getClubOrders(clubId));
      onSuccess && onSuccess();
    } else {
    }
  } catch (e) {
  } finally {
    dispatch(loadingEnd(LoadingSubject.CancelOrder));
  }
};

export const completeOrder = (
  orderId: string,
  clubId: string,
  categoryId?: string,
  onSuccess?: () => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.CompleteOrder));
    const token = getState().auth.token;
    const response = await fetch(
      `${baseUrl}/api/order/${clubId}/complete?id=${orderId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    const jsonData = await response.json();

    if (response.status === 200) {
      dispatch(getClubOrders(clubId));
      dispatch(getClubMembers(clubId));
      categoryId && dispatch(getProductList(categoryId));
      onSuccess && onSuccess();
    } else {
      toast.error(jsonData.detail);
    }
  } catch (e) {
  } finally {
    dispatch(loadingEnd(LoadingSubject.CompleteOrder));
  }
};

export const getOrderById = (
  orderId: string,
  onSuccess?: (order: IOrder) => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.GetOrderById));
    const token = getState().auth.token;
    const response = await fetch(`${baseUrl}/api/order/{id}?id=${orderId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    const jsonData = await response.json();

    if (response.status === 200) {
      onSuccess && onSuccess(jsonData);
    } else {
    }
  } catch (e) {
  } finally {
    dispatch(loadingEnd(LoadingSubject.GetOrderById));
  }
};

export const { _getClubOrders } = orders.actions;

export default orders.reducer;
