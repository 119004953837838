import * as Yup from 'yup';
import { useIntl } from 'react-intl';

enum ErrType {
  required = 'required',
  min = 'min',
  max = 'max',
  notCorrect = 'notCorrect',
  custom = 'custom',
}

const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z/^[A-Za-z0-9\!\@\#\$\%\^\&\*\)\(\_\-\<\>\{\}\[\]\~\'\"\//]{8,}$/;

export const useSchemas = () => {
  const intl = useIntl();

  const t = (id: string) => {
    return intl.formatMessage({ id });
  };

  const generateError = (
    field: string,
    errorType: ErrType,
    textSize?: number,
    customError?: string,
  ) => {
    switch (errorType) {
      case ErrType.required:
        return intl.formatMessage({ id: 'error.required' }, { field });
      case ErrType.max:
        return intl.formatMessage({ id: 'error.max' }, { textSize });
      case ErrType.min:
        return intl.formatMessage({ id: 'error.min' }, { textSize });
      case ErrType.notCorrect:
        return intl.formatMessage({ id: 'error.notCorrect' }, { field });
      case ErrType.custom:
        return customError || intl.formatMessage({ id: 'error.custom' });
    }
  };

  const emailSchema = Yup.string()
    .required(generateError(t('general.email'), ErrType.required))
    .email(t('error.enter-valid-email'))
    .max(100)
    .label(t('general.email'))
    .trim();

  const getPasswordScheme = (label?: string) => {
    return Yup.string()
      .required(generateError(label ?? t('general.password'), ErrType.required))
      // .min(8, t('error.password-min'))
      // .max(32, t('error.password-max'))
      // .matches(passwordRegex, t('error.password-chars'))
      .label(label ?? t('general.password'));
  };

  const emailAuthSchema = Yup.object().shape({
    email: emailSchema,
    password: getPasswordScheme(),
  });

  const supportAuthSchema = Yup.object().shape({
    email: emailSchema,
    text: Yup.string()
      .required(generateError(t('Text'), ErrType.required))
      .label(t('Text'))
      .trim(),
  });

  const forgotPasswordSchema = Yup.object().shape({
    email: emailSchema,
  });

  const restorePasswordSchema = Yup.object().shape({
    password: getPasswordScheme(),
    passwordConfirmation: Yup.string()
      .required(generateError(t('fields.confirmNewPassword'), ErrType.required))
      .oneOf([Yup.ref('password'), null], t('error.pass-mismatch'))
      .label(t('fields.confirmNewPassword')),
  });

  const changePasswordSchema = Yup.object().shape({
    email: emailSchema,
  });

  const changeClubSchema = Yup.object().shape({
    adress: Yup.string()
      .required(generateError(t('Adress'), ErrType.required))
      .label(t('Adress'))
      .trim(),
    deposit: Yup.string()
      .required(generateError(t('Deposit'), ErrType.required))
      .label(t('Deposit'))
      .trim(),
    addPhoto: Yup.string().required(generateError(t('Image'), ErrType.required))
      .label(t('Add photo'))
      .trim(),
  });

  const newPassSchema = Yup.object().shape({
    newPassword: getPasswordScheme(t('password.new-password')),

    confirmNewPassword: Yup.string()
      .required(generateError(t('fields.confirmNewPassword'), ErrType.required))
      .oneOf([Yup.ref('newPassword'), null], t('error.pass-mismatch'))
      .label(t('fields.confirmNewPassword')),
  });

  const usersTabEditSchema = Yup.object().shape({
    name: Yup.string()
      .required(generateError(t('Name'), ErrType.required))
      .label(t('Name'))
      .trim(),
    email: emailSchema,
    personalId: Yup.string()
      .required(generateError(t('Personal ID'), ErrType.required))
      .min(13, t('Personal ID length must be at least 13 symbols'))
    ,
    race: Yup.string()
      .required(generateError(t('Race'), ErrType.required))
      .label(t('Race'))
      .trim(),
    birthdate: Yup.date()
      .required(generateError(t('Birthdate'), ErrType.required))
      .label(t('Birthdate')),
    city: Yup.string()
      .required(generateError(t('City'), ErrType.required))
      .label(t('City'))
      .trim(),
  });

  const newsTabEditSchema = Yup.object().shape({
    title: Yup.string()
      .required(generateError(t('Title'), ErrType.required))
      .label(t('Title'))
      .trim(),
    description: Yup.string()
      .required(generateError(t('Description'), ErrType.custom))
      .label(t('Description'))
      .trim(),
  });

  const tabsGenericRejectSchema = Yup.object().shape({
    reason: Yup.string()
      .required(generateError(t('Reason'), ErrType.required))
      .label(t('Reason'))
      .trim(),
  });

  const createUserSchema = Yup.object().shape({
    first_name: Yup.string()
      .required(generateError(t('First name'), ErrType.required))
      .label(t('First name'))
      .trim(),

    last_name: Yup.string()
      .required(generateError(t('Last name'), ErrType.required))
      .label(t('Last name'))
      .trim(),

    email: emailSchema,

    role: Yup.string()
      .required(generateError(t('Role'), ErrType.required))
      .label(t('Role'))
      .trim(),
  });

  const createClubSchema = Yup.object().shape({
    // image: Yup.string()
    //   .required(generateError(t('Image'), ErrType.required))
    //   .label(t('Image'))
    //   .trim(),

    name: Yup.string()
      .required(generateError(t('Name'), ErrType.required))
      .label(t('Name'))
      .trim(),
    // address: Yup.string()
    //   .required(generateError(t('Address'), ErrType.required))
    //   .label(t('Address'))
    //   .trim(),

    description: Yup.string()
      .required(generateError(t('Description'), ErrType.required))
      .label(t('Description'))
      .trim(),
  });

  const updateClubSchema = Yup.object().shape({
    name: Yup.string()
      .required(generateError(t('Name'), ErrType.required))
      .label(t('Name'))
      .trim(),

    address: Yup.string()
      .required(generateError(t('Address'), ErrType.required))
      .label(t('Address'))
      .trim(),

    amount: Yup.number().typeError('Amount must be a number')
      .required(generateError(t('Amount'), ErrType.required))
      .label(t('Amount')),

    description: Yup.string()
      .required(generateError(t('Description'), ErrType.required))
      .label(t('Description'))
      .trim(),
  });

  const categorySchema = Yup.object().shape({
    name: Yup.string()
      .required(generateError(t('Name'), ErrType.required))
      .label(t('Name'))
      .trim(),
    description: Yup.string()
      .required(generateError(t('Description'), ErrType.required))
      .label(t('Description'))
      .trim(),
  });

  const productSchema = Yup.object().shape({
    name: Yup.string()
      .required(generateError(t('Name'), ErrType.required))
      .label(t('Name'))
      .trim(),
    description: Yup.string()
      .required(generateError(t('Description'), ErrType.required))
      .label(t('Description'))
      .trim(),
    amount: Yup.number().typeError('Amount must be a number')
      .required(generateError(t('Amount'), ErrType.required))
      .label(t('Amount')),
    price: Yup.number().typeError('Price must be a number')
      .required(generateError(t('Price'), ErrType.required))
      .label(t('Price')),
  });

  const createEditNewsSchema = Yup.object().shape({
    title: Yup.string()
      .required(generateError(t('Title'), ErrType.required))
      .label(t('Title'))
      .trim(),
    content: Yup.string()
      .required(generateError(t('Content'), ErrType.required))
      .label(t('Content'))
      .trim()
  });

  const tariffFormSchema = Yup.object().shape({
    name: Yup.string()
      .required(generateError(t('Name'), ErrType.required))
      .label(t('Name'))
      .trim(),
    duration: Yup.number().typeError('Duration must be a number')
      .required(generateError(t('Duration'), ErrType.required))
      .label(t('Duration')),
    global_news_amount: Yup.number().typeError('Global news amount must be a number')
      .required(generateError(t('Global news amount'), ErrType.required))
      .label(t('Global news amount')),
    local_news_amount: Yup.number().typeError('Local news amount must be a number')
      .required(generateError(t('Local news amount'), ErrType.required))
      .label(t('Local news amount')),
    price: Yup.number().typeError('Price must be a number')
      .required(generateError(t('Price'), ErrType.required))
      .label(t('Price')),
  });

  return {
    ErrType,
    generateError,

    newPassSchema,
    changePasswordSchema,
    emailAuthSchema,
    forgotPasswordSchema,
    restorePasswordSchema,
    changeClubSchema,

    usersTabEditSchema,
    newsTabEditSchema,
    tabsGenericRejectSchema,

    createUserSchema,
    createClubSchema,
    updateClubSchema,

    categorySchema,
    productSchema,

    createEditNewsSchema,
    tariffFormSchema,
    supportAuthSchema
  };
};
