import React, { useEffect, useState } from "react";
import { Button, Divider, Drawer, IconButton, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import styled from "@emotion/styled";
import AvatarMenu, { Name, Role } from "components/blocks/AvatarMenu";
import { IconLogo } from "components/icons";
import { useNav } from "hooks/useNav";
import BuyProModal from "./components/BuyProModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { useIntl } from "react-intl";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotifsModal from "./NotifsModal/NotifsModal";
import { Row } from "components/blocks";
import { Link } from "react-router-dom";
import { ROUTES } from "navigation/navigation";

type AppHeaderProps = {};

function AppHeader(props: AppHeaderProps) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const t = (id: string) => intl.formatMessage({ id });
  const { me } = useSelector((s: RootState) => s.auth);
  const { messagesList } = useSelector((s: RootState) => s.messages);

  const [isNotifsModal, setNotifsModal] = useState(false);
  const openNotifsModal = () => setNotifsModal(true);
  const closeNotifsModal = () => setNotifsModal(false);

  const userData = {
    name: `${me?.first_name} ${me?.last_name}`,
    role: `${me?.role}`,
    // avatar: "",
  };
  const { viewWelcome } = useNav();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isOpenByProModal, setIsOpenByProModal] = useState(false);
  const [tariffStep, setTariffStep] = useState<number>(25);

  const newMessagesList = messagesList.filter((m) => m.isNew);
  console.log("newMessagesList", newMessagesList);

  const renderNotifsBell = () => {
    return (
      <Row>
        <StyledLink key={"messages-footer"} to={ROUTES.HELP_CHAT}>
          {"Messages"}
        </StyledLink>
        <IconButton
          disabled={!newMessagesList.length}
          onClick={openNotifsModal}
        >
          {newMessagesList.length ? (
            <Typography
              variant="body4"
              color="white"
              style={{
                position: "absolute",
                top: 5,
                right: 5,
                backgroundColor: "red",
                borderRadius: 10,
                padding: "0px 4px",
              }}
            >
              {newMessagesList.length}
            </Typography>
          ) : (
            <></>
          )}
          <NotificationsIcon sx={{ width: 30, height: 30, color: "#131517" }} />
        </IconButton>
      </Row>
    );
  };

  return (
    <>
      <BuyProModal
        open={isOpenByProModal}
        onClose={() => setIsOpenByProModal(false)}
        onSubmit={() => setIsOpenByProModal(false)}
        tariffStep={tariffStep}
        setTariffStep={setTariffStep}
      />

      <StyledAppBar position="fixed">
        <Container maxWidth="xl">
          <StyledToolbar disableGutters>
            {/* MOBILE */}
            <ToolbarWrapper sx={{ display: { xs: "flex", md: "none" } }}>
              <CenteredBox>
                <IconButton onClick={() => setIsDrawerOpen(true)}>
                  <MenuIcon />
                </IconButton>
              </CenteredBox>
            </ToolbarWrapper>
            {/* DESKTOP */}
            <ToolbarWrapper sx={{ display: { xs: "none", md: "flex" } }}>
              <CenteredBox>
                <StyledLogoButton onClick={viewWelcome}>
                  {/* <IconLogo /> */}
                  <img
                    style={{ width: 150, height: 60, objectFit: "contain" }}
                    src={require("../../assets/images/raster/logo_dark.png")}
                  />
                </StyledLogoButton>
                <AvatarMenu userData={userData} />
              </CenteredBox>
              <CenteredBox>
                {/* <Button
                  variant="contained"
                  onClick={() => {
                    setIsOpenByProModal(true);
                    setIsDrawerOpen(false);
                  }}
                >
                  Buy PRO
                </Button> */}
                {/* <Button variant="outlined" color="black">FAQ</Button> */}
                {/* <Button variant="contained">Help</Button> */}
              </CenteredBox>
            </ToolbarWrapper>
            {renderNotifsBell()}
          </StyledToolbar>
          {/* MOBILE DRAWER */}
          <StyledDrawer
            anchor={"left"}
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
          >
            <Column>
              <StyledLogoButton onClick={viewWelcome}>
                {/* <IconLogo /> */}
                <img
                  style={{ width: 110, height: 60, objectFit: "contain" }}
                  src={require("../../assets/images/raster/logo_dark.png")}
                />
              </StyledLogoButton>
              <Divider />
              <Box sx={{ display: "flex", gap: 3 }}>
                {/* <HeaderAvatar
                  src={
                    userData?.avatar ||
                    require("assets/images/raster/avatar.png")
                  }
                /> */}
                <Box>
                  <Name>{userData?.name || "User name"}</Name>
                  <Role>{userData?.role || "Role"}</Role>
                </Box>
              </Box>
              <Divider />
              <Column>
                {/* <Button
                  variant="contained"
                  onClick={() => {
                    setIsOpenByProModal(true);
                    setIsDrawerOpen(false);
                  }}
                >
                  Buy PRO
                </Button> */}
                {/* <Button variant="outlined" color="black">FAQ</Button> */}
                {/* <Button variant="contained">Help</Button> */}
              </Column>
            </Column>
            <Column>
              <Divider />
              <Button variant="outlined" color="error">
                {t("general.logout")}
              </Button>
            </Column>
          </StyledDrawer>
        </Container>
        <NotifsModal open={isNotifsModal} handleClose={closeNotifsModal} />
      </StyledAppBar>
    </>
  );
}
export default AppHeader;

export const CenteredBox = styled(Box)(({ theme }: any) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(8),
}));

export const StyledAppBar = styled(AppBar)(({ theme }: any) => ({
  zIndex: theme.zIndex?.drawer + 1,
  borderBottom: "1px solid",
  borderColor: "transparent",
  backgroundColor: theme.palette.common.white,
}));

const ToolbarWrapper = styled(Box)(({ theme }: any) => ({
  flexGrow: 1,
  justifyContent: "space-between",
  alignItems: "center",
}));

const StyledToolbar = styled(Toolbar)(({ theme }: any) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(8),
}));

export const StyledDrawer = styled(Drawer)(
  ({ theme, drawerwidth = 300 }: any) => ({
    width: drawerwidth,
    minWidth: drawerwidth,
    zIndex: 2000,
    flexShrink: 0,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
    [`& .MuiDrawer-paper`]: {
      minWidth: drawerwidth,
      boxSizing: "border-box",
      padding: theme.spacing(5),
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
  })
);

const Column = styled(Box)(({ theme }: any) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
}));

const StyledLogoButton = styled(Box)(({ theme }: any) => ({
  display: "flex",
  alignItems: "center",
  color: theme.palette.common.black,
  cursor: "pointer",
}));

const StyledLink = styled(Link)(({ theme }: any) => ({
  fontFamily: "Malgun-Bold",
  textDecoration: "none",
  fontSize: 14,
  color: "black",
  ":hover": {
    color: "green",
  },
  marginRight: 5,
}));
