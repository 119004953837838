declare module "@mui/material/styles/createPalette" {
  interface Palette {
    white1: Palette["primary"];
    gray2: Palette["primary"];
    gray3: Palette["primary"];
    black: Palette["primary"];
  }
  interface PaletteOptions {
    white1?: PaletteOptions["primary"];
    gray2?: PaletteOptions["primary"];
    gray3?: PaletteOptions["primary"];
    black?: PaletteOptions["primary"];
  }
}

// SETUP COLORS

export const GREY = {
  50: "#F0F0F0",
  100: "#F0F0F0",
  200: "#D9D9D9",
  300: "#D9D9D9",
  400: "#AFAFAF",
  500: "#AFAFAF",
  600: "#AFAFAF",
  700: "#3F4B57",
  800: "#7B7B7B",
  900: "#7B7B7B",
};

export const AppColors = {
  orange: "var(--orange)",
  blue: "var(--blue)",
  green: "var(--green)",
  darkCyan: "var(--darkCyan)",
  red: "var(--red)",
  purple: "var(--purple)",

  gray1: "var(--gray1)",
  gray2: "var(--gray2)",
  gray3: "var(--gray3)",

  white: "var(--white)",
  black: "var(--black)",
};

export const getCssVarValue = (cssVar: string) => {
  const bodyStyles = window.getComputedStyle(document.body);
  const colorInHEX = bodyStyles.getPropertyValue(cssVar.split(/[()]/)[1]);

  return colorInHEX;
};

const palette = {
  common: { black: "#1E1E1E", white: "#F0F0F0" },
  primary: {
    main: getCssVarValue(AppColors.green),
  },
  secondary: {
    main: getCssVarValue(AppColors.white),
  },
  error: {
    main: getCssVarValue(AppColors.red),
  },
  black: {
    main: getCssVarValue(AppColors.black),
  },
  white1: {
    main: getCssVarValue(AppColors.white),
  },
  gray2: {
    main: getCssVarValue(AppColors.gray2),
  },
  gray3: {
    main: getCssVarValue(AppColors.gray3),
  },
  action: {
    disabled: getCssVarValue(AppColors.gray2),
  },
  grey: { ...GREY },
};

export default palette;
