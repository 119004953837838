import React, { Fragment, useState } from "react";
import AppText from "../../components/AppText/AppText";
import styles from "./SuperAdminClubsPage.module.css";
import Icon from "../../assets/icons/svgs/Icon";
import AppInput from "../../components/AppInput/AppInput";
import AppButton from "./../../components/AppButton/AppButton";
import { clubsItems } from "./SuperAdminClubsPageMock";
import { Box, Grid, IconButton, Rating } from "@mui/material";
import AppModal from "../../components/AppModal/AppModal";
import TrashIcon from "@mui/icons-material/DeleteOutline";
import cn from "classnames";
import Select from "react-dropdown-select";
import { AppColors } from "../../AppTheme";
import ClubModal from "../../components/ClubModal/ClubModal";
import AuthorizedLayout from "layouts/AuthorizedLayout";
import DeleteModal from "components/modals/DeleteModal/DeleteModal";
import { useIntl } from "react-intl";

type ClubItemType = {
  image: string;
  title: string;
  desc: string;
  admin: string;
  rating: number;
  tariff: string;
};

enum ModalState {
  None,
  ClubModal,
  DeleteClubModal,
}

type DropdownValue = {
  name: string;
  value?: number;
};

function SuperAdminClubsPage() {
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const [isModal, setModal] = useState<ModalState>(ModalState.None);

  const openClubModal = () => setModal(ModalState.ClubModal);
  const openClubDeleteModal = () => setModal(ModalState.DeleteClubModal);

  const closeModal = () => {
    setModal(ModalState.None);
    setClubItem(undefined);
  };

  const [clubItem, setClubItem] = useState<ClubItemType | undefined>(undefined);

  const [dropdownValues, setDropdownValues] = useState<DropdownValue[]>([
    { name: "Raquel Aranda", value: 0 },
  ]);

  const renderItemInfoText = (name: string, value: string) => {
    return (
      <div className={styles["item-info-text"]}>
        <AppText variant="body3">{`${name}:`}</AppText>
        <AppText variant="body2">{value}</AppText>
      </div>
    );
  };

  const renderDropdownAddButton = () => {
    return (
      <div className={styles["item-info-text"]}>
        <AppText variant="body3">Add new item</AppText>
      </div>
    );
  };

  const renderEditClubModalContent = () => {
    return (
      <Fragment>
        <div
          className={`${cn(styles["modal-left-container"], styles["shadow"])}`}
        >
          {clubItem?.image && (
            <img
              className={styles["modal-image"]}
              src={clubItem?.image}
              alt={"club"}
            />
          )}

          {!clubItem?.image && (
            <AppText color="gray3" variant="h3">
              {"No club image"}
            </AppText>
          )}

          {!clubItem?.image && (
            <IconButton>
              <Icon className={styles["add-club-image-icon"]} name="Plus" />
            </IconButton>
          )}

          {clubItem?.image && (
            <IconButton className={styles["delete-club-photo-icon-container"]}>
              <Icon
                className={styles["delete-club-photo-icon"]}
                name="CloseStroke"
              />
            </IconButton>
          )}
        </div>
        <div className={styles["modal-right-container"]}>
          <AppInput
            value={clubItem?.title!}
            classNameContainer={styles["input-container"]}
            classNameInput={cn(
              styles["input-styles"],
              styles["input-club-name"]
            )}
            name="title"
            placeholder={"Club name"}
          />
          <Select
            searchable={false}
            dropdownPosition="bottom"
            dropdownHeight="300px"
            direction="ltr"
            searchBy="name"
            separator
            dropdownHandle
            closeOnSelect
            placeholder="Select administrator"
            keepSelectedInList
            color={AppColors.green}
            values={dropdownValues}
            labelField="name"
            valueField="value"
            options={[
              { name: "Raquel Aranda", value: 0 },
              { name: "Viserys Targaryen", value: 1 },
              { name: "Jaime Lannister", value: 2 },
              { name: "Arya Stark", value: 3 },
              { name: "Tyrion Lannister", value: 4 },
              { name: "Brienne Tharth", value: 5 },
              { name: "Jorah Mormont", value: 6 },
              { name: "Theon Greyjoy", value: 7 },
              { name: "Sansa Stark", value: 8 },
              { name: "Davos Seaworth", value: 9 },
            ]}
            onChange={(values) => {
              setDropdownValues(values);
            }}
          />

          <div className={styles["rating-container"]}>
            <AppText>{"Rating:"}</AppText>
            {/* <Rating
              disabled
              value={clubItem?.rating}
              onChange={(event, newValue) => {}}
              size="small"
              sx={{ ml: 1 }}
            /> */}
          </div>
          {renderItemInfoText("Tarif", clubItem?.tariff!)}
          <div className={styles["modal-club-desc-container"]}>
            <AppText variant="body2">{"Description"}</AppText>
            <AppInput
              value={clubItem?.desc!}
              classNameContainer={styles["input-container"]}
              classNameInput={cn(styles["input-styles"], styles["input-desc"])}
              name="desc"
              placeholder={clubItem?.desc!}
            />
          </div>
          <div className={styles["modal-club-bottom-buttons-container"]}>
            <AppButton text={"Save"} />
            <AppButton
              onClick={closeModal}
              variant="outlined"
              color="error"
              text={"Cancel"}
            />
          </div>
        </div>
      </Fragment>
    );
  };

  const renderClubItem = (item: ClubItemType) => {
    const moreInfoButtonOnClick = () => {
      setClubItem(item);
      openClubModal();
    };

    return (
      <div className={cn(styles["item-container"], styles["shadow"])}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Box
              className={cn(styles["item-image-container"], styles["shadow"])}
              sx={{ maxWidth: { xs: "100%", sm: "250px" } }}
            >
              {item.image ? (
                <img
                  src={item.image}
                  className={cn(styles["item-image"])}
                  alt={item.title}
                />
              ) : (
                <AppText color="gray3" variant="h4">
                  {"No club image"}
                </AppText>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={styles["item-info"]}>
              <AppText variant="h4">{item.title}</AppText>
              <AppText variant="body3" sx={{ mt: 1 }}>
                {item.desc}
              </AppText>
              {renderItemInfoText("Admin", item.admin)}
              <div className={styles["rating-container"]}>
                <AppText>{"Rating:"}</AppText>
                {/* <Rating
                  disabled
                  value={item.rating}
                  onChange={(event, newValue) => {}}
                  size="small"
                  sx={{ ml: 1 }}
                /> */}
              </div>
              {renderItemInfoText("Tariff", item.tariff)}
              <AppButton
                onClick={moreInfoButtonOnClick}
                sx={{ mt: 2, alignSelf: "flex-end" }}
                text={"More info"}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  const renderClubsWrapper = (title: string, data: ClubItemType[]) => {
    return (
      <div className={styles["clubs-wrapper"]}>
        <AppText color="white" variant="h3">
          {title}
        </AppText>
        <Grid container spacing={8}>
          {data.map((club) => (
            <Grid item xs={12} md={6}>
              {renderClubItem(club)}
            </Grid>
          ))}
        </Grid>
      </div>
    );
  };

  return (
    <AuthorizedLayout>
      <AppButton
        className={styles["city-button"]}
        color="white1"
        variant="text"
        text={"Granada"}
        endIcon={<Icon name="Arrow" />}
      />

      <div className={styles["search-container"]}>
        <AppInput
          startIcon={<Icon className={styles["search-icon"]} name="Search" />}
          classNameInput={styles["search-input"]}
          name="search"
          placeholder="Search"
        />
      </div>

      {renderClubsWrapper("Top clubs", clubsItems)}
      {renderClubsWrapper("All clubs", clubsItems)}

      {/* <AppModal
        leftControl={
          <AppButton
            sx={{ fontFamily: "Malgun-Bold", fontSize: 13 }}
            startIcon={<TrashIcon fontSize="large" />}
            variant="text"
            color="error"
            text={"Delete Club"}
            onClick={openClubDeleteModal}
          />
        }
        width="800px"
        open={isModal === ModalState.ClubModal}
        onClose={closeModal}
        childrenContainerClassname={styles["modal-container"]}
      >
        {renderEditClubModalContent()}
      </AppModal> */}

      <ClubModal
        closeModal={closeModal}
        isOpen={isModal === ModalState.ClubModal}
      />

      <DeleteModal
        open={isModal === ModalState.DeleteClubModal}
        onClose={() => {}}
        onDelete={() => {}}
        title={"Delete club"}
        desc={
          "Lorem ipsum dolor sit amet consectetur. Orci maecenas eu amet enim. Ac enim laoreet massa adipiscing feugiat."
        }
      />
    </AuthorizedLayout>
  );
}

export default SuperAdminClubsPage;
