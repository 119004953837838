import React, { useEffect, useRef, useState } from "react";
import styles from "./CreateEditNewsModal.module.css";
import ModalWrapper from "components/modals/ModalWrapper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { isLoading } from "utils";
import { LoadingSubject } from "slices/loadingSlice";
import {
  INews,
  NewsStatusEnum,
  createNews,
  deleteNews,
  getNewsAvailableAmount,
  publishNews,
  rejectNews,
  setNewsPhoto,
  updateNews,
} from "slices/newsSlice";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { AppColors } from "AppTheme";
import { Button, Checkbox } from "@mui/material";
import { Column, Row } from "components/blocks";
import ReactQuill from "react-quill";
import AppText from "components/AppText/AppText";
import { useLoadings } from "hooks/useLoadings";
import { useRoles } from "hooks/useRoles";
import toast from "react-hot-toast";
import Comments from "pages/NewsPage/Comments/Comments";
import { getCommentsList } from "slices/commentsSlice";

type Props = {
  open: boolean;
  onClose: () => void;
  selectedItem?: INews;
  clubId: string;
  fromTable?: boolean;
};

export const CreateEditNewsModal = (props: Props) => {
  const { open, onClose, selectedItem, clubId, fromTable } = props;
  const dispatch = useDispatch();

  const [image, setImage] = useState<{
    preview: string | undefined;
    raw: File | undefined;
  }>({
    preview: undefined,
    raw: undefined,
  });
  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [showComments, setShowComments] = useState(false);

  const [isGlobal, setIsGlobal] = useState(false);

  const { isPublishNewsLoading, isRejectNewsLoading, isDeleteCommentLoading } =
    useLoadings();
  const roles = useRoles();

  const { subjects } = useSelector((s: RootState) => s.loading);
  const isLoadingCreateEditNews = isLoading(
    LoadingSubject.CreateEditNews,
    subjects
  );
  const isLoadingSetNewsPhoto = isLoading(
    LoadingSubject.SetNewsPhoto,
    subjects
  );

  const { token } = useSelector((s: RootState) => s.auth);
  const { commentsList } = useSelector((s: RootState) => s.comments);

  const filteredCommentsList = commentsList.filter(
    (c) => c.news_id === selectedItem?.id
  );

  const { availableAmount } = useSelector((s: RootState) => s.news);
  const readOnly =
    selectedItem?.moderation_status === NewsStatusEnum.Published &&
    (roles.ClubOwner || roles.ManagerAdmin || roles.Staff);

  const inputFile = useRef<HTMLInputElement | null>(null);

  const onImageClick = () => {
    if (readOnly) {
      return;
    }
    inputFile?.current?.click();
  };

  useEffect(() => {
    setImage({
      preview: selectedItem?.photo_url,
      raw: undefined,
    });
    setTitle(selectedItem?.title || "");
    setContent(selectedItem?.content || "");

    // @ts-ignore
    // dispatch(getNewsAvailableAmount(selectedItem?.club_id));
  }, [selectedItem]);

  useEffect(() => {
    if (selectedItem) {
      setIsGlobal(selectedItem.is_global);
    } else {
      setIsGlobal(false);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (selectedItem && !isDeleteCommentLoading) {
      // @ts-ignore
      dispatch(getCommentsList([selectedItem.id]));
    }
  }, [selectedItem, isDeleteCommentLoading]);

  const onSubmit = () => {
    if (!availableAmount?.local_news_amount && !selectedItem) {
      toast.error("You dont have available news amount!");
      return;
    }

    if (selectedItem) {
      // update
      if (!title || !content) {
        toast.error("Please fill out all fields!");
        return;
      }
      if (
        title !== selectedItem.title ||
        content !== selectedItem.content ||
        isGlobal !== selectedItem.is_global
      ) {
        dispatch(
          // @ts-ignore
          updateNews(
            selectedItem.id,
            {
              content: content,
              is_global: isGlobal,
              title: title,
            },
            fromTable ? clubId : clubId,
            onCloseModal
          )
        );
      }
      if (image.raw) {
        const formData = new FormData();
        formData.append("file", image.raw);
        dispatch(
          // @ts-ignore
          setNewsPhoto(
            selectedItem.id,
            formData,
            fromTable ? clubId : selectedItem.club_id,
            onCloseModal
          )
        );
      }
    } else {
      // create
      if (!image.raw || !title || !content) {
        toast.error("Please fill out all fields!");
        return;
      }

      const formData = new FormData();
      formData.append("picture", image.raw);
      formData.append("title", title);
      formData.append("content", content);
      formData.append("club_id", clubId!);
      formData.append("is_global", isGlobal.toString());

      // @ts-ignore
      dispatch(createNews(formData, clubId, isGlobal, onCloseModal));
    }

    // if (image.raw) {
    //   const formData = new FormData();
    //   formData.append("file", image.raw);
    //   // @ts-ignore
    //   dispatch(updateClubImage(clubItem?.id, formData));
    // }
    // // @ts-ignore
    // dispatch(updateClub(clubItem?.id, newValues, values.amount, closeModal));
  };

  const onCloseModal = () => {
    onClose();
    setImage({ preview: undefined, raw: undefined });
    setTitle("");
    setContent("");
    setShowComments(false);
  };

  const onChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e?.target?.files;
    if (files?.length) {
      const image = files[0];
      if (image.size > 4194304) {
        toast.error("Please select an image smaller than 4,2 mb");
        return;
      }
      setImage({ preview: URL.createObjectURL(image), raw: image });
    }
  };

  const titleText = selectedItem ? "Edit news" : "Create news";

  return (
    <ModalWrapper
      headerChildren={
        selectedItem && fromTable && (roles.SuperAdmin || roles.Moderator) ? (
          <>
            <Button
              onClick={() =>
                // @ts-ignore
                dispatch(publishNews(selectedItem?.id, selectedItem?.club_id))
              }
              variant="outlined"
              children="Publish"
              disabled={
                isPublishNewsLoading ||
                selectedItem?.moderation_status === NewsStatusEnum.Published
              }
            />
            <Button
              onClick={() =>
                // @ts-ignore
                dispatch(rejectNews(selectedItem?.id, selectedItem?.club_id))
              }
              variant="outlined"
              color="error"
              children="Reject"
              disabled={
                isRejectNewsLoading ||
                selectedItem?.moderation_status === NewsStatusEnum.Rejected
              }
            />
          </>
        ) : (
          <></>
        )
      }
      title={
        fromTable ? (
          <Button
            onClick={() => {
              // @ts-ignore
              dispatch(deleteNews(selectedItem?.id));
            }}
            variant="outlined"
            color="error"
          >
            {"Delete news"}
          </Button>
        ) : (
          titleText
        )
      }
      open={open}
      onClose={onCloseModal}
    >
      <div>
        <div
          onClick={onImageClick}
          style={{ cursor: readOnly ? "initial" : "pointer" }}
          className={styles["image-container"]}
        >
          {image.preview ? (
            <img
              className={styles["image"]}
              src={
                image.raw
                  ? `${image.preview}`
                  : `${image.preview}&token=${token}`
              }
              alt="news"
            />
          ) : (
            <AddCircleOutlineIcon
              sx={{ fill: AppColors.green, width: 100, height: 100 }}
            />
          )}
          <input
            name="image"
            autoComplete="off"
            onChange={onChangeImage}
            accept="image/*"
            type="file"
            id="file"
            ref={inputFile}
            style={{ display: "none" }}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 20,
            marginLeft: 10,
          }}
        >
          <div>
            <AppText>{"Type global:"}</AppText>
            <Checkbox
              disabled={availableAmount?.global_news_amount === 0 || readOnly}
              onChange={() => setIsGlobal(!isGlobal)}
              value={isGlobal}
              checked={isGlobal}
            />
          </div>
          <div style={{ marginRight: 10 }}>
            <AppText>{"Status: "}</AppText>
            <AppText>{selectedItem?.moderation_status || "none"}</AppText>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 10,
          }}
        >
          <AppText>
            {`According on your plan you have ${availableAmount?.global_news_amount}
             global and ${availableAmount?.local_news_amount} local news amount`}
          </AppText>
        </div>
        <div>
          <input
            style={{
              border: "none",
              outline: "none",
              borderBottom: "1.5px solid var(--gray3)",
              marginTop: 20,
              width: "98%",
              height: 40,
              fontSize: 25,
              paddingLeft: 5,
            }}
            onChange={(e) => setTitle(e.target.value)}
            name="title"
            value={title}
            disabled={readOnly}
          />
        </div>

        <Column center>
          <Column sx={{ width: "100%" }}>
            <ReactQuill
              className={styles["quill-styles"]}
              theme="snow"
              value={content}
              style={{ height: 200, marginTop: 20 }}
              onChange={(text) => setContent(text)}
              readOnly={readOnly}
            />
            <Row sx={{ mt: 11, gap: { xs: 4, md: 8 } }}>
              <Button
                disabled={
                  isLoadingCreateEditNews || isLoadingSetNewsPhoto || readOnly
                }
                onClick={onSubmit}
                fullWidth
                variant="contained"
                children="Save"
              />
              <Button
                onClick={onCloseModal}
                fullWidth
                variant="outlined"
                color="error"
                children="Cancel"
              />
            </Row>
            {selectedItem && filteredCommentsList.length ? (
              <>
                <Button
                  sx={{ mt: 4 }}
                  color={"gray3"}
                  disabled={
                    isLoadingCreateEditNews || isLoadingSetNewsPhoto || readOnly
                  }
                  onClick={() => setShowComments(!showComments)}
                  fullWidth
                  variant="outlined"
                  children={
                    showComments
                      ? `Close comments section (${filteredCommentsList.length})`
                      : `Open comments section (${filteredCommentsList.length})`
                  }
                />
                {showComments && (
                  <div style={{ marginTop: 10 }}>
                    <Comments newsId={selectedItem.id} />
                  </div>
                )}
              </>
            ) : (
              <></>
            )}
          </Column>
        </Column>
      </div>
    </ModalWrapper>
  );
};
