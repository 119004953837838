import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../store/store";
import { baseUrl } from "../constants";
import { loadingEnd, loadingStart, LoadingSubject } from "./loadingSlice";

export interface IProduct {
  id: string;
  category_id: string;
  image_url: string;
  name: string;
  description: string;
  amount: number;
  price: number;
  delete?: boolean;
}

export interface IProductState {
  productList: IProduct[];
}

const initialState: IProductState = {
  productList: [],
};

const product = createSlice({
  name: "product",
  initialState,
  reducers: {
    _getProductList: (state, { payload }: PayloadAction<IProduct[]>) => {
      return { ...state, productList: payload };
    },
  },
});

export const getProductList = (
  categoryId: string
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(_getProductList([]));
    dispatch(loadingStart(LoadingSubject.ProductList));
    const token = getState().auth.token;
    const response = await fetch(
      `${baseUrl}/api/category/${categoryId}/product`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    const jsonData = await response.json();

    if (response.status === 200) {
      dispatch(_getProductList(jsonData));
    } else {
    }
  } catch (e) {
  } finally {
    dispatch(loadingEnd(LoadingSubject.ProductList));
  }
};

export const createProduct = (
  categoryId: string,
  data: FormData,
  callback?: () => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.CreateProduct));
    const token = getState().auth.token;
    const response = await fetch(`${baseUrl}/api/product`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: data,
    });
    const jsonData = await response.json();

    if (response.status === 200) {
      dispatch(getProductList(categoryId));
      callback && callback();
    } else {
    }
  } catch (e) {
  } finally {
    dispatch(loadingEnd(LoadingSubject.CreateProduct));
  }
};

export const deleteProduct = (
  productId: string,
  categoryId: string,
  callback?: () => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.DeleteProduct));
    const token = getState().auth.token;
    const response = await fetch(`${baseUrl}/api/product/${productId}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    const jsonData = await response.json();

    if (response.status === 200) {
      dispatch(getProductList(categoryId));
      callback && callback();
    } else {
    }
  } catch (e) {
  } finally {
    dispatch(loadingEnd(LoadingSubject.DeleteProduct));
  }
};

export const deleteSelectedProducts = (
  productIds: string[],
  categoryId: string,
  callback?: () => void
): AppThunk => async (dispatch, getState) => {
  dispatch(loadingStart(LoadingSubject.DeleteSelectedProducts));
  for (let pId of productIds) {
    try {
      const token = getState().auth.token;
      const response = await fetch(`${baseUrl}/api/product/${pId}`, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      const jsonData = await response.json();

      if (response.status === 200) {
      } else {
      }
    } catch (e) {
    } finally {
      //
    }
  }
  dispatch(loadingEnd(LoadingSubject.DeleteSelectedProducts));
  dispatch(getProductList(categoryId));
  callback && callback();
};

type IUpdateProductType = {
  category_id: string;
  name: string;
  description: string;
  amount: number;
  price: number;
};
export const updateProduct = (
  productId: string,
  data: IUpdateProductType,
  callback?: () => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.UpdateProduct));
    const token = getState().auth.token;
    const response = await fetch(`${baseUrl}/api/product/${productId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });
    const jsonData = await response.json();

    if (response.status === 200) {
      dispatch(getProductList(data.category_id));
      callback && callback();
    } else {
    }
  } catch (e) {
  } finally {
    dispatch(loadingEnd(LoadingSubject.UpdateProduct));
  }
};

export const updateProductImage = (
  productId: string,
  categoryId: string,
  data: FormData,
  callback?: () => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.UpdateProductImage));
    const token = getState().auth.token;
    const response = await fetch(
      `${baseUrl}/api/product/${productId}/image`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: data,
      }
    );
    const jsonData = await response.json();

    if (response.status === 200) {
      dispatch(getProductList(categoryId));
      callback && callback();
    } else {
    }
  } catch (e) {
  } finally {
    dispatch(loadingEnd(LoadingSubject.UpdateProductImage));
  }
};

export const { _getProductList } = product.actions;

export default product.reducer;
