import AppForm from "../../../forms/AppForm";
import { FormikValues } from "formik";
import { useSchemas } from "../../../helpers/schemas";
import AppInput from "../../../components/AppInput/AppInput";
import styles from "./EditClubForm.module.css";
import AppButton from "../../../components/AppButton/AppButton";
import { AppColors } from "../../../AppTheme";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IClub, updateClub, updateClubImage } from "../../../slices/clubSlice";
import { RootState } from "../../../store/rootReducer";
import { isLoading } from "../../../utils";
import { LoadingSubject } from "../../../slices/loadingSlice";
import AppText from "../../../components/AppText/AppText";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { Column } from "components/blocks";
import { useRoles } from "hooks/useRoles";
import { useIntl } from "react-intl";
import { baseUrl } from "./../../../constants";

type Props = {
  closeModal: () => void;
  clubItem?: IClub;
};

type AddressType = {
  lng: string;
  lat: string;
  address: string;
};

const initialAddress: AddressType = {
  lng: "",
  lat: "",
  address: "",
};

export const handleParseAddress = (value: string = "") => {
  let final: AddressType = initialAddress;
  try {
    final = JSON.parse(value);
  } catch (e) {
    final = { ...initialAddress, address: value };
  }
  return final;
};

function EditClubForm(props: Props) {
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const { clubItem, closeModal } = props;
  const { token } = useSelector((s: RootState) => s.auth);

  //-------------------------------------------------------------------------------------------
  const parsedAddress = handleParseAddress(clubItem?.address);
  const [addressState, setAddressState] = useState(parsedAddress);

  const [inputAddressValue, setInputAddressValue] = useState(
    parsedAddress.address
  );
  const [loading, setLoading] = useState(false);
  const [addressList, setAddressList] = useState([]);

  useEffect(() => {
    if (inputAddressValue) {
      const timeoutId = setTimeout(
        () => getLocationByQuery(inputAddressValue),
        800
      );
      return () => clearTimeout(timeoutId);
    }
  }, [inputAddressValue]);

  const getLocationByQuery = async (textQuery: string) => {
    if (!textQuery) {
      return;
    }
    try {
      setLoading(true);
      const url = `${baseUrl}/api/google/places/search?query=${textQuery}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      if (response?.status === 200) {
        const jsonData = await response.json();
        const addressList = jsonData?.results.map((el: any) => ({
          lat: el?.geometry?.location?.lat,
          lng: el?.geometry?.location?.lng,
          address: el?.formatted_address,
        }));
        setAddressList(addressList);
      }
    } catch (error) {
      console.error("getLocationByQueryERR", error);
    } finally {
      setLoading(false);
    }
  };
  //-------------------------------------------------------------------------------------------

  const { updateClubSchema } = useSchemas();
  const dispatch = useDispatch();
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [image, setImage] = useState<{
    preview: string | undefined;
    raw: File | undefined;
  }>({
    preview: clubItem?.picture_url,
    raw: undefined,
  });
  const subjects = useSelector((s: RootState) => s.loading.subjects);
  const { balance } = useSelector((s: RootState) => s.balance);

  const isLoadingUpdateClub = isLoading(LoadingSubject.UpdateClub, subjects);
  const isLoadingUpdateClubImage = isLoading(
    LoadingSubject.UpdateClubImage,
    subjects
  );
  const roles = useRoles();

  const onSubmit = (values: FormikValues) => {
    const newValues = {
      name: values.name,
      color_hex: "#ffffff",
      card_style_type: "full",
      show_product_amount: false,
      gradient_angle: 0,
      address: JSON.stringify(addressState),
      description: values.description,
      is_top: clubItem ? clubItem?.is_top : false,
      sorting: clubItem ? clubItem?.sorting : 0,
    };
    if (image.raw) {
      const formData = new FormData();
      formData.append("file", image.raw);

      // @ts-ignore
      dispatch(updateClubImage(clubItem?.id, formData));
    }
    // @ts-ignore
    dispatch(updateClub(clubItem?.id, newValues, values.amount, closeModal));
  };

  const onImageClick = () => {
    inputFile?.current?.click();
  };

  return (
    <AppForm
      initialValues={{
        name: clubItem?.name,
        address: clubItem?.address,
        amount: 0,
        description: clubItem?.description,
      }}
      onSubmit={onSubmit}
      children={
        <>
          <Grid container spacing={8}>
            <Grid item xs={12} lg={6}>
              <div onClick={onImageClick} className={styles["left-container"]}>
                <img
                  className={styles["image-style"]}
                  src={image.preview}
                  alt="club-img"
                />
                <input
                  autoComplete="off"
                  onChange={(event) => {
                    const files = event?.target?.files;
                    if (files?.length) {
                      const image = files[0];
                      setImage({
                        preview: URL.createObjectURL(image),
                        raw: image,
                      });
                    }
                  }}
                  accept="image/*"
                  type="file"
                  id="file"
                  ref={inputFile}
                  style={{ display: "none" }}
                />
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Column>
                <AppInput
                  name="name"
                  classNameContainer={styles["input-container-style"]}
                  classNameInput={styles["input-style"]}
                  placeholder={t("text.type-club-name-here") + "..."}
                  errorColor={AppColors.red}
                  isForm
                />
                <Autocomplete
                  options={addressList}
                  filterOptions={(options) => options}
                  getOptionLabel={(option: { address: string }) =>
                    option?.address
                  }
                  value={addressState}
                  onChange={(event, value: any) => {
                    if (value) {
                      setAddressState(value);
                    }
                  }}
                  loading={loading}
                  inputValue={inputAddressValue}
                  onInputChange={(event, newInputValue) => {
                    setInputAddressValue(newInputValue);
                  }}
                  onClose={() => {
                    setInputAddressValue("");
                    setAddressList([]);
                  }}
                  renderOption={(props, option) => (
                    <li {...props}>{option?.address}</li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="address"
                      placeholder={t("text.address")}
                      variant="standard"
                    />
                  )}
                />
                {(roles.SuperAdmin || roles.Moderator) && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: 250,
                    }}
                  >
                    <AppText variant="body4">
                      {t("text.current-deposit") + `: €${balance?.amount || 0}`}
                    </AppText>
                    <AppText variant="body4">
                      {t(`text.increase-or-decrease-deposit`)}
                    </AppText>
                    <AppInput
                      disabled={
                        roles.ClubOwner || roles.ManagerAdmin || roles.Staff
                      }
                      name="amount"
                      startIcon={"€"}
                      classNameContainer={styles["input-container-style"]}
                      classNameInput={styles["input-style"]}
                      placeholder={t("text.deposit")}
                      errorColor={AppColors.red}
                      isForm
                    />
                  </div>
                )}
                <AppInput
                  name="description"
                  classNameContainer={styles["input-container-style"]}
                  classNameInput={styles["input-style"]}
                  placeholder={t("desc")}
                  errorColor={AppColors.red}
                  isForm
                  isTextArea
                  maxLength={256}
                  style={{
                    marginTop: 10,
                    height: 100,
                    backgroundColor: AppColors.white,
                  }}
                />
              </Column>
            </Grid>
          </Grid>
          <div className={styles["buttons-container"]}>
            <AppButton
              isForm
              sx={{ width: "30%" }}
              text={t("general.save")}
              loading={isLoadingUpdateClub || isLoadingUpdateClubImage}
            />
            <AppButton
              onClick={closeModal}
              sx={{ width: "30%" }}
              variant="outlined"
              color="error"
              text={t("general.cancel")}
            />
          </div>
        </>
      }
      validationSchema={updateClubSchema}
      enableReinitialize={true}
    />
  );
}
export default EditClubForm;
