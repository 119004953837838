import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../store/store";
import { baseUrl } from "../constants";
import { loadingEnd, loadingStart, LoadingSubject } from "./loadingSlice";
import { getProductList } from "./productSlice";

export enum CategoryTypeEnum {
  Menu420 = "420",
  Bar = "bar",
  Dispense = "dispense",
}

export interface ICategory {
  id: string;
  club_id: string;
  image_url: string;
  name: string;
  description: string;
  type: string;
}

export interface ICategoryState {
  categoryList: ICategory[];
}

const initialState: ICategoryState = {
  categoryList: [],
};

const category = createSlice({
  name: "category",
  initialState,
  reducers: {
    _getCategoryList: (state, { payload }: PayloadAction<ICategory[]>) => {
      return { ...state, categoryList: payload };
    },
  },
});

export const getCategoryList =
  (
    clubId: string,
    type: CategoryTypeEnum,
    onSuccess?: (categoryItem: ICategory) => void
  ): AppThunk => async (dispatch, getState) => {
    try {
      dispatch(loadingStart(LoadingSubject.CategoryList));
      const { token } = getState().auth;
      const params = `/${clubId}/category${type ? "?type=" + type : ""}`;
      const url = `${baseUrl}/api/club${params}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("getCategoryListResponse", response);
      const jsonData = await response.json();
      if (response.status === 200) {
        console.log("getCategoryListJson", jsonData);
        dispatch(_getCategoryList(jsonData));
        if (jsonData.length) {
          const firstCategoryItem = jsonData[0];
          onSuccess && onSuccess(firstCategoryItem);
          dispatch(getProductList(firstCategoryItem.id));
        }
      }
    } catch (e) {
    } finally {
      dispatch(loadingEnd(LoadingSubject.CategoryList));
    }
  };

export const createCategory =
  (
    clubId: string,
    data: FormData,
    type: CategoryTypeEnum,
    callback?: (smth?: any) => void
  ): AppThunk => async (dispatch, getState) => {
    try {
      dispatch(loadingStart(LoadingSubject.CreateCategory));
      const token = getState().auth.token;
      const response = await fetch(`${baseUrl}/api/category`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: data,
      });
      console.log("createCategoryResponse", response);
      const jsonData = await response.json();

      if (response.status === 200) {
        console.log("createCategoryJson", jsonData);
        dispatch(getCategoryList(clubId, type));
        callback && callback(jsonData.id);
      } else {
        console.log("createCategoryNot200", jsonData);
      }
    } catch (e) { } finally {
      dispatch(loadingEnd(LoadingSubject.CreateCategory));
    }
  };

export const deleteCategory =
  (
    clubId: string,
    categoryId: string,
    type: CategoryTypeEnum,
    callback?: () => void
  ): AppThunk => async (dispatch, getState) => {
    try {
      dispatch(loadingStart(LoadingSubject.DeleteCategory));
      const token = getState().auth.token;
      const response = await fetch(`${baseUrl}/api/category/${categoryId}`, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      console.log("deleteCategoryResponse", response);
      const jsonData = await response.json();

      if (response.status === 200) {
        console.log("deleteCategoryJson", jsonData);
        dispatch(getCategoryList(clubId, type));
        callback && callback();
      } else {
        console.log("deleteCategoryNot200", jsonData);
      }
    } catch (e) { }
    finally {
      dispatch(loadingEnd(LoadingSubject.DeleteCategory));
    }
  };

type IUpdateCategoryType = {
  club_id: string;
  name: string;
  description: string;
};
export const updateCategory =
  (
    categoryId: string,
    data: IUpdateCategoryType,
    type: CategoryTypeEnum,
    callback?: () => void
  ): AppThunk => async (dispatch, getState) => {
    try {
      dispatch(loadingStart(LoadingSubject.UpdateCategory));
      const token = getState().auth.token;
      const response = await fetch(`${baseUrl}/api/category/${categoryId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      });
      console.log("updateCategoryResponse", response);
      const jsonData = await response.json();

      if (response.status === 200) {
        console.log("updateCategoryJson", jsonData);
        dispatch(getCategoryList(data.club_id, type));
        callback && callback();
      } else {
        console.log("updateCategoryNot200", jsonData);
      }
    } catch (e) { }
    finally {
      dispatch(loadingEnd(LoadingSubject.UpdateCategory));
    }
  };

export const updateCategoryImage =
  (
    clubId: string,
    categoryId: string,
    data: FormData,
    type: CategoryTypeEnum,
    callback?: () => void
  ): AppThunk => async (dispatch, getState) => {
    try {
      dispatch(loadingStart(LoadingSubject.UpdateCategoryImage));
      const token = getState().auth.token;
      const response = await fetch(
        `${baseUrl}/api/category/${categoryId}/image`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
          },
          body: data,
        }
      );
      console.log("updateCategoryImageResponse", response);
      const jsonData = await response.json();

      if (response.status === 200) {
        console.log("updateCategoryImageJson", jsonData);
        dispatch(getCategoryList(clubId, type));
        callback && callback();
      } else {
        console.log("updateCategoryImageNot200", jsonData);
      }
    } catch (e) { }
    finally {
      dispatch(loadingEnd(LoadingSubject.UpdateCategoryImage));
    }
  };

export const { _getCategoryList } = category.actions;

export default category.reducer;
