import { useEffect } from "react";
import styles from "./ClubsWithoutTopsTab.module.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/rootReducer";
import { IClub, getEmployeeList } from "../../../../slices/clubSlice";
import AppText from "../../../../components/AppText/AppText";
import { Grid } from "@mui/material";
import AppButton from "../../../../components/AppButton/AppButton";
import { useNav } from "../../../../hooks/useNav";
import { useIntl } from "react-intl";
import { useRoles } from "hooks/useRoles";
import { Column, Row } from "components/blocks";
import { handleParseAddress } from "pages/ClubProfilePage/forms/EditClubForm";
import { UserRole } from "slices/usersSlice";

export default function ClubsWithoutTopsTab() {
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const { viewClubProfile, viewClubMenu } = useNav();
  const { clubList } = useSelector((s: RootState) => s.club);
  const dispatch = useDispatch();
  const { me } = useSelector((s: RootState) => s.auth);
  const { viewCreateClub } = useNav();
  const roles = useRoles();

  useEffect(() => {
    // @ts-ignore
    dispatch(getEmployeeList([me?.id], undefined, true));
  }, []);

  const renderClubItem = (item: IClub) => {
    const parsedAddress = handleParseAddress(item?.address);

    return (
      <div className={styles["club-item-container"]}>
        <img
          className={styles["club-item-image"]}
          src={item.picture_url}
          alt={item.name}
        />
        <div className={styles["club-item-info-container"]}>
          <div className={styles["club-item-desc-container"]}>
            <AppText sx={{ fontSize: 20 }} color={"white"} variant="body2">
              {item.name}
            </AppText>
            <AppText
              color={"gray1"}
              className={styles["club-item-name"]}
              variant="body3"
              sx={{ fontSize: 12 }}
            >
              {parsedAddress.address}
            </AppText>
          </div>
          <AppButton
            onClick={() =>
              roles.Staff ? viewClubMenu(item.id) : viewClubProfile(item.id)
            }
            text={t("text.more-info")}
          />
        </div>
      </div>
    );
  };

  return (
    <Column>
      {roles.ClubOwner && (
        <AppButton
          onClick={viewCreateClub}
          sx={{ width: "10%", mb: 5, alignSelf: "flex-end" }}
          text={"Create club"}
        />
      )}
      <Grid container spacing={8}>
        {clubList.map((item) => (
          <Grid item xs={12} md={6}>
            {renderClubItem(item)}
          </Grid>
        ))}
      </Grid>
    </Column>
  );
}
