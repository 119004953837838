import styles from "./ClubMenuPage.module.css";
import Text from "../../components/AppText/AppText";
import { useNav } from "../../hooks/useNav";
import { useParams } from "react-router-dom";
import AuthorizedLayout from "layouts/AuthorizedLayout";
import { Grid } from "@mui/material";
import { useRoles } from "hooks/useRoles";
import { CategoryTypeEnum, _getCategoryList } from "slices/categorySlice";
import { useDispatch } from "react-redux";
import { _getProductList } from "slices/productSlice";
import { useIntl } from "react-intl";

type ClubItemList = {
  name: string;
  image: string;
  route: () => void;
};

function ClubMenuPage() {
  const { viewAdminEdit, viewGoBack } = useNav();
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const params = useParams();
  const roles = useRoles();

  const dispatch = useDispatch();

  const myClubItem = {
    name: t("text.my-club"),
    image: require("./../../assets/images/raster/ClubMenu/club-logo.png"),
    route: viewGoBack,
  };

  const clubsList: ClubItemList[] = [
    {
      name: t("text.menu-4-20"),
      image: require("./../../assets/images/raster/ClubMenu/cannabis.png"),
      route: () => viewAdminEdit(params.id!, CategoryTypeEnum.Menu420),
    },
    {
      name: t("text.bar"),
      image: require("./../../assets/images/raster/ClubMenu/wineglass.png"),
      route: () => viewAdminEdit(params.id!, CategoryTypeEnum.Bar),
    },
    {
      name: t("text.dispense"),
      image: require("./../../assets/images/raster/ClubMenu/scales-neon.png"),
      route: () => viewAdminEdit(params.id!, CategoryTypeEnum.Dispense),
    },
  ];

  if (
    roles.ClubOwner ||
    roles.ManagerAdmin ||
    roles.Moderator ||
    roles.SuperAdmin
  ) {
    clubsList.push(myClubItem);
  }
  const renderClubItem = (item: ClubItemList) => {
    return (
      <div
        className={styles["club-item-container"]}
        onClick={() => {
          dispatch(_getProductList([]));
          dispatch(_getCategoryList([]));
          item.route();
        }}
      >
        <div className={styles["club-item-image-wrapper"]}>
          <div className={styles["club-item-image-container"]}>
            <img
              className={styles["club-item-image"]}
              src={item.image}
              alt={item.name}
            />
          </div>
        </div>
        <div className={styles["club-item-text-wrapper"]}>
          <Text
            color={"white"}
            className={styles["club-item-name"]}
            variant="body2"
          >
            {item.name}
          </Text>
        </div>
      </div>
    );
  };

  return (
    <AuthorizedLayout>
      <Grid container spacing={8}>
        {clubsList.map((item) => (
          <Grid item xs={12} sm={6}>
            {renderClubItem(item)}
          </Grid>
        ))}
      </Grid>
    </AuthorizedLayout>
  );
}
export default ClubMenuPage;
