import styles from "./PrivacyPolicyPages.module.css";
import UnauthorizedLayout from "layouts/UnauthorizedLayout";

function PrivacyPolicyPage() {
  return (
    <UnauthorizedLayout>
      <div className={styles["container"]}>
        <div className="policy__wrapper">
          <h1 className="p-3">PRIVACY POLICY</h1>
          <p className="p-2 fw-light">Last updated May 14, 2024</p>

          <p className="p-2 fw-light">
            This is a privacy notice for the private company "CannaPush"("we",
            "us", or "our") describes how and why we may collect, store, use
            and/or share ("process") your information when you use our services
            ("Services"), such as when you:
          </p>

          <ul>
            <li className="fw-light">
              Download and use our mobile application (CannaPush), or any other
              application of ours that links to this privacy notice
            </li>
            <li className="fw-light">
              Engage with us in other related ways, including any sales,
              marketing, or events
            </li>
          </ul>

          <p className="p-2 fw-light">
            Questions or concerns? Reading this privacy notice will help you
            understand your privacy rights and choices. If you do not agree with
            our policies and practices, please do not use our Services. If you
            still have any questions or concerns, please contact us at
            admin@canna-push.com
          </p>

          <h1 className="p-3">SUMMARY OF KEY POINTS</h1>

          <p className="p-2 fw-light fst-italic">
            This summary provides key points from our privacy notice, but you
            can find out more details about any of these topics by clicking the
            link following each key point or by using our table of contents
            below to find the section you are looking for.
          </p>

          <p className="p-2 fw-light">
            What personal information do we process? When you visit, use, or
            navigate our Services, we may process personal information depending
            on how you interact with us and the Services, the choices you make,
            and the products and features you use.
          </p>
          <p className="p-2 fw-light">
            Do we process any sensitive personal information? We may process
            sensitive personal information when necessary with your consent or
            as otherwise permitted by applicable law.
          </p>
          <p className="p-2 fw-light">
            Do we receive any information from third parties? We do not receive
            any information from third parties.
          </p>
          <p className="p-2 fw-light">
            How do we process your information? We process your information to
            provide, improve, and administer our Services, communicate with you,
            for security and fraud prevention, and to comply with law. We may
            also process your information for other purposes with your consent.
            We process your information only when we have a valid legal reason
            to do so.
          </p>
          <p className="p-2 fw-light">
            In what situations and with which parties do we share personal
            information? We may share information in specific situations and
            with specific third parties.
          </p>
          <p className="p-2 fw-light">
            How do we keep your information safe? We have organizational and
            technical processes and procedures in place to protect your personal
            information. However, no electronic transmission over the internet
            or information storage technology can be guaranteed to be 100%
            secure, so we cannot promise or guarantee that hackers,
            cybercriminals, or other unauthorized third parties will not be able
            to defeat our security and improperly collect, access, steal, or
            modify your information.
          </p>
          <p className="p-2 fw-light">
            What are your rights? Depending on where you are located
            geographically, the applicable privacy law may mean you have certain
            rights regarding your personal information.
          </p>
          <p className="p-2 fw-light">
            How do you exercise your rights? The easiest way to exercise your
            rights is by submitting a data subject access request, or by
            contacting us. We will consider and act upon any request in
            accordance with applicable data protection laws.
          </p>
          <h1 className="p-2">TABLE OF CONTENTS</h1>
          <ul className="p-3">
            <li className="p-2">1. WHAT INFORMATION DO WE COLLECT?</li>
            <li className="p-2">2. HOW DO WE PROCESS YOUR INFORMATION?</li>
            <li className="p-2">
              3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
              INFORMATION?
            </li>
            <li className="p-2">
              4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </li>
            <li className="p-2">
              5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
            </li>
            <li className="p-2">
              6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </li>
            <li className="p-2">7. HOW LONG DO WE KEEP YOUR INFORMATION?</li>
            <li className="p-2">8. HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
            <li className="p-2">9. DO WE COLLECT INFORMATION FROM MINORS?</li>
            <li className="p-2">10. WHAT ARE YOUR PRIVACY RIGHTS?</li>
            <li className="p-2">11. CONTROLS FOR DO-NOT-TRACK FEATURES</li>
            <li className="p-2">12. DO WE MAKE UPDATES TO THIS NOTICE?</li>
            <li className="p-2">
              13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </li>
            <li className="p-2">
              14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </li>
          </ul>

          <h1 className="p-3">1. WHAT INFORMATION DO WE COLLECT?</h1>
          <h3 className="p-3">Personal information you disclose to us</h3>
          <p className="p-2 fw-light fst-italic">
            In Short: We collect personal information that you provide to us.
          </p>
          <p className="p-2 fw-light">
            We collect personal information that you voluntarily provide to us
            when you register on the Services, express an interest in obtaining
            information about us or our products and Services, when you
            participate in activities on the Services, or otherwise when you
            contact us.
          </p>
          <p className="p-2 fw-light">
            Personal Information Provided by You. The personal information that
            we collect depends on the context of your interactions with us and
            the Services, the choices you make, and the products and features
            you use. The personal information we collect may include the
            following:
          </p>
          <ul className="p-3">
            <li className="p-2 fw-light">names</li>
            <li className="p-2 fw-light">email</li>
            <li className="p-2 fw-light">addresses</li>
            <li className="p-2 fw-light">usernames</li>
            <li className="p-2 fw-light">passwords</li>
            <li className="p-2 fw-light">date of birth</li>
            <li className="p-2 fw-light">city</li>
            <li className="p-2 fw-light">geolocation information</li>
            <li className="p-2 fw-light">id number</li>
            <li className="p-2 fw-light">photo</li>
          </ul>
          <p className="p-2 fw-light">
            Sensitive Information. When necessary, with your consent or as
            otherwise permitted by applicable law, we process the following
          </p>

          <ul>
            <li className="p-3 fw-light">
              social security numbers or other government identifiers
            </li>
          </ul>
          <p className="p-2 fw-light">
            Application Data. If you use our application(s), we also may collect
            the following information if you choose to provide us with access or
            permission:
          </p>
          <ul>
            <li className="p-3 fw-light">
              <p className="fst-italic">Geolocation Information.</p>
              We may request access or permission to track location-based
              information from your mobile device, either continuously or while
              you are using our mobile application(s), to provide certain
              location-based services. If you wish to change our access or
              permissions, you may do so in your device's settings.
            </li>
            <li className="p-3 fw-light">
              <p className="fst-italic">Mobile Device Access.</p>
              We may request access or permission to certain features from your
              mobile device, including your mobile device's camera, and other
              features. If you wish to change our access or permissions, you may
              do so in your device's settings.
            </li>

            <li className="p-3 fw-light">
              <p className="fst-italic">Push Notifications.</p>
              We may request to send you push notifications regarding your
              account or certain features of the application(s). If you wish to
              opt out from receiving these types of communications, you may turn
              them off in your device's settings.
            </li>
          </ul>

          <p className="p-2 fw-light">
            This information is primarily needed to maintain the security and
            operation of our application(s), for troubleshooting, and for our
            internal analytics and reporting purposes.
          </p>

          <p className="p-2 fw-light">
            All personal information that you provide to us must be true,
            complete, and accurate, and you must notify us of any changes to
            such personal information.
          </p>

          <h1 className="p-3">2. HOW DO WE PROCESS YOUR INFORMATION?</h1>

          <p className="p-2 fw-light fst-italic">
            In Short: We process your information to provide, improve, and
            administer our Services, communicate with you, for security and
            fraud prevention, and to comply with law. We may also process your
            information for other purposes with your consent.
          </p>
          <p className="p-2 fw-light">
            We process your personal information for a variety of reasons,
            depending on how you interact with our Services, including:
          </p>
          <ul className="p-3">
            <li className="p-2 fw-light">
              To facilitate account creation and authentication and otherwise
              manage user accounts. We may process your information so you can
              create and log in to your account, as well as keep your account in
              working order.
            </li>
            <li className="p-2 fw-light">
              To request feedback. We may process your information when
              necessary to request feedback and to contact you about your use of
              our Services.
            </li>
            <li className="p-2 fw-light">
              To save or protect an individual's vital interest. We may process
              your information when necessary to save or protect an individual’s
              vital interest, such as to prevent harm.
            </li>
          </ul>

          <h1 className="p-3">
            3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
          </h1>

          <p className="p-2 fw-light fst-italic">
            In Short: We only process your personal information when we believe
            it is necessary and we have a valid legal reason (i.e., legal basis)
            to do so under applicable law, like with your consent, to comply
            with laws, to provide you with services to enter into or fulfill our
            contractual obligations, to protect your rights, or to fulfill our
            legitimate business interests.
          </p>

          <p className="p-2 fw-light">
            The General Data Protection Regulation (GDPR) and UK GDPR require us
            to explain the valid legal bases we rely on in order to process your
            personal information. As such, we may rely on the following legal
            bases to process your personal information:
          </p>

          <ul className="p-3">
            <li className="p-2 fw-light">
              Consent. We may process your information if you have given us
              permission (i.e., consent) to use your personal information for a
              specific purpose. You can withdraw your consent at any time.
            </li>
            <li className="p-2 fw-light">
              Legitimate Interests. We may process your information when we
              believe it is reasonably necessary to achieve our legitimate
              business interests and those interests do not outweigh your
              interests and fundamental rights and freedoms. For example, we may
              process your personal information for some of the purposes
              described in order to:
            </li>
            <li className="p-2 fw-light">
              Understand how our users use our products and services so we can
              improve user experience
            </li>
            <li className="p-2 fw-light">
              Legal Obligations. We may process your information where we
              believe it is necessary for compliance with our legal obligations,
              such as to cooperate with a law enforcement body or regulatory
              agency, exercise or defend our legal rights, or disclose your
              information as evidence in litigation in which we are involved.
            </li>
            <li className="p-2 fw-light">
              Vital Interests. We may process your information where we believe
              it is necessary to protect your vital interests or the vital
              interests of a third party, such as situations involving potential
              threats to the safety of any person.
            </li>
          </ul>

          <h1 className="p-3">
            4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </h1>

          <p className="p-2 fw-light fst-italic">
            In Short: We may share information in specific situations described
            in this section and/or with the following third parties.
          </p>

          <p className="p-2 fw-light">
            We may need to share your personal information in the following
            situations:
          </p>

          <ul className="p-3">
            <li className="p-2 fw-light">
              Business Transfers. We may share or transfer your information in
              connection with, or during negotiations of, any merger, sale of
              company assets, financing, or acquisition of all or a portion of
              our business to another company.
            </li>
            <li className="p-2 fw-light">
              When we use Google Maps Platform APIs. We may share your
              information with certain Google Maps Platform APIs (e.g., Google
              Maps API, Places API). We obtain and store on your device
              ("cache") your location. You may revoke your consent anytime by
              contacting us at the contact details provided at the end of this
              document.
            </li>
            <li className="p-2 fw-light">
              Affiliates. We may share your information with our affiliates, in
              which case we will require those affiliates to honor this privacy
              notice. Affiliates include our parent company and any
              subsidiaries, joint venture partners, or other companies that we
              control or that are under common control with us.
            </li>
            <li className="p-2 fw-light">
              Business Partners. We may share your information with our business
              partners to offer you certain products, services, or promotions.
            </li>
          </ul>
          <h1 className="p-3">
            5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
          </h1>
          <p className="p-2 fw-light fst-italic">
            In Short: We are not responsible for the safety of any information
            that you share with third parties that we may link to or who
            advertise on our Services, but are not affiliated with, our
            Services.
          </p>

          <p className="p-2 fw-light">
            The Services may link to third-party websites, online services, or
            mobile applications and/or contain advertisements from third parties
            that are not affiliated with us and which may link to other
            websites, services, or applications. Accordingly, we do not make any
            guarantee regarding any such third parties, and we will not be
            liable for any loss or damage caused by the use of such third-party
            websites, services, or applications. The inclusion of a link towards
            a third-party website, service, or application does not imply an
            endorsement by us. We cannot guarantee the safety and privacy of
            data you provide to any third parties. Any data collected by third
            parties is not covered by this privacy notice. We are not
            responsible for the content or privacy and security practices and
            policies of any third parties, including other websites, services,
            or applications that may be linked to or from the Services. You
            should review the policies of such third parties and contact them
            directly to respond to your questions.
          </p>

          <h1 className="p-3">
            6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          </h1>
          <p className="p-2 fw-light fst-italic">
            In Short: We may use cookies and other tracking technologies to
            collect and store your information.
          </p>

          <p className="p-2 fw-light">
            We may use cookies and similar tracking technologies (like web
            beacons and pixels) to access or store information. Specific
            information about how we use such technologies and how you can
            refuse certain cookies is set out in our Cookie Notice.
          </p>

          <h1 className="p-3">7. HOW LONG DO WE KEEP YOUR INFORMATION?</h1>
          <p className="p-2 fw-light fst-italic">
            In Short: We keep your information for as long as necessary to
            fulfill the purposes outlined in this privacy notice unless
            otherwise required by law.
          </p>

          <p className="p-2 fw-light">
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting, or other legal requirements). No purpose in this
            notice will require us keeping your personal information for longer
            than the period of time in which users have an account with us.
          </p>

          <p className="p-2 fw-light">
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize such
            information, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.
          </p>

          <h1 className="p-3">8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h1>
          <p className="p-2 fw-light fst-italic">
            In Short: We aim to protect your personal information through a
            system of organizational and technical security measures.
          </p>

          <p className="p-2 fw-light">
            We have implemented appropriate and reasonable technical and
            organizational security measures designed to protect the security of
            any personal information we process. However, despite our safeguards
            and efforts to secure your information, no electronic transmission
            over the Internet or information storage technology can be
            guaranteed to be 100% secure, so we cannot promise or guarantee that
            hackers, cybercriminals, or other unauthorized third parties will
            not be able to defeat our security and improperly collect, access,
            steal, or modify your information. Although we will do our best to
            protect your personal information, transmission of personal
            information to and from our Services is at your own risk. You should
            only access the Services within a secure environment.
          </p>
          <h1 className="p-3">9. DO WE COLLECT INFORMATION FROM MINORS?</h1>
          <p className="p-2 fw-light fst-italic">
            In Short: We do not knowingly collect data from or market to
            children under 18 years of age.
          </p>

          <p className="p-2 fw-light">
            We do not knowingly solicit data from or market to children under 18
            years of age. By using the Services, you represent that you are at
            least 18 or that you are the parent or guardian of such a minor and
            consent to such minor dependent’s use of the Services. If we learn
            that personal information from users less than 18 years of age has
            been collected, we will deactivate the account and take reasonable
            measures to promptly delete such data from our records. If you
            become aware of any data we may have collected from children under
            age 18, please contact us at cannapushinfo@gmail.com.
          </p>

          <h1 className="p-3">10. WHAT ARE YOUR PRIVACY RIGHTS?</h1>
          <p className="p-2 fw-light fst-italic">
            In Short: In some regions, such as the European Economic Area (EEA),
            United Kingdom (UK), and Switzerland, you have rights that allow you
            greater access to and control over your personal information. You
            may review, change, or terminate your account at any time.
          </p>

          <p className="p-2 fw-light">
            In some regions (like the EEA, UK, and Switzerland), you have
            certain rights under applicable data protection laws. These may
            include the right (i) to request access and obtain a copy of your
            personal information, (ii) to request rectification or erasure;
            (iii) to restrict the processing of your personal information; (iv)
            if applicable, to data portability; and (v) not to be subject to
            automated decision-making. In certain circumstances, you may also
            have the right to object to the processing of your personal
            information. You can make such a request by contacting us by using
            the contact details provided in the section "HOW CAN YOU CONTACT US
            ABOUT THIS NOTICE?" below.
          </p>
          <p className="p-2 fw-light">
            We will consider and act upon any request in accordance with
            applicable data protection laws.
          </p>
          <p className="p-2 fw-light">
            If you are located in the EEA or UK and you believe we are
            unlawfully processing your personal information, you also have the
            right to complain to your
            <span className="blue">
              Member State data protection authority{" "}
            </span>
            or
            <span className="blue">UK data protection authority.</span>
          </p>
          <p className="p-2 fw-light">
            If you are located in Switzerland, you may contact the
            <span className="blue">
              Federal Data Protection and Information Commissioner.
            </span>
          </p>
          <p className="p-2 fw-light">
            <span className="text-reset">Withdrawing your consent: </span>
            If we are relying on your consent to process your personal
            information, you have the right to withdraw your consent at any
            time. You can withdraw your consent at any time by contacting us by
            using the contact details provided in the section "HOW CAN YOU
            CONTACT US ABOUT THIS NOTICE?" below.
          </p>

          <p className="p-2 fw-light">
            However, please note that this will not affect the lawfulness of the
            processing before its withdrawal nor will it affect the processing
            of your personal information conducted in reliance on lawful
            processing grounds other than consent.
          </p>

          <h3 className="p-2">Account Information</h3>

          <p className="p-2 fw-light">
            If you would at any time like to review or change the information in
            your account or terminate your account, you can:
          </p>

          <ul className="p-3">
            <li className="p-2 fw-light">
              Log in to your account settings and update your user account.
            </li>
          </ul>

          <p className="p-2 fw-light">
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our legal terms and/or comply with applicable legal
            requirements.
          </p>
          <p className="p-2 fw-light">
            If you have questions or comments about your privacy rights, you may
            email us at admin@canna-push.com.
          </p>

          <h1 className="p-3">11. CONTROLS FOR DO-NOT-TRACK FEATURES</h1>

          <p className="p-2 fw-light">
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ("DNT") feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage no uniform technology standard for recognizing and
            implementing DNT signals has been finalized. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this privacy notice.
          </p>

          <h1 className="p-3">12. DO WE MAKE UPDATES TO THIS NOTICE?</h1>
          <p className="p-2 fw-light fst-italic">
            In Short: Yes, we will update this notice as necessary to stay
            compliant with relevant laws.
          </p>

          <p className="p-2 fw-light">
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated "Revised" date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy notice, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.
          </p>

          <h1 className="p-3">13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h1>
          <p className="p-2 fw-light">
            If you have questions or comments about this notice, you may email
            us at or contact us by post at: admin@canna-push.com
          </p>
          <p className="p-2 fw-light">
            "BBShark" company (BBShark S.L.), Barcelona, Port Vell
          </p>

          <h1 className="p-3">
            14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </h1>

          <p className="p-2 fw-light">
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it. To request to review, update,
            or delete your personal information, please fill out and submit a
            data subject access request.
          </p>
        </div>
      </div>
    </UnauthorizedLayout>
  );
}
export default PrivacyPolicyPage;
