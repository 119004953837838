import { ReactNode, useCallback, useState } from "react";
import ErrorText from "../../forms/ErrorText";
import styles from "./AppInput.module.css";
import { useFormikContext } from "formik";
import { IconButton } from "@mui/material";
import {
  RemoveRedEye as HidePasswordIcon,
  VisibilityOff as ShowPasswordIcon,
} from "@mui/icons-material";
import cn from "classnames";

type AppInputProps = {
  name: string;
  placeholder: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  isForm?: boolean;
  type?: React.HTMLInputTypeAttribute;
  classNameContainer?: string;
  classNameInput?: string;
  customIcon?: ReactNode;
  iconColor?: string;
  errorColor?: string;
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  isErrorHidden?: boolean;
  startIcon?: ReactNode;
  value?: string;
  noCheck?: boolean;
  isTextArea?: boolean;
  maxLength?: number;
  textAreaClassname?: string;
  disabled?: boolean;
};

function AppInput(props: AppInputProps) {
  const {
    name,
    placeholder,
    onChange,
    isForm,
    type,
    classNameContainer,
    classNameInput,
    customIcon,
    iconColor,
    errorColor,
    containerStyle,
    style,
    isErrorHidden,
    startIcon,
    value,
    noCheck,
    isTextArea,
    maxLength,
    textAreaClassname,
    disabled,
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const inputType = type === "password" && showPassword ? "text" : type;

  const formikHook = useFormikContext();
  let values: any, setFieldValue: any, errors: any, touched: any;

  if (isForm) {
    values = formikHook.values;
    setFieldValue = formikHook.setFieldValue;
    errors = formikHook.errors;
    touched = formikHook.touched;
  }

  let fValue: any, fError: any, fVisible: any;

  if (isForm) {
    fValue = values[name];
    fError = errors[name];
    fVisible = touched[name];
  }

  const onChangeFormik = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const text = e.target.value || "";
      setFieldValue(name, text);
    },
    [name, setFieldValue]
  );

  return (
    <>
      {isTextArea ? (
        <textarea
          value={isForm ? fValue : value}
          style={{ ...style }}
          name={name}
          autoComplete="off"
          key={name}
          disabled={disabled}
          placeholder={placeholder}
          // @ts-ignore
          onChange={isForm ? onChangeFormik : onChange}
          cols={40}
          rows={5}
          maxLength={maxLength}
          className={cn(textAreaClassname, styles["text-area-style"])}
        />
      ) : (
        <div
          className={`${styles["input-container-default"]} ${
            classNameContainer || ""
          }`}
          style={containerStyle}
        >
          {startIcon && (
            <div className={styles["start-icon-container"]}>{startIcon}</div>
          )}
          <input
            value={isForm ? fValue : value}
            name={name}
            disabled={disabled}
            autoComplete="off"
            key={name}
            onChange={isForm ? onChangeFormik : onChange}
            type={inputType}
            className={`${styles["input-default"]} ${classNameInput || ""}`}
            id={name}
            placeholder={placeholder}
            style={{ ...style }}
          />
          <div className={styles["icon-container"]}>
            {type === "password" && (
              <IconButton onClick={handleClickShowPassword}>
                {showPassword ? (
                  <ShowPasswordIcon sx={{ color: iconColor }} />
                ) : (
                  <HidePasswordIcon sx={{ color: iconColor }} />
                )}
              </IconButton>
            )}
            {customIcon && customIcon}
          </div>
        </div>
      )}
      {!isErrorHidden && !noCheck && (
        <ErrorText error={fError} visible={fVisible} errorColor={errorColor} />
      )}
    </>
  );
}
export default AppInput;
