import React, { useState } from "react";
import styles from "./CreateUserForm.module.css";
import AppForm from "../../../forms/AppForm";
import { FormikValues } from "formik";
import { useSchemas } from "../../../helpers/schemas";
import Icon from "../../../assets/icons/svgs/Icon";
import AppInput from "../../../components/AppInput/AppInput";
import AppButton from "../../../components/AppButton/AppButton";
import { useNav } from "../../../hooks/useNav";
import AppSelect from "../../../components/AppSelect/AppSelect";
import {
  IUser,
  UserRole,
  createUser,
  updateUser,
} from "../../../slices/usersSlice";
import { useDispatch, useSelector } from "react-redux";
import AppModal from "../../../components/AppModal/AppModal";
import ErrorIcon from "@mui/icons-material/Error";
import SuccessIcon from "@mui/icons-material/CheckCircle";
import { AppColors } from "../../../AppTheme";
import AppText from "../../../components/AppText/AppText";
import { RootState } from "../../../store/rootReducer";
import { isLoading } from "../../../utils";
import { LoadingSubject } from "../../../slices/loadingSlice";
import { Grid } from "@mui/material";
import { Column, Row } from "components/blocks";
import { useRoles } from "hooks/useRoles";
import { useParams } from "react-router-dom";
import { useLoadings } from "hooks/useLoadings";
import toast from "react-hot-toast";

type Props = {
  showClubs: (value: boolean) => void;
  clubIds: string[];
  selectedUserId?: string;
  clearSelectedUserId?: () => void;
};

const s = (className: string) => styles[className];

function CreateUserForm(props: Props) {
  const { showClubs, clubIds, selectedUserId, clearSelectedUserId } = props;
  const dispatch = useDispatch();
  const { createUserSchema } = useSchemas();
  const { viewGoBack, viewWelcome, viewCreateUser } = useNav();

  const [isModal, setModal] = useState<boolean>(false);
  const openModal = () => setModal(true);
  const closeModal = () => setModal(false);

  const [password, setPassword] = useState<string>("");

  const subjects = useSelector((s: RootState) => s.loading.subjects);

  const { isCreateUserLoading, isUpdateUserLoading } = useLoadings();

  const { list } = useSelector((s: RootState) => s.users);

  const userItem = list.find((lu) => lu.id === selectedUserId);

  const onSubmit = (values: FormikValues) => {
    if (
      values.role !== UserRole.Moderator &&
      values.role !== UserRole.SuperAdmin &&
      !clubIds.length
    ) {
      toast.error(`This role requires at least one club`);
      return;
    }

    const bodyParams = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      role: values.role,
    };

    if (userItem) {
      // @ts-ignore
      dispatch(updateUser(userItem.id, bodyParams, clearSelectedUserId));
    } else {
      const onSuccess = (password: string) => {
        setPassword(password);
        openModal();
      };
      const onError = () => {
        setPassword("");
        openModal();
      };
      dispatch(
        // @ts-ignore
        createUser({ ...bodyParams, club_ids: clubIds }, onSuccess, onError)
      );
    }
  };

  const urlParams = useParams();
  let initialRole = urlParams.role || "";

  const roles = useRoles();

  const rolesList = [
    {
      title: "Club owner",
      value: UserRole.ClubOwner,
      active: roles.SuperAdmin || roles.Moderator,
    },
    {
      title: "Manager / Admin",
      value: UserRole.ManagerAdmin,
      active: roles.SuperAdmin || roles.Moderator || roles.ClubOwner,
    },
    {
      title: "Moderator",
      value: UserRole.Moderator,
      active: roles.SuperAdmin && !userItem,
    },
    {
      title: "Staff",
      value: UserRole.Staff,
      active:
        roles.SuperAdmin ||
        roles.Moderator ||
        roles.ClubOwner ||
        roles.ManagerAdmin,
    },
  ].filter((r) => r.active);

  const isUrlParamMatchRole = rolesList.find((r) => r.value === initialRole);

  initialRole = isUrlParamMatchRole ? initialRole : "";

  const initialValues = {
    first_name: userItem?.first_name || "",
    last_name: userItem?.last_name || "",
    email: userItem?.email || "",
    role: userItem?.role || initialRole,
  };

  const commonAppInputProps = {
    classNameContainer: s("input-container"),
    classNameInput: s("input-styles"),
    isForm: true,
  };

  let placeholderText = "Role";

  if (userItem) {
    if (userItem?.role === UserRole.SuperAdmin) {
      placeholderText = "Superadmin";
    } else if (userItem?.role === UserRole.Moderator) {
      placeholderText = "Moderator";
    }
  }

  const rightFieldsBlock = (
    <Column>
      <AppInput
        disabled={!!userItem}
        name="email"
        placeholder="Email"
        type="email"
        startIcon={<Icon name="Email" />}
        {...commonAppInputProps}
        classNameInput={userItem ? s("color-gray") : s("input-styles")}
      />
      <AppSelect
        name="role"
        disabled={
          userItem?.role === UserRole.SuperAdmin ||
          userItem?.role === UserRole.Moderator ||
          urlParams?.role === UserRole.ClubOwner ||
          urlParams?.role === UserRole.ManagerAdmin ||
          urlParams?.role === UserRole.Moderator ||
          urlParams?.role === UserRole.Staff
        }
        placeholder={placeholderText}
        data={rolesList}
        onChangeCallback={(role) => {
          const isModerator = role === UserRole.Moderator;
          showClubs(!isModerator);
        }}
        classNameContainer={s("input-container")}
        classNameSelect={
          userItem &&
          (userItem.role === UserRole.SuperAdmin ||
            userItem.role === UserRole.Moderator)
            ? s("color-gray")
            : s("input-styles")
        }
        isForm
      />
    </Column>
  );

  const leftFieldsBlock = (
    <Column>
      <AppInput
        name="first_name"
        placeholder="First name"
        {...commonAppInputProps}
      />
      <AppInput
        name="last_name"
        placeholder="Last name"
        {...commonAppInputProps}
      />
    </Column>
  );

  const buttonsBlock = (
    <Row sx={{ gap: 4, mt: 5 }} style={{ justifyContent: "end" }}>
      <AppButton
        fullWidth
        loading={isCreateUserLoading || isUpdateUserLoading}
        sx={{ maxWidth: 200 }}
        text={"Save"}
        isForm
      />
      <AppButton
        fullWidth
        disabled={isCreateUserLoading}
        sx={{ maxWidth: 200 }}
        variant="outlined"
        color="error"
        text={"Cancel"}
        onClick={() => {
          if (userItem) {
            clearSelectedUserId && clearSelectedUserId();
          } else {
            viewGoBack();
          }
        }}
      />
    </Row>
  );

  const renderModal = () => {
    return (
      <AppModal
        childrenContainerClassname={s("modal-container")}
        open={isModal}
        onClose={() => {
          closeModal();
          password && viewWelcome();
        }}
      >
        {password ? (
          <SuccessIcon sx={{ color: AppColors.green, width: 80, height: 80 }} />
        ) : (
          <ErrorIcon sx={{ color: AppColors.red, width: 80, height: 80 }} />
        )}
        <AppText variant="body1" sx={{ mt: 2 }}>
          {password
            ? "User created successfully!"
            : "An error occurred, please try again later"}
        </AppText>
        {password && (
          <AppText variant="body1">{`Password: "${password}"`}</AppText>
        )}
      </AppModal>
    );
  };

  return (
    <>
      <AppForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        children={
          <Grid container columnSpacing={8} sx={{ py: 8 }}>
            <Grid item xs={12} md={6}>
              {leftFieldsBlock}
            </Grid>
            <Grid item xs={12} md={6}>
              {rightFieldsBlock}
            </Grid>
            <Grid item xs={12}>
              {buttonsBlock}
            </Grid>
          </Grid>
        }
        validationSchema={createUserSchema}
        enableReinitialize={true}
      />
      {renderModal()}
    </>
  );
}
export default CreateUserForm;
