import React from "react";
import styles from "./AppModal.module.css";
import { Modal } from "@mui/material";
import { IconButton } from "@mui/material";
import Icon from "../../assets/icons/svgs/Icon";

type AppModalProps = {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  childrenContainerClassname?: string;
  width?: string;
  leftControl?: React.ReactNode;
  centerControl?: React.ReactNode;
  rightControl?: React.ReactNode;
  backgroundColor?: string;
};

function AppModal(props: AppModalProps) {
  const {
    open,
    onClose,
    children,
    childrenContainerClassname,
    width,
    leftControl,
    centerControl,
    rightControl,
    backgroundColor,
  } = props;

  return (
    <Modal open={open} onClose={onClose}>
      <div
        className={styles["modal-container"]}
        style={{ maxWidth: width || "900px", backgroundColor }}
      >
        <div className={styles["top-container"]}>
          {leftControl && <div>{leftControl}</div>}
          {centerControl && <div>{centerControl}</div>}

          <div className={styles["right-control"]}>
            {rightControl ? (
              rightControl
            ) : (
              <IconButton onClick={onClose}>
                <Icon
                  className={styles["close-button"]}
                  color="red"
                  name="CloseFill"
                />
              </IconButton>
            )}
          </div>
        </div>
        <div className={childrenContainerClassname}>{children}</div>
      </div>
    </Modal>
  );
}
export default AppModal;
