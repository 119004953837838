import NewTable from "../../../../components/NewTable/NewTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/rootReducer";
import ModalWrapper from "components/modals/ModalWrapper";
import { IClubMember } from "slices/clubSlice";
import { useState } from "react";
import styles from "./MembersModalTable.module.css";
import AppText from "components/AppText/AppText";
import AppButton from "components/AppButton/AppButton";
import { makeTransaction } from "slices/depositSlice";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";

type Props = {
  selectedMember: IClubMember;
  setSelectedMember: React.Dispatch<
    React.SetStateAction<IClubMember | undefined>
  >;
  open: boolean;
  onClose: () => void;
  membersList: IClubMember[];
};

export default function MembersModalTable(props: Props) {
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const { selectedMember, setSelectedMember, open, onClose, membersList } =
    props;

  const dispatch = useDispatch();

  const onCloseAction = () => {
    onClose();
    setSelectedMember(undefined);
  };

  const params = useParams();

  const { token } = useSelector((s: RootState) => s.auth);
  const [newBalance, setNewBalance] = useState<string>("");

  let headCells = [
    t("text.member"),
    t("text.balance"),
    t("text.birth-date"),
    t("text.email"),
    t("text.city"),
  ];
  let rowsData: string[][] = [];

  const subjects = useSelector((s: RootState) => s.loading.subjects);

  // const isLoadingCancelOrder = isLoading(LoadingSubject.CancelOrder, subjects);

  membersList?.map((member) =>
    rowsData.push([
      "id_" + member.id,
      member.full_name,
      member.balance.toString(),
      member.birthdate,
      member.email,
      member.city,
    ])
  );

  const renderEditMember = () => {
    return (
      <div className={styles["edit-member-container"]}>
        <div className={styles["left-container"]}>
          <div className={styles["polaroid-container"]}>
            <img
              className={styles["member-photo"]}
              src={selectedMember?.photo_url + "&token=" + token}
              alt={t("text.member")}
            />
          </div>
        </div>
        <div className={styles["right-container"]}>
          <AppText sx={{ mt: 10, mb: 3 }} variant="h2">
            {selectedMember?.full_name}
          </AppText>

          <div style={{}}>
            <AppText sx={{ mt: 2 }} variant="body3">
              <AppText>{`${t("text.amount")}: `}</AppText>
              <AppText color="green">{`€ ${selectedMember?.balance} + `}</AppText>
              <AppText>{`€`}</AppText>
            </AppText>
            <input
              value={newBalance}
              onChange={(e) => {
                const value = e.target.value;
                const re = /^-?\d*$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  setNewBalance(value);
                }
              }}
              style={{ outline: "none", marginLeft: 10 }}
            />
          </div>

          <AppText sx={{ mt: 2 }} variant="body3">
            {`${t("text.birth-date")}: ${selectedMember?.birthdate}`}
          </AppText>
          <AppText sx={{ mt: 2 }} variant="body3">
            {`${t("text.email")}: ${selectedMember?.email}`}
          </AppText>
          <AppText sx={{ mt: 2 }} variant="body3">
            {`${t("text.city")}: ${selectedMember?.city}`}
          </AppText>
        </div>
        <div
          style={{
            display: "flex",
            marginTop: 50,
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <AppButton
            onClick={() => {
              const onSuccess = () => {
                setSelectedMember(undefined);
                setNewBalance("");
              };
              dispatch(
                // @ts-ignore
                makeTransaction(
                  {
                    amount: newBalance,
                    club_id: params.id!,
                    customer_id: selectedMember?.id!,
                  },
                  onSuccess
                )
              );
            }}
            disabled={!newBalance}
            sx={{ width: 200 }}
            text={t("general.apply")}
          />
          <AppButton
            onClick={() => {
              setSelectedMember(undefined);
              setNewBalance("");
            }}
            sx={{ width: 200 }}
            variant="outlined"
            color="error"
            text={t("general.cancel")}
          />
        </div>
      </div>
    );
  };

  const renderTable = () => {
    return (
      <NewTable
        headCells={headCells}
        rows={rowsData}
        actionButton={{
          onClick: (id: string) =>
            setSelectedMember(membersList.find((m) => m.id === id)),
          title: t("general.edit"),
        }}
      />
    );
  };

  return (
    <ModalWrapper
      open={open}
      onClose={onCloseAction}
      maxWidth={selectedMember && "800px"}
    >
      {selectedMember ? renderEditMember() : renderTable()}
    </ModalWrapper>
  );
}
