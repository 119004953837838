import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    body3: React.CSSProperties;
    body4: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
  }
  interface Palette {
    white1: Palette["primary"];
    gray2: Palette["primary"];
    gray3: Palette["primary"];
  }
  interface PaletteOptions {
    white1?: PaletteOptions["primary"];
    gray2?: PaletteOptions["primary"];
    gray3?: PaletteOptions["primary"];
  }
}
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
  }
}
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    white1: true;
    gray2: true;
    gray3: true;
  }
}

export const AppColors = {
  orange: "var(--orange)",
  blue: "var(--blue)",
  green: "var(--green)",
  darkCyan: "var(--darkCyan)",
  red: "var(--red)",
  purple: "var(--purple)",

  gray1: "var(--gray1)",
  gray2: "var(--gray2)",
  gray3: "var(--gray3)",

  white: "var(--white)",
  black: "var(--black)",
};

export const getCssVarValue = (cssVar: string) => {
  const bodyStyles = window.getComputedStyle(document.body);
  const colorInHEX = bodyStyles.getPropertyValue(cssVar.split(/[()]/)[1]);

  return colorInHEX;
};

const spacing = [
  0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 48, 64, 80, 100, 120, 140, 160,
];

const breakpoints = {
  values: {
    xs: 0,
    sm: 540,
    md: 768,
    lg: 1200,
    xl: 1440,
  },
};

export const GREY = {
  50: "#F0F0F0",
  100: "#F0F0F0",
  200: "#D9D9D9",
  300: "#D9D9D9",
  400: "#AFAFAF",
  500: "#AFAFAF",
  600: "#AFAFAF",
  700: "#3F4B57",
  800: "#7B7B7B",
  900: "#7B7B7B",
};

export let AppTheme = createTheme({
  spacing: spacing,
  breakpoints: breakpoints,
  typography: {
    h1: {
      fontFamily: "Malgun-Bold",
      fontSize: 48,
    },
    h2: {
      fontFamily: "Malgun-Bold",
      fontSize: 40,
    },
    h3: {
      fontFamily: "Malgun-Bold",
      fontSize: 24,
    },
    h4: {
      fontFamily: "Malgun-Bold",
      fontSize: 20,
    },
    body1: {
      fontFamily: "Malgun-Regular",
      fontSize: 18,
    },
    body2: {
      fontFamily: "Malgun-Bold",
      fontSize: 16,
    },
    body3: {
      fontFamily: "Malgun-Regular",
      fontSize: 14,
    },
    body4: {
      fontFamily: "Malgun-Bold",
      fontSize: 12,
    },
  },
  palette: {
    common: { black: "#1E1E1E", white: "#F0F0F0" },
    primary: {
      main: getCssVarValue(AppColors.green),
    },
    secondary: {
      main: getCssVarValue(AppColors.white),
    },
    error: {
      main: getCssVarValue(AppColors.red),
    },
    white1: {
      main: getCssVarValue(AppColors.white),
    },
    gray2: {
      main: getCssVarValue(AppColors.gray2),
    },
    gray3: {
      main: getCssVarValue(AppColors.gray3),
    },
    action: {
      disabled: getCssVarValue(AppColors.gray2),
    },
    grey: { ...GREY },
  },
});
