import { AppColors } from "../../../../AppTheme";
import AppForm from "../../../../forms/AppForm";
import AppInput from "../../../../components/AppInput/AppInput";
import AppButton from "../../../../components/AppButton/AppButton";
import { useSchemas } from "../../../../helpers/schemas";
import { useIntl } from "react-intl";
import { FormikValues } from "formik";
import styles from "./Tariffs.module.css";
import { Row } from "components/blocks";
import { ITariff, createTariff, updateTariff } from "slices/tariffsSlice";
import AppText from "components/AppText/AppText";
import { useLoadings } from "hooks/useLoadings";
import { useDispatch } from "react-redux";

type Props = {
  tariffItem?: ITariff | undefined;
  onClose: () => void;
};

function TariffForm(props: Props) {
  const { onClose, tariffItem } = props;
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const { tariffFormSchema } = useSchemas();
  const dispath = useDispatch();

  const onSubmit = (values: FormikValues) => {
    console.log("submitted", values);

    if (tariffItem) {
      // @ts-ignore
      dispath(updateTariff(tariffItem.id, values, onClose));
    } else {
      // @ts-ignore
      dispath(createTariff(values, onClose));
    }
  };

  const initialValues = {
    name: tariffItem?.name || undefined,
    duration: tariffItem?.duration || undefined,
    global_news_amount: tariffItem?.global_news_amount || undefined,
    local_news_amount: tariffItem?.local_news_amount || undefined,
    price: tariffItem?.price || undefined,
  };

  const { isCreateTariffLoading, isUpdateTariffLoading } = useLoadings();

  return (
    <AppForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      children={
        <>
          <AppText sx={{ mt: 2, mb: -1 }}>{"Name:"}</AppText>
          <AppInput
            errorColor={AppColors.red}
            classNameContainer={styles["input-container"]}
            classNameInput={styles["name-input-style"]}
            isForm
            name={"name"}
            placeholder={"Name"}
          />
          <AppText sx={{ mt: 2, mb: -1 }}>{"Duration:"}</AppText>
          <AppInput
            errorColor={AppColors.red}
            classNameContainer={styles["input-container"]}
            classNameInput={styles["input-styles"]}
            isForm
            name={"duration"}
            placeholder={"Duration"}
            type="number"
          />
          <AppText sx={{ mt: 2, mb: -1 }}>{"Global news amount:"}</AppText>
          <AppInput
            errorColor={AppColors.red}
            classNameContainer={styles["input-container"]}
            classNameInput={styles["input-styles"]}
            isForm
            name={"global_news_amount"}
            placeholder={"Global news amount"}
            type="number"
          />
          <AppText sx={{ mt: 2, mb: -1 }}>{"Local news amount:"}</AppText>
          <AppInput
            errorColor={AppColors.red}
            classNameContainer={styles["input-container"]}
            classNameInput={styles["input-styles"]}
            isForm
            name={"local_news_amount"}
            placeholder={"Local news amount"}
            type="number"
          />
          <AppText sx={{ mt: 2, mb: -1 }}>{"Price:"}</AppText>
          <AppInput
            errorColor={AppColors.red}
            classNameContainer={styles["input-container"]}
            classNameInput={styles["input-styles"]}
            isForm
            name={"price"}
            placeholder={"Price"}
            type="number"
          />
          <Row sx={{ mt: 8 }}>
            <AppButton
              loading={isCreateTariffLoading || isUpdateTariffLoading}
              sx={{ width: "40%" }}
              type="submit"
              text={t("general.save")}
              isForm
            />
            <AppButton
              sx={{ width: "40%" }}
              variant="outlined"
              color="error"
              text={t("general.cancel")}
              onClick={onClose}
            />
          </Row>
        </>
      }
      validationSchema={tariffFormSchema}
      enableReinitialize={true}
    />
  );
}
export default TariffForm;
