import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styled from "@emotion/styled";
import { AppColors } from "../../AppTheme";
import styles from "./NewTable.module.css";
import { CircularProgress } from "@mui/material";
import AppText from "../AppText/AppText";
import AppButton from "../AppButton/AppButton";
import { useIntl } from "react-intl";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: AppColors.green,
    fontSize: 13,
    fontFamily: "Malgun-Bold",
    color: AppColors.white,
    whiteSpace: "nowrap",
  },
}));

type NewTableProps = {
  headCells: string[];
  rows: string[][];
  actionButton?: {
    onClick: (id: string) => void;
    title: string;
  };
  loading?: boolean;
};

export default function NewTable(props: NewTableProps) {
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const { headCells, rows, actionButton, loading } = props;

  const trimString = (limit: number, value: string) => {
    if (value.length > limit) {
      return value.slice(0, limit) + " ...";
    } else {
      return value;
    }
  };

  return (
    <>
      <TableContainer className={styles["table-container"]}>
        <Table>
          <TableHead>
            {headCells.map((headCell) => (
              <StyledTableCell sx={{ paddingLeft: 10 }}>
                {headCell}
              </StyledTableCell>
            ))}
            {actionButton && <StyledTableCell>{""}</StyledTableCell>}
          </TableHead>

          <TableBody>
            {rows.map((row, index) => {
              return (
                <TableRow hover>
                  {row.map((cell) => {
                    if (cell.startsWith("id_")) {
                      return "";
                    }
                    return (
                      <TableCell
                        sx={{ paddingLeft: 10, whiteSpace: "nowrap" }}
                        className={styles["table-body-text"]}
                      >
                        {trimString(12, cell)}
                      </TableCell>
                    );
                  })}
                  {actionButton && (
                    <TableCell
                      className={styles["table-body-text"]}
                      sx={{
                        width: 40,
                        height: 40,
                        position: "sticky",
                        right: 0,
                        zIndex: 1,
                        backgroundColor: (t) => t.palette.common.white,
                      }}
                    >
                      <AppButton
                        onClick={() => {
                          const id_ = row.find((i) => i.startsWith("id_"));
                          const newId = id_?.replace("id_", "");
                          actionButton.onClick(newId!);
                        }}
                        text={actionButton.title}
                      />
                    </TableCell>
                  )}
                </TableRow>
              );
            })}

            {loading && (
              <CircularProgress
                sx={{ position: "absolute", top: "50%", left: "45%" }}
              />
            )}
            {!rows.length && (
              <TableRow>
                <TableCell colSpan={2}>
                  <AppText
                    sx={{ pt: 1, pb: 1, paddingLeft: 7 }}
                    variant="body2"
                    color="gray3"
                  >
                    {t("text.no-data-found") + "..."}
                  </AppText>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* IN TERMS TO ADD PAGINATION USE THIS!!! */}
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={10}
        page={1}
        onPageChange={() => {}}
        onRowsPerPageChange={() => {}}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          bgcolor: (t) => t.palette.primary.main,
          color: (t) => t.palette.common.white,
        }}
      /> */}
    </>
  );
}
