import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppText from "../AppText/AppText";
import { AppColors } from "../../AppTheme";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ marginTop: 30 }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <AppText>{children}</AppText>
        </div>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export type AppTabItemType = {
  name: string;
  children?: React.ReactNode;
  disabled?: boolean;
  hidden?: boolean;
};

type AppTabsProps = {
  tabStep: number;
  setTabStep: React.Dispatch<React.SetStateAction<number>>;
  tabsItemArr: AppTabItemType[];
  onTabChange?: () => void;
};

export default function AppTabs(props: AppTabsProps) {
  const { tabsItemArr, tabStep, setTabStep, onTabChange } = props;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabStep(newValue);
    onTabChange && onTabChange();
  };

  return (
    <div>
      <Tabs
        value={tabStep}
        onChange={handleChange}
        aria-label="basic-tabs"
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          "& button": {
            color: AppColors.white,
            textTransform: "none",
          },
        }}
      >
        {tabsItemArr
          .filter((t) => !t.hidden)
          .map((tab, index) => {
            return (
              <Tab
                sx={{ fontFamily: "Malgun-Bold", fontSize: 15 }}
                key={tab.name + index}
                disabled={tab.disabled}
                label={tab.name}
                {...a11yProps(index)}
              />
            );
          })}
      </Tabs>

      {tabsItemArr.map((tab, index) => {
        return (
          <CustomTabPanel key={tab.name + index} value={tabStep} index={index}>
            {tab.children}
          </CustomTabPanel>
        );
      })}
    </div>
  );
}
