import React from "react";
import { store, persistor } from "./store/store";
import { PersistGate } from "redux-persist/es/integration/react";
import { Provider as ReduxProvider } from "react-redux";
import { ThemeProvider } from "@mui/material";
import TranslationsProvider from "./translations/TranslationProvider";
import AppNavigation from "./navigation/navigation";
import { BrowserRouter } from "react-router-dom";
import theme from "theme";
import Quill from "quill";
import toast, { Toaster } from "react-hot-toast";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const Link = Quill.import("formats/link");
Link.sanitize = function (url: any) {
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    return `http://${url}`;
  }
  return url;
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ReduxProvider store={store}>
        <PersistGate persistor={persistor}>
          <TranslationsProvider>
            <BrowserRouter>
              <AppNavigation />
              <Toaster
                toastOptions={{
                  style: { fontFamily: "Malgun-Regular" },
                }}
                position="top-center"
              />
            </BrowserRouter>
          </TranslationsProvider>
        </PersistGate>
      </ReduxProvider>
    </ThemeProvider>
  );
}

export default App;
