import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../store/store";
import { baseUrl } from "../constants";
import { loadingEnd, loadingStart, LoadingSubject } from "./loadingSlice";

export interface IApplication {
  id: string;
  customer: {
    id: string;
    full_name: string;
    email: string;
    photo_url: string;
    document_photo_url: string;
    document_number: string;
    document_country: string;
    city: string;
    birthdate: string;
  };
  status: string;
}

export interface IApplicationState {
  list: IApplication[];
}

const initialState: IApplicationState = {
  list: [],
};

const application = createSlice({
  name: "application",
  initialState,
  reducers: {
    _getClubApplication: (
      state,
      { payload }: PayloadAction<IApplication[]>
    ) => {
      return { ...state, list: payload };
    },
  },
});

export const getClubApplication =
  (clubId: string): AppThunk => async (dispatch, getState) => {
    try {
      dispatch(loadingStart(LoadingSubject.GetClubApplication));
      const token = getState().auth.token;
      const response = await fetch(
        `${baseUrl}/api/club/${clubId}/application`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const jsonData = await response.json();

      if (response.status === 200) {
        dispatch(_getClubApplication(jsonData));
      } else {
      }
    } catch (e) {
    } finally {
      dispatch(loadingEnd(LoadingSubject.GetClubApplication));
    }
  };

export const acceptApplication = (
  applicationId: string,
  clubId: string,
  onSuccess?: () => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.AcceptApplication));
    const token = getState().auth.token;
    const response = await fetch(
      `${baseUrl}/api/application/${applicationId}/accept`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    const jsonData = await response.json();

    if (response.status === 200) {
      dispatch(getClubApplication(clubId));
      onSuccess && onSuccess();
    } else {
    }
  } catch (e) {
  } finally {
    dispatch(loadingEnd(LoadingSubject.AcceptApplication));
  }
};

export const declineApplication =
  (
    applicationId: string,
    clubId: string,
    onSuccess: () => void
  ): AppThunk => async (dispatch, getState) => {
    try {
      dispatch(loadingStart(LoadingSubject.DeclineApplication));
      const token = getState().auth.token;
      const response = await fetch(
        `${baseUrl}/api/application/${applicationId}/decline`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const jsonData = await response.json();

      if (response.status === 200) {
        dispatch(getClubApplication(clubId));
        onSuccess && onSuccess();
      } else {
      }
    } catch (e) {
    } finally {
      dispatch(loadingEnd(LoadingSubject.DeclineApplication));
    }
  };

export const { _getClubApplication } = application.actions;

export default application.reducer;
