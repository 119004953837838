import NewTable from "../../../../components/NewTable/NewTable";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/rootReducer";
import { getClubList, getEmployeeList } from "slices/clubSlice";
import "react-quill/dist/quill.snow.css";
import Select from "react-dropdown-select";
import { useRoles } from "hooks/useRoles";
import { useIntl } from "react-intl";
import { getClubStatistics } from "slices/statisticsSlice";
import moment from "moment";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { useState } from "react";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import styles from "./StatisticsTab.module.css";
import AppText from "components/AppText/AppText";
import { Column, Row } from "components/blocks";
import { useLoadings } from "hooks/useLoadings";
import { Divider } from "@mui/material";

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

enum EnumPeriodTypes {
  Day = 1,
  Yesterday,
  Week,
  Month,
}

type SelectOptionType = {
  id: string;
  name: string;
};

type periodTypesType = {
  name: string;
  value: EnumPeriodTypes;
  period: { start: Date; end: Date };
};

const periodTypes: periodTypesType[] = [
  {
    name: "Today",
    value: EnumPeriodTypes.Day,
    period: {
      start: moment().startOf("day").toDate(),
      end: moment().endOf("day").toDate(),
    },
  },
  {
    name: "Yesterday",
    value: EnumPeriodTypes.Yesterday,
    period: {
      start: moment().subtract(1, "day").startOf("day").toDate(),
      end: moment().endOf("day").toDate(),
    },
  },
  {
    name: "Week",
    value: EnumPeriodTypes.Week,
    period: {
      start: moment().subtract(1, "week").startOf("week").toDate(),
      end: moment().endOf("day").toDate(),
    },
  },
  {
    name: "Month",
    value: EnumPeriodTypes.Month,
    period: {
      start: moment().subtract(1, "month").startOf("month").toDate(),
      end: moment().endOf("day").toDate(),
    },
  },
];

function StatisticsTab() {
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const roles = useRoles();
  const { me } = useSelector((s: RootState) => s.auth);

  const [selectedPeriodType, setPeriodType] = useState(EnumPeriodTypes.Month);

  const clubList = useSelector((s: RootState) => s.club.clubList);
  const statisticsList = useSelector((s: RootState) => s.statistics.list);

  const [selectedClub, setSelectedClub] = useState<
    SelectOptionType | undefined
  >();

  // @ts-ignore
  const [value, onChange] = useState<Value>([
    moment().startOf("month").toDate(),
    moment().endOf("month").toDate(),
  ]);

  useEffect(() => {
    if (roles.SuperAdmin || roles.Moderator) {
      // @ts-ignore
      dispatch(getClubList());
    } else {
      // @ts-ignore
      dispatch(getEmployeeList(me?.id, undefined, true));
    }
  }, []);

  useEffect(() => {
    if (clubList.length) {
      const item = clubList[0];
      setSelectedClub({ id: item.id, name: item.name });
    }
  }, [clubList]);

  useEffect(() => {
    if (selectedClub) {
      let startDate = undefined;
      let endDate = undefined;

      if (value) {
        startDate = moment((value as any)[0]).format("YYYY-MM-DD");
        endDate = moment((value as any)[1]).format("YYYY-MM-DD");
      }
      dispatch(
        // @ts-ignore
        getClubStatistics(selectedClub.id, startDate, endDate)
      );
    }
  }, [value, selectedClub]);

  const renderPeriodTile = (periodItem: periodTypesType) => {
    const isSelected = periodItem.value === selectedPeriodType;
    return (
      <div
        onClick={() => {
          setPeriodType(periodItem.value);
          onChange([periodItem.period.start, periodItem.period.end]);
        }}
        style={{
          width: "15%",
          padding: 5,
          borderBottom: `2px solid ${
            isSelected ? "var(--green)" : "var(--gray1)"
          }`,
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <AppText color={isSelected ? "green" : "gray1"}>
          {periodItem.name}
        </AppText>
      </div>
    );
  };

  let headCells = [
    t("Product"),
    t("Amount"),
    t("Price") + " (€)",
    t("Completed orders"),
  ];
  let statisticsData: string[][] = [];

  statisticsList?.map((stat) => {
    return statisticsData.push([
      stat.product_name,
      stat.total_amount.toString(),
      stat.total_price.toString(),
      stat.completed_orders_amount.toString(),
    ]);
  });

  const options = clubList.map((option) => ({
    id: option.id,
    name: option.name,
  }));

  return (
    <Column mt={-2}>
      <Column alignSelf={"flex-end"}>
        <Select
          searchable={false}
          placeholder={t("text.select-club") + "..."}
          values={selectedClub ? [selectedClub] : []}
          options={options}
          labelField="name"
          valueField="id"
          onChange={(val) => setSelectedClub(val[0])}
        />

        <DateRangePicker
          className={styles["date-range-picker"]}
          onChange={onChange}
          value={value}
          clearIcon={null}
        />
      </Column>

      <Row mt={5} mb={5}>
        {periodTypes.map((pt) => renderPeriodTile(pt))}
      </Row>

      <NewTable headCells={headCells} rows={statisticsData} />
    </Column>
  );
}
export default StatisticsTab;
