import React, { ReactNode } from "react";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import { LanguageCode, translationsForCode } from "./translations";
import { RootState } from "./../store/rootReducer";

type Props = {
  children: ReactNode;
};

const TranslationsProvider = ({ children }: Props) => {
  const { currentLanguage } = useSelector((s: RootState) => s.language);

  const languageCode = currentLanguage
    ? currentLanguage
    : LanguageCode.UKRAINIAN;

  return (
    <IntlProvider
      locale={languageCode}
      messages={translationsForCode(languageCode)}
    >
      {children}
    </IntlProvider>
  );
};

export default TranslationsProvider;
