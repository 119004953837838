import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../store/store";
import { baseUrl } from "../constants";
import { loadingEnd, loadingStart, LoadingSubject } from "./loadingSlice";
import qs from "qs";
import { IClub } from "./clubSlice";
import { getMe } from "./authSlice";

export enum UserRole {
  SuperAdmin = "super_admin",
  Moderator = "moderator",
  ClubOwner = "club_owner",
  ManagerAdmin = "manager_admin",
  Staff = "staff",
}

export interface IUser {
  id: string;
  role: string;
  email: string;
  first_name: string;
  last_name: string;
  clubs: IClub[];
}

export interface IUsersState {
  list: IUser[];
}

const initialState: IUsersState = {
  list: [],
};

const users = createSlice({
  name: "users",
  initialState,
  reducers: {
    _getUsersList: (state, { payload }: PayloadAction<IUser[]>) => {
      return { ...state, list: payload };
    },
  },
});

type CreateUserRequestType = {
  role: UserRole;
  club_ids: string[];
  email: string;
  first_name: string;
  last_name: string;
};
export const createUser = (
  data: CreateUserRequestType,
  onSuccess: (password: string) => void,
  onError: () => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.CreateUser));
    const { token } = getState().auth;
    const response = await fetch(`${baseUrl}/api/admin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    const jsonData = await response.json();
    if (response.status === 200) {
      onSuccess(jsonData.password);
    } else {
      onError();
    }
  } catch (e) {
    onError();
  } finally {
    dispatch(loadingEnd(LoadingSubject.CreateUser));
  }
};

type GetUsersListRequestType = {
  club_ids?: string[];
  role?: UserRole;
};
export const getUserList = (
  data?: GetUsersListRequestType,
  onSuccess?: () => void,
  onError?: () => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.GetUserList));
    const { token } = getState().auth;
    const params = data;
    const queryParams = params
      ? `?${qs.stringify(params, { arrayFormat: "repeat", encode: false })}`
      : "";
    const url = `${baseUrl}/api/admin${queryParams}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    const jsonData = await response.json();
    if (response.status === 200) {
      dispatch(_getUsersList(jsonData));
      onSuccess && onSuccess();
    } else {
      onError && onError();
    }
  } catch (e) {
    onError && onError();
  } finally {
    dispatch(loadingEnd(LoadingSubject.GetUserList));
  }
};

export const deleteUser = (
  id: string,
  onSuccess?: () => void,
  onError?: () => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.DeleteUser));
    const { token } = getState().auth;
    const response = await fetch(`${baseUrl}/api/admin/{id}?id=${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonData = await response.json();
    if (response.status === 200) {
      console.log('deleteUserJson', jsonData);
      onSuccess && onSuccess();
    } else {
      console.log('deleteUserNot200', jsonData);
      onError && onError();
    }
  } catch (e) {
    console.log('deleteUserErr', e);
    onError && onError();
  } finally {
    dispatch(loadingEnd(LoadingSubject.DeleteUser));
  }
};

type UpdateUserRequestType = {
  role: string;
  email: string;
  first_name: string;
  last_name: string;
};
export const updateUser = (
  id: string,
  data: UpdateUserRequestType,
  onSuccess?: () => void,
  onError?: () => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.UpdateUser));
    const { token } = getState().auth;
    const response = await fetch(`${baseUrl}/api/admin/{id}?id=${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    const jsonData = await response.json();
    if (response.status === 200) {
      console.log('updateUserJson', jsonData)
      if (data.role === UserRole.SuperAdmin) {
        dispatch(getMe())
      }
      onSuccess && onSuccess();
    } else {
      console.log('updateUserNot200', jsonData)
      onError && onError();
    }
  } catch (e) {
    console.log('updateUserErr', e)
    onError && onError();
  } finally {
    dispatch(loadingEnd(LoadingSubject.UpdateUser));
  }
};

export const { _getUsersList } = users.actions;

export default users.reducer;
