import { AppColors } from "../AppTheme";
import styles from "./ErrorText.module.css";

type Props = {
  error?: string;
  visible?: boolean;
  className?: string;
  errorColor?: string;
};

const ErrorText = ({ error, visible, className, errorColor }: Props) => {
  return (
    <div className={styles["input-error-container"]}>
      {!error || !visible ? (
        <></>
      ) : (
        <p
          style={{ color: errorColor || AppColors.red }}
          className={`${styles["error-text"]} ${className}`}
        >
          {error}
        </p>
      )}
    </div>
  );
};

export default ErrorText;
