import React, { Fragment } from "react";
import { Formik, FormikHelpers, FormikValues } from "formik";

type Props = {
  initialValues: any;
  values?: any;
  onSubmit: (
    values: FormikValues,
    helpers: FormikHelpers<FormikValues>
  ) => void;
  children: React.ReactNode;
  validationSchema: any;
  enableReinitialize?: boolean;
};

const AppForm: React.FC<Props> = ({
  initialValues,
  values,
  onSubmit,
  validationSchema,
  children,
  enableReinitialize = false,
}) => {
  return (
    <Formik
      enableReinitialize={enableReinitialize}
      initialValues={initialValues}
      values={values}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {() => <Fragment>{children}</Fragment>}
    </Formik>
  );
};

export default AppForm;
