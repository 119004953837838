import { ReactNode } from "react";
import { Box, Modal, SxProps, Theme } from "@mui/material";
import { IconButton } from "@mui/material";
import Icon from "../../assets/icons/svgs/Icon";
import styled from "@emotion/styled";
import { Column, MobileView, Row } from "components/blocks";
import AppText from "components/AppText/AppText";
import { Button, Checkbox } from "@mui/material";

type ModalWrapperProps = {
  open: boolean;
  onClose: () => void;
  children?: ReactNode;
  title?: string | ReactNode;
  maxWidth?: string;
  maxHeight?: string;
  actions?: any;
  headerSx?: SxProps;
  contentSx?: SxProps;
  footerSx?: SxProps;
  headerChildren?: ReactNode;
};

function ModalWrapper(props: ModalWrapperProps) {
  const {
    open,
    onClose,
    children,
    title,
    maxWidth = "70vw",
    maxHeight = "80vh",
    actions,
    headerSx,
    contentSx,
    footerSx,
    headerChildren,
  } = props;

  return (
    <Modal open={open} onClose={onClose}>
      <ModalPaper
        sx={{
          width: "100%",
          maxWidth: { xs: "100vw", md: maxWidth },
          maxHeight: { xs: "100vh", md: maxHeight },
          height: { xs: "100vh", md: "auto" },
          top: { xs: 0, md: "50%" },
          left: { xs: 0, md: "50%" },
          transform: { xs: "none", md: "translate(-50%, -50%)" },
          borderRadius: { xs: "none", md: "10px" },
          justifyContent: actions ? "space-between" : "start",
        }}
      >
        <ModalHeader sx={headerSx}>
          {title && (
            <AppText variant="h3" color="black">
              {title}
            </AppText>
          )}

          {headerChildren}

          <IconButton onClick={onClose} sx={{ p: 0 }}>
            <Icon color="red" name="CloseFill" />
          </IconButton>
        </ModalHeader>
        <ModalContent sx={contentSx}>{children}</ModalContent>
        {actions && <ModalFooter sx={footerSx}>{actions}</ModalFooter>}
        <MobileView height={60} />
      </ModalPaper>
    </Modal>
  );
}
export default ModalWrapper;

const ModalPaper = styled(Box)(({ theme }: { theme?: Theme }) => ({
  backgroundColor: theme?.palette.common.white,
  display: "flex",
  flexDirection: "column",
  outline: "none",
  position: "absolute" as "absolute",
  zIndex: 3000,
}));

const ModalHeader = styled(Row)(({ theme }: { theme?: Theme }) => ({
  position: "relative",
  padding: 20,
}));

const ModalContent = styled(Column)(({ theme }: { theme?: Theme }) => ({
  overflowY: "auto",
  paddingLeft: 20,
  paddingRight: 20,
  paddingBottom: 20,
}));

const ModalFooter = styled(Row)(({ theme }: { theme?: Theme }) => ({
  position: "relative",
  padding: 20,
}));
