import { Html5QrcodeScanner } from 'html5-qrcode';
import { useEffect, useRef } from 'react';

const scanRegionId = "html5qr-code-full-region";

export const Html5QrcodeScan = (props) => {
  const { qrCodeSuccessCallback, qrCodeErrorCallback } = props;
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current === null) {
      ref.current = new Html5QrcodeScanner(scanRegionId, { ...props });
    }
    const html5QrcodeScanner = ref.current;

    setTimeout(() => {
      const container = document.getElementById(scanRegionId);
      if (html5QrcodeScanner && container?.innerHTML == "") {
        html5QrcodeScanner.render(qrCodeSuccessCallback, qrCodeErrorCallback);
      }
    }, 0);

    return () => {
      if (html5QrcodeScanner) {
        html5QrcodeScanner.clear();
      }
    };
  }, []);

  return <div id={scanRegionId} />;
};