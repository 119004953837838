import React from "react";
import styles from "./DeleteModal.module.css";
import AppText from "../../AppText/AppText";
import AppButton from "../../AppButton/AppButton";
import ModalWrapper from "components/modals/ModalWrapper";
import { Row } from "components/blocks";

const s = (className: string) => styles[className];

type Props = {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  title?: string;
  desc?: string;
  loading?: boolean;
  modalTitle?: string;
};

function DeleteModal(props: Props) {
  const { open, title, desc, onClose, onDelete, loading, modalTitle } = props;

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      maxWidth="480px"
      contentSx={{ px: { xs: 4, md: 10 } }}
      footerSx={{ px: { xs: 4, md: 10 } }}
      title={modalTitle}
      actions={
        <Row fullWidth sx={{ gap: { xs: 4, md: 8 } }}>
          <AppButton
            fullWidth
            onClick={onDelete}
            color="error"
            text={"Delete"}
            loading={loading}
          />
          <AppButton
            fullWidth
            onClick={onClose}
            variant="outlined"
            text={"Cancel"}
          />
        </Row>
      }
    >
      <AppText variant="h2">{title}</AppText>
      <AppText sx={{ mt: 2 }}>{desc}</AppText>
    </ModalWrapper>
  );
}

export default DeleteModal;
