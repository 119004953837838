import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../store/store";
import { baseUrl } from "../constants";
import { loadingEnd, loadingStart, LoadingSubject } from "./loadingSlice";
import qs from "qs";
import { isObjectEmpty } from "utils";
import { getClubList } from "./clubSlice";

export interface IClubFeedback {
  id: string;
  club_id: string;
  customer_id: string;
  score: number;
  text: string;
  created_at: string;
  customer: {
    id: string;
    full_name: string;
    email: string;
    photo_url: string;
    document_photo_url: string;
    document_number: string;
    document_country: string;
    city: string;
    birthdate: string;
    balance: number;
  }
}

export interface IProductFeedback {
  customer_id: string;
  product_id: string;
  text: string;
  score: number;
  order_id: string;
  created_at: string;
  customer: {
    fullname: string;
    photo_url: string;
  }
}

export interface IFeedbackState {
  clubFeedbacksList: IClubFeedback[];
  productFeedbacksList: IProductFeedback[];
}

const initialState: IFeedbackState = {
  clubFeedbacksList: [],
  productFeedbacksList: []
};

const feedbacks = createSlice({
  name: "feedbacks",
  initialState,
  reducers: {
    _getClubFeedbacksList: (state, { payload }: PayloadAction<IClubFeedback[]>) => {
      return { ...state, clubFeedbacksList: payload };
    },
    _getProductFeedbacksList: (state, { payload }: PayloadAction<IProductFeedback[]>) => {
      return { ...state, productFeedbacksList: payload };
    },
  },
});

export const getClubFeedbacksList = (
  club_ids?: string[],
  customer_ids?: string[],
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.GetClubFeedbacksList));
    const { token } = getState().auth;
    const params = { club_ids, customer_ids };
    const queryParams = isObjectEmpty(params) ? '' : `?${qs.stringify(params, { arrayFormat: "repeat", encode: false })}`;
    const url = `${baseUrl}/api/club-feedback${queryParams}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonData: IClubFeedback[] = await response.json();
    if (response.status === 200) {
      console.log('getClubFeedbacksList200', jsonData)
      dispatch(_getClubFeedbacksList(jsonData));
    } else {
      console.log('getClubFeedbacksListNot200', jsonData);
    }
  } catch (e) {
    console.log('getClubFeedbacksListErr', e);
  } finally {
    dispatch(loadingEnd(LoadingSubject.GetClubFeedbacksList));
  }
};

export const deleteClubFeedback = (
  club_id: string,
  customer_id: string,
  onSuccess: () => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.DeleteClubFeedback));
    const { token } = getState().auth;
    const params = { club_id, customer_id };
    const queryParams = `?${qs.stringify(params)}`;
    const url = `${baseUrl}/api/club-feedback${queryParams}`;

    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonData = await response.json();
    if (response.status === 200) {
      console.log('deleteClubFeedback200', jsonData);
      onSuccess && onSuccess();
    } else {
      console.log('deleteClubFeedbackNot200', jsonData);
    }
  } catch (e) {
    console.log('deleteClubFeedbackErr', e);
  } finally {
    dispatch(loadingEnd(LoadingSubject.DeleteClubFeedback));
  }
};

// 

export const getProductFeedbacksList = (
  product_ids?: string[],
  customer_ids?: string[]
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.GetProductFeedbacksList));
    const { token } = getState().auth;
    const params = { product_ids, customer_ids };
    const queryParams = isObjectEmpty(params) ? '' : `?${qs.stringify(params, { arrayFormat: "repeat", encode: false })}`;
    const url = `${baseUrl}/api/product-feedback${queryParams}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonData = await response.json();
    if (response.status === 200) {
      console.log('getProductFeedbacksList200', jsonData)
      dispatch(_getProductFeedbacksList(jsonData));
    } else {
      console.log('getProductFeedbacksListNot200', jsonData);
    }
  } catch (e) {
    console.log('getProductFeedbacksListErr', e);
  } finally {
    dispatch(loadingEnd(LoadingSubject.GetProductFeedbacksList));
  }
};

export const deleteProductFeedback = (
  order_id: string,
  product_id: string,
  onSuccess: () => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.DeleteProductFeedback));
    const { token } = getState().auth;
    const params = { order_id, product_id };
    const queryParams = `?${qs.stringify(params)}`;
    const url = `${baseUrl}/api/product-feedback${queryParams}`;

    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonData = await response.json();
    if (response.status === 200) {
      console.log('deleteProductFeedback200', jsonData)
      onSuccess && onSuccess();
    } else {
      console.log('deleteProductFeedbackNot200', jsonData);
    }
  } catch (e) {
    console.log('deleteProductFeedbackErr', e);
  } finally {
    dispatch(loadingEnd(LoadingSubject.DeleteProductFeedback));
  }
};

export const { _getClubFeedbacksList, _getProductFeedbacksList } = feedbacks.actions;
export default feedbacks.reducer;
