import NewTable from "../../../../components/NewTable/NewTable";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/rootReducer";
import { getNewsList } from "slices/newsSlice";
import moment from "moment";
import { CreateEditNewsModal } from "pages/NewsPage/Modals/CreateEditNewsModal/CreateEditNewsModal";
import { getClubList, getEmployeeList } from "slices/clubSlice";
import "react-quill/dist/quill.snow.css";
import Select from "react-dropdown-select";
import { useRoles } from "hooks/useRoles";
import { useIntl } from "react-intl";

function NewsTab() {
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const roles = useRoles();
  const { me } = useSelector((s: RootState) => s.auth);

  const clubList = useSelector((s: RootState) => s.club.clubList);
  const newsList = useSelector((s: RootState) => s.news.list);

  const [selectedNewsId, setNewsId] = useState<string>();

  useEffect(() => {
    if (roles.SuperAdmin || roles.Moderator) {
      // @ts-ignore
      dispatch(getClubList());
    } else {
      // @ts-ignore
      dispatch(getEmployeeList(me?.id, undefined, true));
    }
  }, [dispatch]);

  useEffect(() => {
    if (clubList.length) {
      // @ts-ignore
      dispatch(getNewsList(clubList[0].id));
    }
  }, [clubList]);

  const selectedNewsItem = newsList.find((n) => n.id === selectedNewsId);

  let headCells = [
    t("text.title"),
    t("text.created"),
    t("Type"),
    t("Status"),
    t("text.club"),
  ];
  let rowsData: string[][] = [];

  newsList?.map((news) => {
    const clubName = clubList.find((c) => c.id === news.club_id);
    return rowsData.push([
      "id_" + news.id,
      news.title,
      moment(news.created_at).format("MMMM Do YYYY, h:mm:ss a"),
      news.is_global ? "Global" : "Local",
      news.moderation_status,
      clubName?.name || news.club_id,
    ]);
  });

  const options = clubList.map((option) => ({
    id: option.id,
    name: option.name,
  }));

  return (
    <div>
      <Select
        searchable={false}
        style={{ width: 250, margin: "12px 0 32px auto" }}
        placeholder={t("text.select-club") + "..."}
        values={
          clubList.length
            ? [{ id: clubList[0].id, name: clubList[0].name }]
            : []
        }
        options={options}
        labelField="name"
        valueField="id"
        onChange={(val) => {
          const clubId = val[0].id;
          // @ts-ignore
          dispatch(getNewsList(clubId));
        }}
      />
      <NewTable
        headCells={headCells}
        rows={rowsData}
        actionButton={{
          onClick: (id: string) => {
            setNewsId(id);
          },
          title: t("general.edit"),
        }}
      />

      {selectedNewsItem && (
        <CreateEditNewsModal
          fromTable={true}
          clubId={selectedNewsItem.club_id}
          open={true}
          selectedItem={selectedNewsItem}
          onClose={() => {
            setNewsId("");
          }}
        />
      )}
    </div>
  );
}
export default NewsTab;
