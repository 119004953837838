import React, { useEffect, useState } from "react";
import styles from "./AdminEditPage.module.css";
import Categories from "./Categories/Categories";
import Basket from "./Basket/Basket";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import { useParams } from "react-router-dom";
import { ICategory, getCategoryList } from "../../slices/categorySlice";
import Products from "./Products/Products";
import AuthorizedLayout from "layouts/AuthorizedLayout";
import { Box, Container } from "@mui/material";
import { getClubMembers } from "slices/clubSlice";

export type CategoryType = {
  id: number;
  name: string;
  image: string;
  desc: string;
};

export type ProductType = {
  id: number;
  image: string;
  title: string;
  desc: string;
  amount: number;
  income: number;
  outcome: number;
  discount: number;
  unit: string;
  delete?: boolean;
};

const s = (className: string) => styles[className];

function AdminEditPage() {
  const dispatch = useDispatch();
  const [categoryItem, setCategoryItem] = useState<ICategory | undefined>();

  const [catId, setCatId] = useState("");

  const params = useParams();
  const clubId = params.id;
  const catType = params.catType;

  useEffect(() => {
    const onSuccess = (categoryItem: ICategory) => {
      setCategoryItem(categoryItem);
    };
    // @ts-ignore
    dispatch(getCategoryList(clubId, catType, onSuccess));
    // @ts-ignore
    dispatch(getClubMembers(clubId));
  }, []);

  const categories = useSelector((s: RootState) => s.category.categoryList);
  const products = useSelector((s: RootState) => s.product.productList);

  return (
    <AuthorizedLayout fullWidth>
      <Container>
        <Basket selectedCategory={categoryItem!} />
      </Container>

      <Categories
        categories={categories}
        clubId={clubId!}
        categoryItem={categoryItem}
        setCategoryItem={setCategoryItem}
        //
        catId={catId}
        setCatId={setCatId}
      />
      <Container>
        <Products
          products={products}
          categoryId={categoryItem?.id!}
          //
          catId={catId}
        />
      </Container>
    </AuthorizedLayout>
  );
}

export default AdminEditPage;
