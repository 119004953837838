import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { UserRole } from 'slices/usersSlice';

export const useRoles = () => {
  const { me } = useSelector((s: RootState) => s.auth);
  return {
    SuperAdmin: me?.role === UserRole.SuperAdmin,
    Moderator: me?.role === UserRole.Moderator,
    ClubOwner: me?.role === UserRole.ClubOwner,
    ManagerAdmin: me?.role === UserRole.ManagerAdmin,
    Staff: me?.role === UserRole.Staff,
  };
}
