import { useDispatch, useSelector } from "react-redux";
import NewTable from "../../../../components/NewTable/NewTable";
import { RootState } from "store/rootReducer";
import { useEffect, useState } from "react";
import {
  deleteTariff,
  getSubscriptionsList,
  getTariffsList,
} from "slices/tariffsSlice";
import { getClubList } from "slices/clubSlice";
import { useIntl } from "react-intl";
import { Column } from "components/blocks";
import AppButton from "components/AppButton/AppButton";
import ModalWrapper from "components/modals/ModalWrapper";
import TariffForm from "./TariffForm";
import AppTabs from "components/AppTabs/AppTabs";
import { useLoadings } from "hooks/useLoadings";
import DeleteModal from "components/modals/DeleteModal/DeleteModal";

enum SubTabsEnum {
  Tariffs,
  Subscriptions,
}

function TariffsTab() {
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });

  const dispatch = useDispatch();

  const [isTariffModal, setTariffModal] = useState(false);
  const openTariffModal = () => setTariffModal(true);
  const closeTariffModal = () => {
    setTariffModal(false);
    setSelectedTariffId("");
    setDeleteTariffModal(false);
  };
  const [selectedTariffId, setSelectedTariffId] = useState("");
  const [subTabStep, setSubTabStep] = useState<SubTabsEnum>(
    SubTabsEnum.Tariffs
  );
  const [deleteTariffModal, setDeleteTariffModal] = useState(false);

  const { subscriptionsList } = useSelector((s: RootState) => s.tariffs);
  const { tariffslist } = useSelector((s: RootState) => s.tariffs);
  const { clubList } = useSelector((s: RootState) => s.club);

  const {
    isCreateTariffLoading,
    isDeleteTariffLoading,
    isUpdateTariffLoading,
  } = useLoadings();

  useEffect(() => {
    if (subTabStep === SubTabsEnum.Tariffs) {
      // @ts-ignore
      dispatch(getTariffsList());
    } else if (subTabStep === SubTabsEnum.Subscriptions) {
      // @ts-ignore
      dispatch(getSubscriptionsList());
    }
  }, [
    subTabStep,
    isCreateTariffLoading,
    isDeleteTariffLoading,
    isUpdateTariffLoading,
  ]);

  useEffect(() => {
    // @ts-ignore
    dispatch(getClubList());
  }, [subscriptionsList]);

  const tariffsHeadCells = [
    t("Name"),
    t("Duration"),
    t("Global"),
    t("Local"),
    t("Price"),
  ];
  const tariffsRowsData: string[][] = [];

  tariffslist.forEach((tf) => {
    const row = [
      "id_" + tf.id,
      tf.name,
      tf.duration.toString(),
      tf.global_news_amount.toString(),
      tf.local_news_amount.toString(),
      tf.price.toString(),
    ];
    tariffsRowsData.push(row);
  });

  const subscriptionsHeadCells = [
    t("text.tariff"),
    t("text.club"),
    t("Start"),
    t("End"),
    t("text.status"),
  ];
  const subscriptionsRowsData: string[][] = [];

  subscriptionsList.forEach((s) => {
    const clubItem = clubList.find((c) => c.id === s.subscription.club_id);
    const row = [
      "id_" + s.tariff.id,
      s.tariff.name || t("text.empty-name"),
      clubItem?.name || s.subscription.id,
      s.subscription.start_date,
      s.subscription.end_date,
      s.subscription.active ? t("active") : t("inactive"),
    ];
    subscriptionsRowsData.push(row);
  });

  const tariffItem = tariffslist.find((t) => t.id === selectedTariffId);

  const tariffsSubTabChild = () => {
    return (
      <Column>
        <AppButton
          onClick={openTariffModal}
          sx={{ mb: 4, mt: -4, alignSelf: "flex-end" }}
          text="Create tariff"
        />
        <NewTable
          headCells={tariffsHeadCells}
          rows={tariffsRowsData}
          actionButton={{
            onClick: (id: string) => setSelectedTariffId(id),
            title: "Edit",
          }}
        />
        <ModalWrapper
          open={isTariffModal || !!tariffItem}
          onClose={closeTariffModal}
          title={tariffItem ? "Editing tariff" : "Creating tariff"}
          maxWidth="40vw"
          headerChildren={
            selectedTariffId && (
              <AppButton
                onClick={() => setDeleteTariffModal(true)}
                variant="outlined"
                text="Delete"
                color="error"
              />
            )
          }
        >
          <TariffForm tariffItem={tariffItem} onClose={closeTariffModal} />
        </ModalWrapper>
        <DeleteModal
          loading={isDeleteTariffLoading}
          open={deleteTariffModal}
          onClose={() => setDeleteTariffModal(false)}
          onDelete={() =>
            // @ts-ignore
            dispatch(deleteTariff(selectedTariffId, closeTariffModal))
          }
          desc="Are your sure you want to delete current tariff?"
          modalTitle="Deleting tariff"
        />
      </Column>
    );
  };

  const subscriptionsSubTabChild = () => {
    return (
      <NewTable
        headCells={subscriptionsHeadCells}
        rows={subscriptionsRowsData}
      />
    );
  };

  const subTabs = [
    {
      name: t("menu-tabs-name.tariffs"),
      children: tariffsSubTabChild(),
      isTabShown: true,
    },
    {
      name: t("Subscriptions"),
      children: subscriptionsSubTabChild(),
      isTabShown: true,
    },
  ];

  return (
    <div style={{ marginTop: -15 }}>
      <AppTabs
        tabStep={subTabStep}
        setTabStep={setSubTabStep}
        tabsItemArr={subTabs}
      />
    </div>
  );
}
export default TariffsTab;
