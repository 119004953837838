import { Box } from "@mui/material";
import React from "react";

export interface BackgroundProps {
  type?: "image" | "video";
  customImageBackground?: string;
}

const Background: React.FC<BackgroundProps> = ({
  type = "image",
  customImageBackground,
}) => {
  const defaultBackgrounds = {
    videoFile: require("assets/videos/smokeVideoBg.mp4"),
    imageFile: require("assets/images/raster/smoke-bg.jpg"),
  };

  const renderVideoBackground = () => {
    const { videoFile } = defaultBackgrounds;
    return (
      <video
        style={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          objectFit: "cover",
        }}
        autoPlay
        loop
        muted
      >
        <source src={videoFile} type="video/mp4" />
      </video>
    );
  };

  const renderImageBackground = () => {
    const imageFile = customImageBackground || defaultBackgrounds.imageFile;
    return (
      <img
        style={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          objectFit: "cover",
        }}
        src={imageFile}
        alt={"background_image"}
      />
    );
  };

  return (
    <Box
      sx={{
        zIndex: -999,
        position: "fixed",
        objectFit: "cover",
        width: "100vw",
        height: "100vh",
        backgroundColor: (t) => t.palette.black.main,
      }}
    >
      {type === "video" ? renderVideoBackground() : renderImageBackground()}
    </Box>
  );
};

export default Background;
