import React from "react";
import * as Icons from "./index";
import iconStyles from "./Icon.module.css";
import classnames from "classnames";

export type names =
  | "Calendar"
  | "Cart"
  | "CloseFill"
  | "CloseStroke"
  | "Cloud"
  | "DigitBig"
  | "DigitSmall"
  | "Dislike"
  | "DollarCircle"
  | "Dots"
  | "Edit"
  | "Empty"
  | "Full"
  | "Like"
  | "Minus"
  | "Point"
  | "QrCode"
  | "Scan"
  | "Search"
  | "Send"
  | "Setting"
  | "Trash"
  | "WiFi"
  | "Logo"
  | "Arrow"
  | "World"
  | "ArrowRight"
  | "Theme"
  | "Plus"
  | "Email"
  | "Filter"
  | "ScanQrCode"
  | "Menu";

export type IconProps = React.AllHTMLAttributes<HTMLSpanElement> & {
  name: names;
  onClick?: () => void;
};

const Icon: React.FC<IconProps> = ({ name, className, onClick }: IconProps) => {
  const containerClassNames = classnames(iconStyles["container"], className);

  // @ts-ignore
  const IconSVG = Icons[name] as keyof JSX.IntrinsicElements;
  return (
    <span onClick={onClick} aria-hidden="true" className={containerClassNames}>
      <IconSVG />
    </span>
  );
};

export default Icon;
