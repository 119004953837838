import { Button, CircularProgress, SxProps, Theme } from "@mui/material";
import { useFormikContext } from "formik";
import { memo } from "react";
import AppText from "../AppText/AppText";
import styles from "./AppButton.module.css";

type AppButtonProps = {
  text: string | React.ReactNode;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  sx?: SxProps<Theme>;
  className?: string;
  variant?: "text" | "contained" | "outlined";
  color?:
    | "primary"
    | "secondary"
    | "error"
    | "white1"
    | "gray2"
    | "gray3"
    | "inherit";
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  isForm?: boolean;
  messagesCount?: number;
  loading?: boolean;
  fullWidth?: boolean;
};

const AppButton = (props: AppButtonProps) => {
  const {
    text,
    onClick,
    type,
    disabled,
    sx,
    className,
    variant,
    color,
    startIcon,
    endIcon,
    isForm,
    messagesCount,
    loading,
    fullWidth,
  } = props;
  const formikHook = useFormikContext();

  return (
    <Button
      type={type}
      fullWidth={fullWidth}
      disabled={disabled || loading}
      onClick={isForm ? (formikHook?.handleSubmit as () => void) : onClick}
      sx={{
        ...sx,
        textTransform: "none",
      }}
      className={className}
      variant={variant || "contained"}
      color={color}
      startIcon={startIcon}
      endIcon={loading ? <CircularProgress size={20} /> : endIcon}
    >
      {text}
      {messagesCount && (
        <div className={styles["message-container"]}>
          <AppText
            color="white"
            variant="body2"
            className={styles["message-text"]}
          >
            {messagesCount}
          </AppText>
        </div>
      )}
    </Button>
  );
};

export default memo(AppButton);
