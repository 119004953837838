import React, { useEffect, useState } from "react";
import styles from "./Basket.module.css";
import AppText from "../../../components/AppText/AppText";
import AppButton from "../../../components/AppButton/AppButton";
import Icon from "../../../assets/icons/svgs/Icon";
import { AppColors } from "../../../AppTheme";
import QrScannerModal from "../Modals/QrScanner/QrScanner";
import OrdersModalTable from "../Modals/OrdersModalTable/OrdersModalTable";
import UserInfoModal from "../Modals/UserInfoModal/UserInfoModal";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  IOrder,
  completeOrder,
  getClubOrders,
} from "../../../slices/ordersSlice";
import { RootState } from "../../../store/rootReducer";
import { IProduct } from "../../../slices/productSlice";
import { ICategory } from "../../../slices/categorySlice";
import { Row } from "components/blocks";
import { IClubMember, getClubMembers } from "slices/clubSlice";
import MembersModalTable from "../Modals/MembersModalTable/MembersModalTable";
import { useIntl } from "react-intl";
import styled from "styled-components";

type Props = {
  selectedCategory: ICategory;
};

export enum BasketStateEnum {
  NoUser,
  UserSelected,
  OpenedBasket,
}

export enum BasketModalEnum {
  None,
  QRScanner,
  OrderSelect,
  AllUsers,
  UserInfo,
}

const s = (className: string) => styles[className];

function Basket(props: Props) {
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const { selectedCategory } = props;

  const [basketState, setBasketState] = useState(BasketStateEnum.UserSelected);

  const [isModal, setModal] = useState(BasketModalEnum.None);
  const openQrModal = () => setModal(BasketModalEnum.QRScanner);
  const openOrderSelectModal = () => setModal(BasketModalEnum.OrderSelect);
  const openAllUsersModal = () => setModal(BasketModalEnum.AllUsers);
  const closeModal = () => setModal(BasketModalEnum.None);

  const [selectedOrder, setSelectedOrder] = useState<IOrder | undefined>(
    undefined
  );

  const [selectedMember, setSelectedMember] = useState<IClubMember | undefined>(
    undefined
  );

  const dispatch = useDispatch();

  const params = useParams();

  const ordersList = useSelector((s: RootState) => s.order.list);
  const members = useSelector((s: RootState) => s.club.membersList);

  const { token } = useSelector((s: RootState) => s.auth);

  useEffect(() => {
    if (params.id) {
      // @ts-ignore
      dispatch(getClubOrders(params.id));
      // @ts-ignore
      dispatch(getClubMembers(params.id));
    }
  }, []);

  const username = selectedOrder ? selectedOrder.customer_fullname : "None";
  // const deposit = "$ 2.000,00";
  const totalOutcome =
    selectedOrder?.products?.reduce(
      (acc, val) => acc + val.price * val.amount,
      0
    ) || 0;

  function renderProductItem(product: IProduct) {
    const productDescription =
      product.description.length > 60
        ? product.description.slice(0, 60) + "..."
        : product.description;
    return (
      <div className={s("item-container")}>
        <div
          className={s("item-left")}
          style={{ display: "flex", alignItems: "flex-end" }}
        >
          <div
            className={s("item-pic")}
            style={{
              backgroundImage: `url(${product?.image_url + "&token=" + token})`,
            }}
          />
        </div>
        <div className={s("item-right")}>
          <AppText variant="h4">{product.name}</AppText>
          <AppText
            sx={{ fontFamily: "Malgun-Regular", mt: 0.8, mb: 0.8 }}
            variant="body4"
          >
            {productDescription}
          </AppText>
          <div>
            <AppText>{t("text.amount") + ":"}</AppText>
            <AppText sx={{ fontFamily: "Malgun-Bold" }}>
              {`${product.amount} (g)`}
            </AppText>
          </div>
          <div>
            <AppText>{t("text.price") + ":"}</AppText>
            <AppText sx={{ fontFamily: "Malgun-Bold" }}>
              {`€${product.price} (per g)`}
            </AppText>
          </div>
        </div>
      </div>
    );
  }

  function renderHeaderControls() {
    const userData = (
      <div
        // onClick={() => setModal(BasketModalEnum.UserInfo)}
        className={s("user-data")}
      >
        {/* <img
          className={s("user-pic")}
          src={require("./../../../assets/images/raster/avatar2.jpg")}
          alt="user-pic"
        /> */}
        <AppText
          className={s("username-deposit")}
          color="white"
          variant="body2"
        >
          <span>{username}</span>
          <span>{` € ${
            members.find((m) => m.id === selectedOrder?.customer_id)?.balance ||
            0
          }`}</span>
        </AppText>
      </div>
    );

    if (!selectedOrder) {
      return (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <AppButton
            onClick={() => {
              // @ts-ignore
              dispatch(getClubOrders(params.id));
              openOrderSelectModal();
            }}
            sx={{ color: AppColors.gray1 }}
            color="white1"
            variant="outlined"
            text={t("text.show-orders")}
            startIcon={<Icon name="Plus" />}
          />
          <AppButton
            startIcon={<Icon className={s("scan-qr-icon")} name="ScanQrCode" />}
            onClick={openQrModal}
            sx={{ fontSize: 15 }}
            variant="outlined"
            color="white1"
            text={t("text.scan-qr-code")}
          />
        </div>
      );
    } else {
      return (
        <Row wrap sx={{ width: "100%", gap: 8 }}>
          {userData}
          <AppText color="white" variant="body2">
            {`${t("text.total-outcome")} €${totalOutcome}`}
          </AppText>
          <AppButton
            sx={{ fontSize: 15 }}
            variant="outlined"
            color="white1"
            text={t("text.clear-all")}
            onClick={() => setSelectedOrder(undefined)}
          />
          <AppButton
            onClick={() => {
              const onSuccess = () => setSelectedOrder(undefined);

              dispatch(
                // @ts-ignore
                completeOrder(
                  selectedOrder.id,
                  selectedOrder.club_id,
                  selectedCategory?.id || undefined,
                  onSuccess
                )
              );
            }}
            sx={{ fontSize: 15 }}
            text={t("text.give-all-to-user")}
          />
        </Row>
      );
    }
  }

  const basketHeaderStyles = `${s("basket-header")} ${
    basketState === BasketStateEnum.UserSelected
      ? s("basket-header-selected-user")
      : ""
  }`;

  return (
    <div className={s("basket-container")}>
      <AppButton
        onClick={openAllUsersModal}
        sx={{ alignSelf: "flex-end" }}
        color="white1"
        variant="outlined"
        text={t("text.all-club-users")}
      />
      <div className={basketHeaderStyles}>{renderHeaderControls()}</div>
      {
        <div className={s("basket-items-container")}>
          {selectedOrder?.products?.map((p) => renderProductItem(p))}
        </div>
      }
      <QrScannerModal
        setSelectedOrder={(order: IOrder) => setSelectedOrder(order)}
        open={isModal === BasketModalEnum.QRScanner}
        onClose={closeModal}
      />
      <OrdersModalTable
        ordersList={ordersList}
        open={isModal === BasketModalEnum.OrderSelect}
        onClose={closeModal}
        returnOrderId={(id: string) => {
          const selectedOrder = ordersList.find((o) => o.id === id);
          setSelectedOrder(selectedOrder);
        }}
      />
      <MembersModalTable
        selectedMember={selectedMember!}
        setSelectedMember={setSelectedMember}
        membersList={members}
        open={isModal === BasketModalEnum.AllUsers}
        onClose={closeModal}
      />
      <UserInfoModal
        open={isModal === BasketModalEnum.UserInfo}
        onClose={closeModal}
      />
    </div>
  );
}

export default Basket;
