import { combineReducers } from '@reduxjs/toolkit';
import authSlice from './../slices/authSlice';
import loadingReducer from './../slices/loadingSlice';
import languageSlice from './../slices/languageSlice';
import clubSlice from './../slices/clubSlice';
import categorySlice from '../slices/categorySlice';
import productSlice from '../slices/productSlice';
import applicationSlice from '../slices/applicationSlice';
import ordersSlice from '../slices/ordersSlice';
import usersSlice from '../slices/usersSlice';
import depositSlice from '../slices/depositSlice';
import tariffsSlice from 'slices/tariffsSlice';
import newsSlice from 'slices/newsSlice';
import statisticsSlice from 'slices/statisticsSlice';
import feedbacksSlice from 'slices/feedbacksSlice';
import messagesSlice from 'slices/messagesSlice';
import commentsSlice from 'slices/commentsSlice';

const rootReducer = combineReducers({
  auth: authSlice,
  club: clubSlice,
  category: categorySlice,
  product: productSlice,
  application: applicationSlice,
  loading: loadingReducer,
  language: languageSlice,
  order: ordersSlice,
  users: usersSlice,
  balance: depositSlice,
  tariffs: tariffsSlice,
  news: newsSlice,
  statistics: statisticsSlice,
  feedbacks: feedbacksSlice,
  messages: messagesSlice,
  comments: commentsSlice
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
