import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "store/store";
import { LoadingSubject, loadingEnd, loadingStart } from "./loadingSlice";
import { baseUrl } from "../constants";
import qs from "qs";

export enum NewsStatusEnum {
  Pending = "pending",
  Rejected = "rejected",
  Published = 'published'
}

export interface INews {
  id: string;
  title: string;
  content: string;
  club_id: string;
  is_global: boolean;
  photo_url: string;
  created_at: string;
  moderation_status: string;
  likes_count: number;
  dislikes_count: number;
}

interface INewsAvailableAmount {
  global_news_amount: number;
  local_news_amount: number;
}

interface INewsState {
  list: INews[];
  availableAmount?: INewsAvailableAmount;
}

const initialState: INewsState = {
  list: [],
};

const news = createSlice({
  name: "news",
  initialState,
  reducers: {
    _getNewsList: (state, { payload }: PayloadAction<INews[]>) => {
      return { ...state, list: payload };
    },
    _getNewsAvailableAmount: (
      state,
      { payload }: PayloadAction<INewsAvailableAmount>
    ) => {
      return { ...state, availableAmount: payload };
    },
  },
});

export const getNewsList = (
  club_id?: string,
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.GetNewsList));
    const { token } = getState().auth;
    const queryParams = `${qs.stringify({ club_id })}`;
    const url = `${baseUrl}/api/news${queryParams ? '?' + queryParams : ''}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    let jsonData: INews[] = await response.json();
    if (response.ok) {
      dispatch(_getNewsList(jsonData));
      if (club_id) {
        dispatch(getNewsAvailableAmount(club_id));
      }
    } else {
    }
  } catch (e) {
  } finally {
    dispatch(loadingEnd(LoadingSubject.GetNewsList));
  }
};

export const createNews = (
  formData: FormData,
  club_id?: string,
  is_global?: boolean,
  onSuccess?: () => void,
  onFail?: () => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.CreateEditNews));
    const { token } = getState().auth;
    const url = `${baseUrl}/api/news`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const jsonData = await response.json();
    if (response.status === 200) {
      dispatch(getNewsList(club_id));
      onSuccess && onSuccess();
    } else {
      onFail && onFail();
    }
  } catch (e) {
  } finally {
    dispatch(loadingEnd(LoadingSubject.CreateEditNews));
  }
};

export const getNewsAvailableAmount = (
  club_id: string
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.GetNewsAvailableAmount));
    const { token } = getState().auth;
    const url = `${baseUrl}/api/news/${club_id}/available_amount`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonData = await response.json();
    if (response.ok) {
      dispatch(_getNewsAvailableAmount(jsonData));
    } else {
    }
  } catch (e) {
  } finally {
    dispatch(loadingEnd(LoadingSubject.GetNewsAvailableAmount));
  }
};

export const deleteNews = (
  newsId: string,
  clubId?: string,
  onSuccess?: () => void,
  onFail?: () => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.DeleteNews));
    const { token } = getState().auth;
    const url = `${baseUrl}/api/news/${newsId}`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const jsonData = await response.json();
    if (response.ok) {
      dispatch(getNewsList(clubId));
      onSuccess && onSuccess();
    } else {
      onFail && onFail();
    }
  } catch (e) {
  } finally {
    dispatch(loadingEnd(LoadingSubject.DeleteNews));
  }
};

export const updateNews = (
  newsId: string,
  body: {
    title: string;
    content: string;
    is_global: boolean;
  },
  clubId?: string,
  onSuccess?: () => void,
  onFail?: () => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.UpdateNews));
    const { token } = getState().auth;
    const url = `${baseUrl}/api/news/${newsId}`;
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const jsonData = await response.json();
    if (response.ok) {
      dispatch(getNewsList(clubId));
      onSuccess && onSuccess();
    } else {
      onFail && onFail();
    }
  } catch (e) {
  } finally {
    dispatch(loadingEnd(LoadingSubject.UpdateNews));
  }
};

export const setNewsPhoto = (
  newsId: string,
  formData: FormData,
  clubId?: string,
  onSuccess?: () => void,
  onFail?: () => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.SetNewsPhoto));
    const { token } = getState().auth;
    const url = `${baseUrl}/api/news/${newsId}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const jsonData = await response.json();
    if (response.ok) {
      dispatch(getNewsList(clubId));
      onSuccess && onSuccess();
    } else {
      onFail && onFail();
    }
  } catch (e) {
  } finally {
    dispatch(loadingEnd(LoadingSubject.SetNewsPhoto));
  }
};

export const publishNews = (
  newsId: string,
  clubId: string,
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.PublishNews));
    const { token } = getState().auth;
    const url = `${baseUrl}/api/news/${newsId}/publish`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      dispatch(getNewsList(clubId));
    } else {
      // 
    }
  } catch (e) {
  } finally {
    dispatch(loadingEnd(LoadingSubject.PublishNews));
  }
};

export const rejectNews = (
  newsId: string,
  clubId: string,
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.RejectNews));
    const { token } = getState().auth;
    const url = `${baseUrl}/api/news/${newsId}/reject`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      dispatch(getNewsList(clubId));
    } else {
      // 
    }
  } catch (e) {
  } finally {
    dispatch(loadingEnd(LoadingSubject.RejectNews));
  }
};

export const { _getNewsList, _getNewsAvailableAmount } = news.actions;
export default news.reducer;
