import styles from "./ForgotPasswordPage.module.css";
import AppText from "../../../components/AppText/AppText";
import AppInput from "../../../components/AppInput/AppInput";
import AppForm from "../../../forms/AppForm";
import { FormikValues } from "formik";
import { useIntl } from "react-intl";
import { useSchemas } from "../../../helpers/schemas";
import AppButton from "../../../components/AppButton/AppButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useState } from "react";
import { AppColors } from "../../../AppTheme";
import { useNav } from "../../../hooks/useNav";
import UnauthorizedLayout from "layouts/UnauthorizedLayout";
import { Row } from "components/blocks";
import { IconLogo } from "components/icons";
import { useDispatch } from "react-redux";
import { forgot } from "slices/authSlice";
import { useLoadings } from "hooks/useLoadings";

enum StepsEnum {
  Forgot,
  Recovery,
}

function ForgotPasswordPage() {
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const { forgotPasswordSchema, restorePasswordSchema } = useSchemas();
  const { viewClubMenu, viewLogin } = useNav();
  const [step, setStep] = useState(StepsEnum.Forgot);
  const isForgot = step === StepsEnum.Forgot;
  const validationSchema = isForgot
    ? forgotPasswordSchema
    : restorePasswordSchema;

  const dispatch = useDispatch();
  const { isForgotLoading } = useLoadings();

  const subTitleText = isForgot
    ? t("password.forgot-title")
    : t("password.new-create");

  const subTitleText2 = t("password.forgot-reset");

  const formInitialValues = isForgot
    ? { email: "" }
    : { password: "", passwordConfirmation: "" };

  const onSubmit = (values: FormikValues) => {
    // if (step === StepsEnum.Recovery) {
    //   viewClubMenu();
    //   return;
    // }
    // setStep(StepsEnum.Recovery);

    // @ts-ignore
    dispatch(forgot(values.email, viewLogin));
  };

  return (
    <UnauthorizedLayout>
      <Row style={{ flexDirection: "row-reverse" }}>
        {/* <IconLogo color="white" style={{ width: 200, height: 50 }} /> */}
        <img
          style={{ width: 300, height: 100, objectFit: "contain" }}
          src={require("../../../assets/images/raster/logo_white.png")}
        />
      </Row>
      <div className={styles["form-container"]}>
        <AppText variant="h1" className={styles["login-title"]} color={"white"}>
          {"Reset password"}
        </AppText>
        <AppText sx={{ mt: 2 }} color="white" variant="body1">
          {subTitleText}
        </AppText>
        {isForgot && (
          <AppText color="white" variant="body3">
            {subTitleText2}
          </AppText>
        )}

        <AppForm
          initialValues={formInitialValues}
          onSubmit={onSubmit}
          children={
            <>
              {isForgot ? (
                <AppInput
                  isForm
                  classNameContainer={styles["input-field-container-email"]}
                  name={"email"}
                  placeholder={t("general.email")}
                />
              ) : (
                <>
                  <AppInput
                    isForm
                    classNameContainer={
                      styles["input-field-container-password"]
                    }
                    name={"password"}
                    type={"password"}
                    placeholder={t("general.password")}
                    iconColor={AppColors.gray2}
                  />
                  <AppInput
                    isForm
                    classNameContainer={
                      styles["input-field-container-password"]
                    }
                    name={"passwordConfirmation"}
                    type={"password"}
                    placeholder={t("password.approve-password")}
                    iconColor={AppColors.gray2}
                  />
                </>
              )}

              <AppButton
                className={styles["sign-in-button"]}
                text={t("password.reset-password-btn")}
                endIcon={<ChevronRightIcon />}
                isForm
                loading={isForgotLoading}
              />
            </>
          }
          validationSchema={validationSchema}
          enableReinitialize={true}
        />
      </div>
    </UnauthorizedLayout>
  );
}
export default ForgotPasswordPage;
