import enTranslations from './strings/en.json';
import ukTranslations from './strings/ua.json';

export enum LanguageCode {
  ENGLISH = 'en',
  UKRAINIAN = 'uk',
}

export const DEFAULT_LANGUAGE_CODE = LanguageCode.ENGLISH;

export const AVAILABLE_TRANSLATIONS: LanguageCode[] = [
  LanguageCode.ENGLISH,
  LanguageCode.UKRAINIAN,
];

export const languageCodeToDisplayTitle = (code: LanguageCode) => {
  switch (code) {
    case LanguageCode.ENGLISH:
      return 'English';

    case LanguageCode.UKRAINIAN:
      return 'Українська';
  }
};

export const translationsForCode = (code: LanguageCode) => {
  switch (code) {
    case LanguageCode.ENGLISH:
      return enTranslations;
    case LanguageCode.UKRAINIAN:
      return ukTranslations;
  }
};
