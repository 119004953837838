import * as React from "react";
import styled from "@emotion/styled";

import { Avatar, Box, Button, Menu, MenuItem } from "@mui/material";
import AppText from "../AppText/AppText";
import { useDispatch } from "react-redux";
import { _logout } from "../../slices/authSlice";
import { useIntl } from "react-intl";

export interface AvatarMenuOption {
  title: string;
  onClick: () => void;
}

export interface AvatarMenuProps {
  userData?: {
    name: string;
    role: string;
    // avatar: string;
  };
  options?: AvatarMenuOption[];
}

const AvatarMenu: React.FC<AvatarMenuProps> = ({ options = [], userData }) => {
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    dispatch(_logout());
  };

  return (
    <>
      <AvatarButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {/* <HeaderAvatar
          src={
            userData?.avatar ||
            require("./../../assets/images/raster/avatar.png")
          }
        /> */}
        <Name>{userData?.name || "User name"}</Name>
        <Role>{userData?.role || "Role"}</Role>
      </AvatarButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {options.map(({ title, onClick }: AvatarMenuOption) => (
          <MenuItem
            key={title}
            onClick={() => {
              onClick();
              handleClose();
            }}
          >
            <AppText variant={"body4"}>{title}</AppText>
          </MenuItem>
        ))}
        <MenuItem
          key={"logout"}
          onClick={() => {
            handleLogout();
            handleClose();
          }}
        >
          <AppText color={"red"}>{t("general.logout")}</AppText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default AvatarMenu;

const AvatarButton = styled(Button)(({ theme }: any) => ({
  display: "flex",
  gap: theme.spacing(4),
  textTransform: "none",
  color: theme.palette.black,
}));

export const HeaderAvatar = styled(Avatar)(({ theme }: any) => ({
  width: 30,
  height: 30,
  borderRadius: "5px",
}));

export const Name = styled(Box)(({ theme }: any) => ({
  color: theme.palette.common.black,
  fontFamily: "Malgun-Bold",
  fontSize: 15,
  fontStyle: "normal",
  lineHeight: "normal",
}));

export const Role = styled(Box)(({ theme }: any) => ({
  color: theme.palette.grey[800],
  fontFamily: "Malgun-Regular",
  fontSize: 15,
  fontStyle: "normal",
  lineHeight: "normal",
}));
