import AppButton from "../AppButton/AppButton";
import TrashIcon from "@mui/icons-material/DeleteOutline";
import ModalWrapper from "components/modals/ModalWrapper";

type ClubItem = {
  name: string;
  color_hex: string;
  card_style_type: "full" | "standard" | "small" | "tiny";
  show_product_amount: boolean;
  gradient_angle: number;
  picture: any;
};

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  clubItem?: ClubItem;
  width?: string;
};

function ClubModal(props: Props) {
  const { isOpen, closeModal, width } = props;

  return (
    <ModalWrapper
      title={
        <AppButton
          sx={{ fontFamily: "Malgun-Bold", fontSize: 13 }}
          startIcon={<TrashIcon fontSize="large" />}
          variant="text"
          color="error"
          text={"Delete Club"}
          onClick={() => {}}
        />
      }
      maxWidth={width + "px" || "800px"}
      open={isOpen}
      onClose={closeModal}
      // childrenContainerClassname={styles["modal-container"]}
    ></ModalWrapper>
  );
}

export default ClubModal;
