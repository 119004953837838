import { useEffect, useState } from "react";
import styles from "./FeedbacksPage.module.css";
import { AppColors } from "../../AppTheme";
import AppText from "../../components/AppText/AppText";
import AppButton from "../../components/AppButton/AppButton";
import AuthorizedLayout from "layouts/AuthorizedLayout";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  IClubFeedback,
  IProductFeedback,
  deleteClubFeedback,
  deleteProductFeedback,
  getClubFeedbacksList,
  getProductFeedbacksList,
} from "slices/feedbacksSlice";
import { useParams } from "react-router-dom";
import { RootState } from "store/rootReducer";
import { CircularProgress, Rating } from "@mui/material";
import moment from "moment";
import { useLoadings } from "hooks/useLoadings";
import AppTabs, { AppTabItemType } from "components/AppTabs/AppTabs";
import DeleteModal from "components/modals/DeleteModal/DeleteModal";
import { IProduct } from "slices/productSlice";
import { baseUrl } from "./../../constants";
import Select from "react-dropdown-select";
import { getClubList } from "slices/clubSlice";
import { Row } from "components/blocks";

enum FeedbackTabsEnum {
  ClubFeedbacks,
  ProductFeedbacks,
}

const getProductsByIds = async (
  token: string,
  productIds: string[],
  onSuccess: (data: IProduct[]) => void
) => {
  const productItems: IProduct[] = [];
  for (let pId of productIds) {
    try {
      const response = await fetch(`${baseUrl}/api/product/${pId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const jsonData = await response.json();
      if (response.status === 200) {
        productItems.push(jsonData);
      }
    } catch (e) {
      console.log("getProductsByIdsErr", e);
    }
  }
  onSuccess(productItems);
};

function FeedbacksPage() {
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [tabStep, setTabStep] = useState<FeedbackTabsEnum>(
    FeedbackTabsEnum.ClubFeedbacks
  );
  const [productsList, setProductsList] = useState<IProduct[] | undefined>(
    undefined
  );
  const { token } = useSelector((s: RootState) => s.auth);

  const params = useParams();
  const clubIdParam = params.clubId;

  const { clubFeedbacksList, productFeedbacksList } = useSelector(
    (s: RootState) => s.feedbacks
  );

  const showAllOptionItem = { id: "none", name: "Show all..." };

  const [clubFilterSelectedValue, setClubFilterSelectedValue] =
    useState(showAllOptionItem);

  const [productFilterSelectedValue, setProductFilterSelectedValue] =
    useState(showAllOptionItem);

  const clubList = useSelector((s: RootState) => s.club.clubList);

  const clubOptions = clubList.map((option) => ({
    id: option.id,
    name: option.name,
  }));
  clubOptions.unshift(showAllOptionItem);

  let productOptions = productsList?.map((option) => ({
    id: option.id,
    name: option.name,
  }));
  productOptions?.unshift(showAllOptionItem);

  if (productOptions) {
    const noDubls: typeof productOptions = [];
    productOptions.map((po) => {
      if (!noDubls?.find((nd) => nd.id === po.id)) {
        noDubls.push(po);
      }
    });
    productOptions = noDubls;
  }

  const [selectedClubFeedback, setSelectedClubFeedback] = useState<
    IClubFeedback | undefined
  >();

  const [selectedProductFeedback, setSelectedProductFeedback] = useState<
    IProductFeedback | undefined
  >();

  const {
    isClubFeedbacksListLoading,
    isDeleteClubFeedbackLoading,
    isProductFeedbacksListLoading,
    isDeleteProductFeedbackLoading,
  } = useLoadings();

  const isClubTabActive = tabStep === FeedbackTabsEnum.ClubFeedbacks;

  const [stop, setStop] = useState(false);

  useEffect(() => {
    if (stop) {
      return;
    }
    const productsIds = productFeedbacksList.map((p) => p.product_id);
    if (productsIds.length) {
      const onSuccess = (data: IProduct[]) => setProductsList(data);
      getProductsByIds(token, productsIds, onSuccess);
    }
  }, [isProductFeedbacksListLoading]);

  useEffect(() => {
    // @ts-ignore
    dispatch(getClubFeedbacksList(clubIdParam ? [clubIdParam] : undefined));
    if (!clubIdParam) {
      // @ts-ignore
      dispatch(getProductFeedbacksList());
    }
  }, [isDeleteClubFeedbackLoading, isDeleteProductFeedbackLoading]);

  useEffect(() => {
    if (stop) {
      return;
    }
    const clubFeedbacksClubIds = clubFeedbacksList.map((cf) => cf.club_id);
    if (clubFeedbacksClubIds.length) {
      // @ts-ignore
      dispatch(getClubList(clubFeedbacksClubIds));
    }
  }, [isClubFeedbacksListLoading]);

  const closeDeleteModal = () => {
    setSelectedClubFeedback(undefined);
    setSelectedProductFeedback(undefined);
  };

  const noFeedbacksText = (
    <AppText sx={{ textAlign: "center", mt: 10 }} variant="h3" color="gray2">
      {t("No feedbacks found")}
    </AppText>
  );

  const renderClubFeedbackItem = (item: IClubFeedback) => {
    const findClubName = clubList.find((c) => c.id === item.club_id);
    return (
      <div className={styles["item-container"]}>
        <div className={styles["item-header"]}>
          <div className={styles["item-header-left"]}>
            <div className={styles["item-header-left-image-container"]}>
              <img
                className={styles["item-header-left-image"]}
                src={item.customer.photo_url + "&token=" + token}
                alt="feedback-item"
              />
            </div>
            <div className={styles["item-header-left-desc"]}>
              <AppText variant="h4">{`${item.customer.full_name}`}</AppText>
              {/* <AppText color="gray3" variant="body4">
                {`${t("text.order")}: ${ORDER ID}`}
              </AppText> */}
            </div>
          </div>
          <div className={styles["item-header-right"]}>
            <AppButton
              onClick={() => setSelectedClubFeedback(item)}
              variant="outlined"
              color="error"
              text={t("text.delete-feedback")}
            />
          </div>
        </div>
        <AppText style={{ marginTop: 10 }} variant="body3">
          {item.text}
        </AppText>
        {!clubIdParam && (
          <div style={{ marginTop: 10, display: "flex" }}>
            <AppText style={{ fontFamily: "Malgun-bold" }} variant="body3">
              {"Club name:"}
            </AppText>
            <AppText style={{ marginLeft: 7 }} variant="body3">
              {findClubName ? findClubName.name : "Loading..."}
            </AppText>
          </div>
        )}
        <div className={styles["item-footer"]}>
          <Rating
            style={{ opacity: 1 }}
            disabled
            value={item.score}
            size="small"
            sx={{
              "& .MuiRating-iconFilled": {
                color: AppColors.red,
              },
              "& .MuiRating-iconEmpty": {
                color: AppColors.gray2,
              },
            }}
          />
          <AppText variant="body4">
            {moment(item.created_at).format("MMMM Do YYYY, h:mm:ss a")}
          </AppText>
        </div>
      </div>
    );
  };

  const renderProductFeedbackItem = (item: IProductFeedback) => {
    const productItem = productsList?.find((p) => p.id === item.product_id);
    return (
      <div className={styles["item-container"]}>
        <div className={styles["item-header"]}>
          <div className={styles["item-header-left"]}>
            <div className={styles["item-header-left-image-container"]}>
              <img
                className={styles["item-header-left-image"]}
                src={item.customer.photo_url + "&token=" + token}
                alt="feedback-item"
              />
            </div>
            <div className={styles["item-header-left-desc"]}>
              <AppText variant="h4">{`${item.customer.fullname}`}</AppText>
              {/* <AppText color="gray3" variant="body4">
                {`${t("text.order")}: ${ORDER ID}`}
              </AppText> */}
            </div>
          </div>
          <div className={styles["item-header-right"]}>
            <AppButton
              onClick={() => setSelectedProductFeedback(item)}
              variant="outlined"
              color="error"
              text={t("text.delete-feedback")}
            />
          </div>
        </div>
        <AppText style={{ marginTop: 10 }} variant="body3">
          {item.text}
        </AppText>
        <div style={{ marginTop: 10, display: "flex" }}>
          <AppText style={{ fontFamily: "Malgun-bold" }} variant="body3">
            {"Product name:"}
          </AppText>
          <AppText style={{ marginLeft: 7 }} variant="body3">
            {productItem ? productItem.name : "Loading..."}
          </AppText>
        </div>
        <div className={styles["item-footer"]}>
          <Rating
            style={{ opacity: 1 }}
            disabled
            value={item.score}
            size="small"
            sx={{
              "& .MuiRating-iconFilled": {
                color: AppColors.red,
              },
              "& .MuiRating-iconEmpty": {
                color: AppColors.gray2,
              },
            }}
          />
          <AppText variant="body4">
            {moment(item.created_at).format("MMMM Do YYYY, h:mm:ss a")}
          </AppText>
        </div>
      </div>
    );
  };

  const titleNode = (
    <AppText color="white" variant="h2">
      {isClubTabActive ? t("club-feedbacks") : t("product-feedbacks")}
    </AppText>
  );

  const tabs: AppTabItemType[] = [
    {
      name: t("club-feedbacks"),
      children: (
        <div className={styles["items-wrapper"]}>
          <Row>
            {titleNode}
            {!clubIdParam && (
              <Select
                searchable={false}
                values={[clubFilterSelectedValue]}
                options={clubOptions}
                labelField="name"
                valueField="id"
                onChange={(val) => {
                  const optionItem = val[0];
                  setClubFilterSelectedValue(optionItem);
                  setStop(true);
                  const isShowAll = optionItem.id === "none";
                  const requestParams = isShowAll ? undefined : [optionItem.id];
                  // @ts-ignore
                  dispatch(getClubFeedbacksList(requestParams));
                }}
              />
            )}
          </Row>
          {/* {isClubFeedbacksListLoading ? (
            <></>
          ) : !clubFeedbacksList.length && !isClubFeedbacksListLoading ? (
            noFeedbacksText
          ) : (
            clubFeedbacksList.map((item) => renderClubFeedbackItem(item))
          )} */}

          {isClubFeedbacksListLoading && (
            <CircularProgress
              sx={{ color: "#ccc" }}
              size={50}
              style={{ position: "absolute", top: "50%", left: "50%" }}
            />
          )}

          {!isClubFeedbacksListLoading && !clubFeedbacksList.length
            ? noFeedbacksText
            : clubFeedbacksList.map((item) => renderClubFeedbackItem(item))}
        </div>
      ),
    },
    {
      name: t("product-feedbacks"),
      children: (
        <div className={styles["items-wrapper"]}>
          <Row>
            {titleNode}
            <Select
              searchable={false}
              values={[productFilterSelectedValue]}
              options={productOptions || []}
              labelField="name"
              valueField="id"
              onChange={(val) => {
                const optionItem = val[0];
                setProductFilterSelectedValue(optionItem);
                setStop(true);
                const isShowAll = optionItem.id === "none";
                const requestParams = isShowAll ? undefined : [optionItem.id];
                // @ts-ignore
                dispatch(getProductFeedbacksList(requestParams));
              }}
            />
          </Row>
          {/* {isProductFeedbacksListLoading ? (
            <></>
          ) : !productFeedbacksList.length && !isProductFeedbacksListLoading ? (
            noFeedbacksText
          ) : (
            productFeedbacksList.map((item) => renderProductFeedbackItem(item))
          )} */}

          {isProductFeedbacksListLoading && (
            <CircularProgress
              sx={{ color: "#ccc" }}
              size={50}
              style={{ position: "absolute", top: "50%", left: "50%" }}
            />
          )}

          {!isProductFeedbacksListLoading && !productFeedbacksList.length
            ? noFeedbacksText
            : productFeedbacksList.map((item) =>
                renderProductFeedbackItem(item)
              )}
        </div>
      ),
      hidden: !!clubIdParam,
    },
  ];

  return (
    <AuthorizedLayout>
      <AppTabs
        onTabChange={() => setStop(false)}
        tabStep={tabStep}
        setTabStep={setTabStep}
        tabsItemArr={tabs}
      />

      <DeleteModal
        title={
          isClubTabActive ? "Delete club feedback?" : "Delete product feedback?"
        }
        open={!!selectedClubFeedback || !!selectedProductFeedback}
        onClose={closeDeleteModal}
        onDelete={() => {
          if (isClubTabActive && selectedClubFeedback) {
            dispatch(
              // @ts-ignore
              deleteClubFeedback(
                selectedClubFeedback.club_id,
                selectedClubFeedback.customer_id,
                closeDeleteModal
              )
            );
          } else if (!isClubTabActive && selectedProductFeedback) {
            dispatch(
              // @ts-ignore
              deleteProductFeedback(
                selectedProductFeedback?.order_id,
                selectedProductFeedback?.product_id,
                closeDeleteModal
              )
            );
          }
        }}
      />
    </AuthorizedLayout>
  );
}

export default FeedbacksPage;
