import React, { useEffect, useState } from "react";
import styles from "./HelpChatPage.module.css";
import AppText from "../../components/AppText/AppText";
import AuthorizedLayout from "layouts/AuthorizedLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  IMessagesGrouped,
  createMessage,
  getMessagesGroupedList,
  markMessageAsRead,
} from "slices/messagesSlice";
import { RootState } from "store/rootReducer";
import { getClubList } from "slices/clubSlice";
import { Row } from "components/blocks";
import Select from "react-dropdown-select";
import ChatModal from "./ChatModal/ChatModal";
import { useLoadings } from "hooks/useLoadings";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";

type SelectOptionType = {
  id: string;
  name: string;
};

function HelpChatPage() {
  const dispatch = useDispatch();

  const [selectedClub, setSelectedClub] = useState<
    SelectOptionType | undefined
  >();

  const [selectedGroupId, setSelectedGroupId] = useState("");
  const [messageText, setMessageText] = useState<string>("");

  const { isMessagesGroupedListLoading } = useLoadings();

  const { clubList } = useSelector((s: RootState) => s.club);
  const { messagesGroupedList, messagesList } = useSelector(
    (s: RootState) => s.messages
  );

  const selectedGroupItem = messagesGroupedList.find(
    (mg) => mg.id === selectedGroupId
  );

  const clubOptions = clubList.map((option) => ({
    id: option.id,
    name: option.name,
  }));

  const params = useParams();
  const { clubId } = params;

  useEffect(() => {
    // @ts-ignore
    dispatch(getClubList());
  }, []);

  useEffect(() => {
    if (clubList.length) {
      const clubItem = clubList.find((c) => c.id === clubId) || clubList[0];
      setSelectedClub({ id: clubItem.id, name: clubItem.name });
    }
  }, [clubList, clubId]);

  const checkNew = messagesList.filter((m) => m.isNew).length;

  useEffect(() => {
    if (selectedClub) {
      // @ts-ignore
      dispatch(getMessagesGroupedList(selectedClub.id));
    }
  }, [selectedClub, checkNew]);

  const renderMessageGroupItem = (messageGroupItem: IMessagesGrouped) => {
    const messagesListNew = messagesList.filter((m) => m.isNew);
    const groupMessages = messageGroupItem.messages;

    let newMessages: string[] = [];

    messagesListNew.map((m) => {
      const found = groupMessages.find((f) => f.id === m.id);
      if (found) {
        newMessages.push(found.id);
      }
    });

    const onClickGroup = () => {
      setSelectedGroupId(messageGroupItem.id);
      dispatch(markMessageAsRead(newMessages));
    };

    return (
      <div onClick={onClickGroup} className={styles["message-group-item"]}>
        <div
          style={{
            width: 12,
            height: 12,
            backgroundColor: newMessages.length ? "red" : "transparent",
            borderRadius: 6,
            marginLeft: "auto",
          }}
        />
        <AppText color="gray3">{messageGroupItem.customer.city}</AppText>
        <AppText style={{ margin: "10px 0 40px 0" }} color="black" variant="h4">
          {messageGroupItem.customer.full_name}
        </AppText>
        <AppText color="black" variant="body3">
          {messageGroupItem.customer.email}
        </AppText>
      </div>
    );
  };

  const onCloseModal = () => setSelectedGroupId("");

  const onRefreshList = () => {
    // @ts-ignore
    dispatch(getMessagesGroupedList(selectedClub.id));
  };

  const onClickSendMessage = () => {
    const bodyParams = {
      club_id: selectedClub?.id!,
      content: messageText,
      customer_id: selectedGroupItem?.customer.id,
    };
    const onSuccess = () => setMessageText("");
    // @ts-ignore
    dispatch(createMessage(bodyParams, onSuccess));
  };

  const noMessagesText = (
    <AppText sx={{ textAlign: "center", mt: 10 }} variant="h3" color="gray2">
      {"No chats found..."}
    </AppText>
  );

  return (
    <AuthorizedLayout>
      <Row>
        <AppText color="white" variant="h2">
          {"Messages"}
        </AppText>
        <Select
          searchable={false}
          values={selectedClub ? [selectedClub] : []}
          options={clubOptions}
          labelField="name"
          valueField="id"
          onChange={(val) => setSelectedClub(val[0])}
        />
      </Row>

      {isMessagesGroupedListLoading && (
        <CircularProgress
          sx={{ color: "#ccc" }}
          size={50}
          style={{ position: "absolute", top: "50%", left: "50%" }}
        />
      )}

      {!isMessagesGroupedListLoading && !messagesGroupedList.length
        ? noMessagesText
        : messagesGroupedList.map((item) => renderMessageGroupItem(item))}

      <ChatModal
        open={!!selectedGroupId}
        selectedGroupId={selectedGroupId}
        onCloseModal={onCloseModal}
        onClickSendMessage={onClickSendMessage}
        onRefreshList={onRefreshList}
        setMessageText={setMessageText}
        messageText={messageText}
      />
    </AuthorizedLayout>
  );
}

export default HelpChatPage;
