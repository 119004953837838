import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../store/store";
import { LoadingSubject, loadingEnd, loadingStart } from "./loadingSlice";
import { baseUrl } from "../constants";
import { getNewsAvailableAmount } from "./newsSlice";
import toast from "react-hot-toast";

export interface ITariff {
  id: string;
  name: string;
  duration: number;
  global_news_amount: number;
  local_news_amount: number;
  price: number;
}

export interface ISubscription {
  tariff: ITariff;
  subscription: {
    id: string;
    tariff_id: string;
    club_id: string;
    start_date: string;
    end_date: string;
    active: boolean;
    name: string;
    duration: number;
    global_news_amount: number;
    local_news_amount: number;
    price: number;
  };
}

interface ITariffState {
  tariffslist: ITariff[];
  subscriptionsList: ISubscription[];
}

const initialState: ITariffState = {
  tariffslist: [],
  subscriptionsList: [],
};

const tariffs = createSlice({
  name: "tariffs",
  initialState,
  reducers: {
    _getTariffsList: (state, { payload }: PayloadAction<ITariff[]>) => {
      return { ...state, tariffslist: payload };
    },
    _getSubscriptionsList: (
      state,
      { payload }: PayloadAction<ISubscription[]>
    ) => {
      return { ...state, subscriptionsList: payload };
    },
  },
});

export const getTariffsList = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.GetTariffsList));
    const { token } = getState().auth;
    const response = await fetch(`${baseUrl}/api/tariff`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonData = await response.json();
    if (response.ok) {
      dispatch(_getTariffsList(jsonData));
    } else {
    }
  } catch (e) {
  } finally {
    dispatch(loadingEnd(LoadingSubject.GetTariffsList));
  }
};

export const createTariff = (
  bodyParams: {
    name: string;
    duration: number;
    global_news_amount: number;
    local_news_amount: number;
    price: number;
  },
  onSuccess: () => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.CreateTariff));
    const { token } = getState().auth;
    const response = await fetch(`${baseUrl}/api/tariff`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyParams),
    });
    const jsonData = await response.json();
    if (response.status === 200) {
      console.log('createTariff200', jsonData)
      onSuccess && onSuccess();
    } else {
      console.log('createTariffNot200', jsonData)
      toast.error('Creating tariff error')
    }
  } catch (e) {
    console.log('createTariffErr', e)
  } finally {
    dispatch(loadingEnd(LoadingSubject.CreateTariff));
  }
};

export const deleteTariff = (
  id: string,
  onSuccess: () => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.DeleteTariff));
    const { token } = getState().auth;
    const response = await fetch(`${baseUrl}/api/tariff/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonData = await response.json();
    if (response.status === 200) {
      console.log('deleteTariff200', jsonData)
      onSuccess && onSuccess();
    } else {
      console.log('deleteTariffNot200', jsonData)
      toast.error('Deleting tariff error')
    }
  } catch (e) {
    console.log('deleteTariffErr', e)
  } finally {
    dispatch(loadingEnd(LoadingSubject.DeleteTariff));
  }
};

export const updateTariff = (
  id: string,
  bodyParams: {
    name: string;
    duration: number;
    global_news_amount: number;
    local_news_amount: number;
    price: number;
  },
  onSuccess: () => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.UpdateTariff));
    const { token } = getState().auth;
    const response = await fetch(`${baseUrl}/api/tariff/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyParams)
    });
    const jsonData = await response.json();
    if (response.status === 200) {
      console.log('updateTariff200', jsonData)
      onSuccess && onSuccess();
    } else {
      console.log('updateTariffNot200', jsonData)
      toast.error('Updating tariff error')
    }
  } catch (e) {
    console.log('updateTariffErr', e)
  } finally {
    dispatch(loadingEnd(LoadingSubject.UpdateTariff));
  }
};

export const getSubscriptionsList = (
  club_id?: string
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.GetSubscriptionsList));
    const { token } = getState().auth;
    const url = `${baseUrl}/api/tariff/subscription${club_id ? "?club_id=" + club_id : ""
      }`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonData = await response.json();
    if (response.ok) {
      dispatch(_getSubscriptionsList(jsonData));
    } else {
    }
  } catch (e) {
  } finally {
    dispatch(loadingEnd(LoadingSubject.GetSubscriptionsList));
  }
};

export const makeSubscribe = (
  bodyParams: {
    club_id: string;
    tariff_id: string;
    start_date: string;
  },
  onSuccess: () => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.MakeSubscribe));
    const { token } = getState().auth;

    const response = await fetch(`${baseUrl}/api/tariff/subscribe`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyParams),
    });

    const jsonData = await response.json();

    if (response.ok) {
      onSuccess && onSuccess();
      dispatch(getSubscriptionsList(bodyParams.club_id));
      dispatch(getNewsAvailableAmount(bodyParams.club_id));
    } else {
      toast.error('Insufficient club balance. You can add some in club settings')
    }
  } catch (e) {
  } finally {
    dispatch(loadingEnd(LoadingSubject.MakeSubscribe));
  }
};

export const { _getTariffsList, _getSubscriptionsList } = tariffs.actions;

export default tariffs.reducer;
