export const clubsItems = [
  {
    image: require("./../../assets/images/raster/ModeratorClubs/image-1.jpg"),
    title: "Club 1",
    desc: "Lorem ipsum dolor sit amet consectetur. Orci maecenas eu amet enim. Ac enim laoreet massa adipiscing feugiat.",
    admin: "Raquel Aranda",
    rating: 1,
    tariff: "Basic Tariff",
  },
  {
    image: require("./../../assets/images/raster/ModeratorClubs/image-2.jpg"),
    title: "Club 2",
    desc: "Lorem ipsum dolor sit amet consectetur. Orci maecenas eu amet enim. Ac enim laoreet massa adipiscing feugiat.",
    admin: "Raquel Aranda",
    rating: 2,
    tariff: "PRO Tariff",
  },
  {
    image: require("./../../assets/images/raster/ModeratorClubs/image-3.jpg"),
    title: "Club 3",
    desc: "Lorem ipsum dolor sit amet consectetur. Orci maecenas eu amet enim. Ac enim laoreet massa adipiscing feugiat.",
    admin: "Raquel Aranda",
    rating: 3,
    tariff: "Test Tariff",
  },
  {
    image: require("./../../assets/images/raster/ModeratorClubs/image-4.jpg"),
    title: "Club 4",
    desc: "Lorem ipsum dolor sit amet consectetur. Orci maecenas eu amet enim. Ac enim laoreet massa adipiscing feugiat.",
    admin: "Raquel Aranda",
    rating: 4,
    tariff: "Average Tariff",
  },
  {
    image: '',
    title: "Club 5",
    desc: "Lorem ipsum dolor sit amet consectetur. Orci maecenas eu amet enim. Ac enim laoreet massa adipiscing feugiat.",
    admin: "Raquel Aranda",
    rating: 5,
    tariff: "Awesome Tariff",
  },
];
