import React, { Fragment, useState } from "react";
import styles from "./ChangePlanModal.module.css";
import AppText from "../../../../components/AppText/AppText";
import AppButton from "../../../../components/AppButton/AppButton";
import ModalWrapper from "components/modals/ModalWrapper";
import { useDispatch, useSelector } from "react-redux";
import { ITariff, makeSubscribe } from "slices/tariffsSlice";
import { RootState } from "store/rootReducer";
import { useParams } from "react-router-dom";
import moment from "moment";
import { isLoading } from "utils";
import { LoadingSubject } from "slices/loadingSlice";

type Props = {
  open: boolean;
  onClose: () => void;
};

function ChangePlanModal(props: Props) {
  const { open, onClose } = props;
  const dispatch = useDispatch();

  const [selectedPlan, setSelectedPlan] = useState<ITariff | undefined>();
  const { tariffslist, subscriptionsList } = useSelector(
    (s: RootState) => s.tariffs
  );

  const params = useParams();
  const club_id = params.id;

  const renderItem = (item: ITariff, index: number) => {
    const { name, duration, global_news_amount, local_news_amount, price } =
      item;

    const isYours = subscriptionsList.find(
      (s) => s.subscription.tariff_id === item.id && s.subscription.active
    );

    const containerStyles = `${styles["item-container"]} ${
      isYours ? styles["item-yours"] : ""
    }`;

    return (
      <div className={containerStyles}>
        {isYours && (
          <div className={styles["item-yours-header"]}>
            <AppText variant="body4" color="white">
              {"Your plan"}
            </AppText>
          </div>
        )}
        <AppText sx={{ mt: 4 }} variant="h4">
          {name || "Plan name"}
        </AppText>
        <AppText sx={{ mt: 4, mb: 2 }} variant="body3" color="gray3">
          {`Duration: ${duration}`}
        </AppText>

        <div className={styles["price-container"]}>
          <AppText variant="h4">{"€"}</AppText>
          <AppText variant="h1">{price}</AppText>
        </div>

        <AppText sx={{ mt: 6 }} variant="body3" color="gray3">
          {`Global news amount: ${global_news_amount}`}
        </AppText>
        <AppText sx={{ mt: 1 }} variant="body3" color="gray3">
          {`Local news amount: ${local_news_amount}`}
        </AppText>

        {!isYours && (
          <AppButton
            onClick={() => setSelectedPlan(item)}
            sx={{ mt: 8 }}
            text={"Plug"}
          />
        )}

        <div style={{ marginTop: 30 }} />

        {!isYours && <div style={{ marginTop: 40 }} />}
      </div>
    );
  };

  const mainContent = () => {
    return (
      <Fragment>
        <AppText sx={{ textAlign: "center" }} variant="h2">
          {"Change plan"}
        </AppText>

        <div
          className={styles["plans-container"]}
          // style={{
          //   justifyContent: tariffslist.length > 1 ? "space-between" : "center",
          // }}
        >
          {tariffslist.map((item, index) => renderItem(item, index))}
        </div>
      </Fragment>
    );
  };

  const subjects = useSelector((s: RootState) => s.loading.subjects);

  const isLoadingMakeSubscribe = isLoading(
    LoadingSubject.MakeSubscribe,
    subjects
  );

  const confirmWindow = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <AppText variant="h2" sx={{ textAlign: "center", mt: 1, p: 1 }}>
          {`Your account will be debited: € ${selectedPlan?.price}`}
        </AppText>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: 40,
          }}
        >
          <AppButton
            loading={isLoadingMakeSubscribe}
            onClick={() => {
              const bodyParams = {
                club_id: club_id!,
                tariff_id: selectedPlan?.id!,
                start_date: moment().format("YYYY-MM-DD"),
              };
              const onSuccess = () => {
                setSelectedPlan(undefined);
                onClose();
              };
              // @ts-ignore
              dispatch(makeSubscribe(bodyParams, onSuccess));
            }}
            sx={{ width: "40%" }}
            text={"Send"}
          />
          <AppButton
            onClick={onClose}
            variant="outlined"
            color="error"
            sx={{ width: "40%" }}
            text={"Cancel"}
          />
        </div>
      </div>
    );
  };

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      maxWidth={selectedPlan && "40%"}
    >
      {selectedPlan ? confirmWindow() : mainContent()}
    </ModalWrapper>
  );
}

export default ChangePlanModal;
