import NewTable from "../../../../components/NewTable/NewTable";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClubApplication } from "../../../../slices/applicationSlice";
import { RootState } from "../../../../store/rootReducer";
import UserApplicationModal from "./UserApplicationModal";
import Select from "react-dropdown-select";
import { Row } from "components/blocks";
import { getClubList, getEmployeeList } from "slices/clubSlice";
import { useRoles } from "hooks/useRoles";
import { useIntl } from "react-intl";

function ApplicationsTab() {
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const [isModal, setModal] = useState(false);
  const openModal = () => setModal(true);
  const closeModal = () => setModal(false);
  const dispatch = useDispatch();

  const [selectedApplicationId, setApplicationId] = useState<string>();
  const [selectedClubId, setSelectedClubId] = useState<string>();

  const applicationList = useSelector((s: RootState) => s.application.list);
  const clubList = useSelector((s: RootState) => s.club.clubList);

  const options = clubList.map((option) => ({
    id: option.id,
    name: option.name,
  }));

  const { me } = useSelector((s: RootState) => s.auth);
  const role = useRoles();

  useEffect(() => {
    if (role.SuperAdmin || role.Moderator) {
      // @ts-ignore
      dispatch(getClubList());
    } else {
      // @ts-ignore
      dispatch(getEmployeeList(me?.id, undefined, true));
    }
  }, [dispatch]);

  // useEffect(() => {}, [dispatch, clubList]);

  let headCells = [
    t("User"),
    t("text.city"),
    t("text.birth-date"),
    t("text.status"),
  ];
  let rowsData: string[][] = [];

  applicationList?.map((application) =>
    rowsData.push([
      "id_" + application.id,
      application.customer.full_name +
        "application.customer.full_name" +
        " application.customer.full_name",
      application.customer.city,
      application.customer.birthdate,
      application.status,
    ])
  );

  const applicationItem = applicationList.find(
    (item) => item.id === selectedApplicationId
  );

  return (
    <div>
      <Row wrap sx={{ my: 8, gap: 4 }}>
        <div />
        <div style={{ maxWidth: 250, width: "100%" }}>
          <Select
            searchable={false}
            placeholder={t("text.select-club") + "..."}
            values={
              clubList.length
                ? [{ id: clubList[0].id, name: clubList[0].name }]
                : []
            }
            options={options}
            labelField="name"
            valueField="id"
            onChange={(val) => {
              const clubId = val[0].id;
              // @ts-ignore
              dispatch(getClubApplication(clubId));
              setSelectedClubId(clubId);
            }}
          />
        </div>
      </Row>

      <NewTable
        headCells={headCells}
        rows={rowsData}
        actionButton={{
          onClick: (id: string) => {
            setApplicationId(id);
            openModal();
          },
          title: "Open",
        }}
      />

      {applicationItem && (
        <UserApplicationModal
          applicationItem={applicationItem}
          open={isModal}
          onClose={closeModal}
          clubId={selectedClubId!}
        />
      )}
    </div>
  );
}
export default ApplicationsTab;
