import { useState } from "react";
import styles from "./CategoryModal.module.css";
import AppInput from "../../../../components/AppInput/AppInput";
import AppButton from "../../../../components/AppButton/AppButton";
import AppForm from "../../../../forms/AppForm";
import { FormikValues } from "formik";
import { useSchemas } from "../../../../helpers/schemas";
import { AppColors } from "../../../../AppTheme";
import { useRef } from "react";
import TrashIcon from "@mui/icons-material/DeleteOutline";
import {
  ICategory,
  createCategory,
  updateCategory,
  updateCategoryImage,
} from "../../../../slices/categorySlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/rootReducer";
import { LoadingSubject } from "../../../../slices/loadingSlice";
import { isLoading } from "../../../../utils";
import Icon from "../../../../assets/icons/svgs/Icon";
import { useParams } from "react-router-dom";
import AppText from "../../../../components/AppText/AppText";
import { Box, Grid } from "@mui/material";
import ModalWrapper from "components/modals/ModalWrapper";
import { Column, Row } from "components/blocks";
import { useIntl } from "react-intl";

const s = (className: string) => styles[className];

type Props = {
  open: boolean;
  onClose: () => void;
  openDeleteModal: () => void;
  categoryItem?: ICategory;
  //
  catId: string;
  setCatId: React.Dispatch<React.SetStateAction<string>>;
};

function CategoryModal(props: Props) {
  const {
    categoryItem,
    onClose,
    openDeleteModal,
    open,
    //
    catId,
    setCatId,
  } = props;
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const token = useSelector((s: RootState) => s.auth.token);
  const { categorySchema } = useSchemas();
  const dispatch = useDispatch();
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [image, setImage] = useState<{
    preview: string | undefined;
    raw: File | undefined;
  }>({
    preview: "",
    raw: undefined,
  });

  const [showImageErr, setShowImageErr] = useState(false);

  const params = useParams();
  const clubId = params.id;
  const catType = params.catType;

  const closeModal = () => {
    setImage({ preview: undefined, raw: undefined });
    setShowImageErr(false);
    onClose();
  };

  const subjects = useSelector((s: RootState) => s.loading.subjects);
  const isLoadingCreateCategory = isLoading(
    LoadingSubject.CreateCategory,
    subjects
  );
  const isLoadingUpdateCategory = isLoading(
    LoadingSubject.UpdateCategory,
    subjects
  );
  const isLoadingUpdateCategoryImage = isLoading(
    LoadingSubject.UpdateCategoryImage,
    subjects
  );

  const onSubmit = (values: FormikValues) => {
    console.log("submitted", values);
    console.log("categoryItem", categoryItem);
    console.log("image.raw", !!image.raw);

    // update
    if (categoryItem) {
      const data = {
        club_id: categoryItem.club_id,
        name: values.name,
        type: catType,
        description: values.description,
      };
      if (image.raw) {
        const formData = new FormData();
        formData.append("file", image.raw);
        dispatch(
          // @ts-ignore
          updateCategoryImage(
            categoryItem.club_id,
            categoryItem.id,
            formData,
            // @ts-ignore
            catType!
          )
        );
      }
      // @ts-ignore
      dispatch(updateCategory(categoryItem.id, data, catType, closeModal));
    } // create
    else if (image.raw) {
      const formData = new FormData();
      formData.append("image", image.raw);
      formData.append("club_id", clubId!);
      formData.append("name", values.name);
      formData.append("description", values.description);
      formData.append("type", catType!);

      dispatch(
        // @ts-ignore
        createCategory(clubId, formData, catType, (smth) => {
          setCatId(smth);
          closeModal();
        })
      );
    } else {
      setShowImageErr(true); // no image set on create
    }
  };

  const onImageClick = () => {
    inputFile?.current?.click();
  };

  const renderImagePicker = () => {
    return (
      <div>
        <div onClick={onImageClick} className={styles["left-container"]}>
          {image.preview || categoryItem?.image_url ? (
            <img
              className={styles["image-style"]}
              src={image.preview || categoryItem?.image_url + "&token=" + token}
              alt="category-img"
            />
          ) : (
            <Icon name="Plus" className={s("plus-icon")} />
          )}
          <input
            autoComplete="off"
            onChange={(event) => {
              const files = event?.target?.files;
              if (files?.length) {
                const image = files[0];
                setImage({
                  preview: URL.createObjectURL(image),
                  raw: image,
                });
                setShowImageErr(false);
              }
            }}
            accept="image/*"
            type="file"
            id="file"
            ref={inputFile}
            style={{ display: "none" }}
          />
        </div>
        {showImageErr && (
          <AppText
            sx={{
              color: AppColors.red,
              display: "block",
              textAlign: "center",
              marginTop: "15px",
            }}
          >
            {t("text.please-upload-a-picture")}
          </AppText>
        )}
      </div>
    );
  };

  const renderCategoryForm = () => {
    return (
      <AppForm
        initialValues={{
          name: categoryItem?.name,
          description: categoryItem?.description,
        }}
        onSubmit={onSubmit}
        children={
          <>
            <Grid container spacing={8}>
              <Grid item xs={12} lg={6}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", lg: "start" },
                  }}
                >
                  {renderImagePicker()}
                </Box>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Column>
                  <AppInput
                    name="name"
                    classNameContainer={styles["input-container-style"]}
                    classNameInput={styles["input-style"]}
                    placeholder={t("text.category-name")}
                    errorColor={AppColors.red}
                    isForm
                  />
                  <AppInput
                    name="description"
                    classNameContainer={styles["input-container-style"]}
                    classNameInput={styles["input-style"]}
                    placeholder={t("desc")}
                    errorColor={AppColors.red}
                    isForm
                    isTextArea
                    maxLength={256}
                    style={{
                      marginTop: 10,
                      backgroundColor: AppColors.white,
                    }}
                  />
                </Column>
              </Grid>
            </Grid>
            <Row
              style={{ justifyContent: "space-around" }}
              sx={{ mt: 10, mb: 4, gap: 4 }}
            >
              <AppButton
                isForm
                sx={{ maxWidth: 250 }}
                fullWidth
                text={t("general.save")}
                loading={
                  isLoadingCreateCategory ||
                  isLoadingUpdateCategory ||
                  isLoadingUpdateCategoryImage
                }
              />
              <AppButton
                sx={{ maxWidth: 250 }}
                onClick={closeModal}
                fullWidth
                variant="outlined"
                color="error"
                text={t("general.cancel")}
              />
            </Row>
          </>
        }
        validationSchema={categorySchema}
        enableReinitialize={true}
      />
    );
  };

  return (
    <ModalWrapper
      open={open}
      title={
        categoryItem && (
          <AppButton
            sx={{ fontFamily: "Malgun-Bold", fontSize: 13 }}
            startIcon={<TrashIcon fontSize="large" />}
            variant="text"
            color="error"
            text={t("text.delete-category")}
            onClick={openDeleteModal}
          />
        )
      }
      onClose={closeModal}
      maxWidth="750px"
    >
      {renderCategoryForm()}
    </ModalWrapper>
  );
}

export default CategoryModal;
