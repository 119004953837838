import NewTable from "../../../../components/NewTable/NewTable";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  UserRole,
  deleteUser,
  getUserList,
} from "../../../../slices/usersSlice";
import { RootState } from "../../../../store/rootReducer";
import AppButton from "../../../../components/AppButton/AppButton";
import { useNav } from "../../../../hooks/useNav";
import styles from "./GenericUsersTab.module.css";
import { getEmployeeList } from "slices/clubSlice";
import { useIntl } from "react-intl";
import ModalWrapper from "components/modals/ModalWrapper";
import CreateUserPageContent from "pages/CreateUserPage/CreateUserPageContent";
import { useLoadings } from "hooks/useLoadings";
import DeleteModal from "components/modals/DeleteModal/DeleteModal";

type Props = {
  userRole?: UserRole;
  roleColumnShown?: boolean;
  clubIds?: string[];
  excludeClubColumn?: boolean;
};

function GenericUsersTab(props: Props) {
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const { userRole, roleColumnShown, excludeClubColumn } = props;

  const [selectedUserId, setSelectedUserId] = useState("");

  const { clubList } = useSelector((s: RootState) => s.club);
  const { me } = useSelector((s: RootState) => s.auth);

  let buttonText = "Create user";

  switch (userRole) {
    case UserRole.ClubOwner:
      buttonText = "Create club owner";
      break;
    case UserRole.ManagerAdmin:
      buttonText = "Create manager/admin";
      break;
    case UserRole.Moderator:
      buttonText = "Create moderator";
      break;
    case UserRole.Staff:
      buttonText = "Create staff";
      break;
  }

  const {
    isDeleteUserLoading,
    isUpdateUserLoading,
    isCreateEmployeeLoading,
    isDeleteEmployeeLoading,
  } = useLoadings();

  const [isDeleteModal, setDeletModal] = useState(false);

  const { viewCreateUser } = useNav();

  const dispatch = useDispatch();
  const { list } = useSelector((s: RootState) => s.users);

  let headCells = [t("text.first-name"), t("text.last-name"), t("text.email")];

  if (!excludeClubColumn) {
    headCells.push(t("Club"));
  }

  if (roleColumnShown) {
    headCells.push(t("text.role"));
  }

  let rowsData: string[][] = [];
  list?.map((i) => {
    const row = ["id_" + i.id, i.first_name, i.last_name, i.email];
    if (!excludeClubColumn) {
      row.push(i.clubs.map((c) => c.name).join(", ") || "—");
    }
    if (roleColumnShown) {
      row.push(i.role);
    }
    return rowsData.push(row);
  });

  useEffect(() => {
    // @ts-ignore
    dispatch(getEmployeeList([me?.id], undefined, true));
  }, [
    isDeleteUserLoading,
    isUpdateUserLoading,
    isCreateEmployeeLoading,
    isDeleteEmployeeLoading,
  ]);

  useEffect(() => {
    const club_ids = clubList.map((c) => c.id);
    const isSuperAdmin = me?.role === UserRole.SuperAdmin;
    dispatch(
      // @ts-ignore
      getUserList({
        club_ids: isSuperAdmin ? undefined : club_ids,
        role: userRole,
      })
    );
  }, [
    clubList,
    isDeleteUserLoading,
    isUpdateUserLoading,
    isDeleteEmployeeLoading,
    isCreateEmployeeLoading,
  ]);

  const clearSelectedUserId = () => setSelectedUserId("");

  console.log({ selectedUserId });
  const seletedUserItem = list.find((lu) => lu.id === selectedUserId);

  const onClickDeleteUser = () => {
    if (selectedUserId) {
      const onSuccess = () => {
        toggleDeleteUserModal();
        clearSelectedUserId();
      };
      // @ts-ignore
      dispatch(deleteUser(selectedUserId, onSuccess));
    }
  };

  const toggleDeleteUserModal = () => setDeletModal(!isDeleteModal);

  return (
    <div className={styles["main-container"]}>
      <AppButton
        className={styles["create-user-button"]}
        text={buttonText}
        onClick={() => viewCreateUser(userRole)}
      />
      <NewTable
        headCells={headCells}
        rows={rowsData}
        actionButton={{
          onClick: (id: string) => setSelectedUserId(id),
          title: "Edit",
        }}
      />
      <ModalWrapper
        maxWidth={"65vw"}
        maxHeight="90vh"
        open={!!selectedUserId}
        onClose={clearSelectedUserId}
        headerChildren={
          seletedUserItem?.id !== me?.id ? (
            <AppButton
              onClick={toggleDeleteUserModal}
              color="error"
              variant="outlined"
              sx={{ minWidth: "15%" }}
              text={"Delete user"}
            />
          ) : (
            <div />
          )
        }
      >
        <CreateUserPageContent
          clearSelectedUserId={clearSelectedUserId}
          selectedUserId={selectedUserId}
        />
        <DeleteModal
          modalTitle={"Deleting user"}
          desc={`User: «${seletedUserItem?.first_name} ${seletedUserItem?.last_name}» will be completely deleted!`}
          loading={isDeleteUserLoading}
          onDelete={onClickDeleteUser}
          open={isDeleteModal}
          onClose={toggleDeleteUserModal}
        />
      </ModalWrapper>
    </div>
  );
}
export default GenericUsersTab;
