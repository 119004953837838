import React from "react";
import { Box, Modal, Typography } from "@mui/material";
import { IMessage, markMessageAsRead } from "slices/messagesSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import moment from "moment";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import { useNav } from "hooks/useNav";

type Props = {
  open: boolean;
  handleClose: () => void;
};

const style = {
  width: "22%",
  height: "50%",
  minWidth: 200,
  minHeight: 150,
  position: "absolute",
  top: 0,
  right: 0,
  bgcolor: "background.paper",
  p: 4,
  overflow: "scroll",
};

const NotifsModal = (props: Props) => {
  const { open, handleClose } = props;
  const { viewHelpChat } = useNav();

  const { token } = useSelector((s: RootState) => s.auth);
  const { messagesList } = useSelector((s: RootState) => s.messages);

  const renderNotifTile = (item: IMessage) => {
    const { club_id, created_at, club, customer, content } = item;
    const messageText =
      content.length > 25 ? content.slice(0, 25) + "..." : content;
    return (
      <div
        style={{
          marginTop: 10,
          borderRadius: 10,
          padding: "7px 12px",
          display: "flex",
          cursor: "pointer",
          boxShadow: "1px 1px 3px 1px rgba(0,0,0,0.2)",
          backgroundColor: "#e3ffd3",
          alignItems: "center",
        }}
        onClick={() => {
          viewHelpChat(club_id);
          handleClose();
        }}
      >
        <img
          style={{
            width: 50,
            minWidth: 50,
            height: 50,
            minHeight: 50,
            objectFit: "cover",
            borderRadius: 25,
            boxShadow: "1px 1px 3px 1px rgba(0,0,0,0.2)",
          }}
          src={customer.photo_url + "&token=" + token}
        />
        <div
          style={{ display: "flex", flexDirection: "column", marginLeft: 15 }}
        >
          <Typography variant="body3">{club.name}</Typography>
          <Typography sx={{ fontSize: 13 }} variant="body3">
            {`${customer.full_name}: ${messageText}`}
          </Typography>
          <Typography sx={{ fontSize: 10, mt: 2 }} variant="body3">
            {moment(created_at).format("DD-MM-YYYY hh:mm:ss")}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style} style={{ borderBottomLeftRadius: 20 }}>
          {messagesList
            .filter((m) => m.isNew)
            .map((item) => renderNotifTile(item))}
        </Box>
      </Fade>
    </Modal>
  );
};

export default NotifsModal;
