import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../store/store";
import { baseUrl } from "../constants";
import { loadingEnd, loadingStart, LoadingSubject } from "./loadingSlice";

export interface IStatistics {
  product_id: string;
  product_name: string;
  total_amount: number;
  total_price: number;
  completed_orders_amount: number;
}

export interface IStatisticsState {
  list: IStatistics[];
}

const initialState: IStatisticsState = {
  list: [],
};

const statistics = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    _getClubStatistics: (state, { payload }: PayloadAction<IStatistics[]>) => {
      return { ...state, list: payload };
    },
  },
});

export const getClubStatistics = (
  clubId: string,
  start_date: string,
  end_date: string
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.GetClubStatistics));
    const { token } = getState().auth;
    const response = await fetch(`${baseUrl}/api/statistics/${clubId}/products?start_date=${start_date}&end_date=${end_date}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonData = await response.json();
    if (response.status === 200) {
      dispatch(_getClubStatistics(jsonData));
    } else {
      console.log('')
    }
  } catch (e) {
    console.log('')
  } finally {
    dispatch(loadingEnd(LoadingSubject.GetClubStatistics));
  }
};

export const { _getClubStatistics } = statistics.actions;
export default statistics.reducer;
