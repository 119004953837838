import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { isLoading } from 'utils';
import { LoadingSubject } from 'slices/loadingSlice';

export const useLoadings = () => {
  const { subjects } = useSelector((s: RootState) => s.loading);
  return {
    isClubListLoading: isLoading(LoadingSubject.ClubList, subjects),


    isPublishNewsLoading: isLoading(LoadingSubject.PublishNews, subjects),
    isRejectNewsLoading: isLoading(LoadingSubject.RejectNews, subjects),

    isClubFeedbacksListLoading: isLoading(LoadingSubject.GetClubFeedbacksList, subjects),
    isDeleteClubFeedbackLoading: isLoading(LoadingSubject.DeleteClubFeedback, subjects),
    isProductFeedbacksListLoading: isLoading(LoadingSubject.GetProductFeedbacksList, subjects),
    isDeleteProductFeedbackLoading: isLoading(LoadingSubject.DeleteProductFeedback, subjects),

    isMessagesGroupedListLoading: isLoading(LoadingSubject.GetMessagesGroupedList, subjects),

    isDeleteUserLoading: isLoading(LoadingSubject.DeleteUser, subjects),

    isCreateUserLoading: isLoading(LoadingSubject.CreateUser, subjects),

    isUpdateUserLoading: isLoading(LoadingSubject.UpdateUser, subjects),

    isCreateEmployeeLoading: isLoading(LoadingSubject.CreateEmployee, subjects),

    isDeleteEmployeeLoading: isLoading(LoadingSubject.DeleteEmployee, subjects),

    isDeleteClubLoading: isLoading(LoadingSubject.DeleteClub, subjects),

    isCreateMessageLoading: isLoading(LoadingSubject.CreateMessage, subjects),

    isForgotLoading: isLoading(LoadingSubject.Forgot, subjects),


    isGetMessagesListLoading: isLoading(LoadingSubject.GetMessagesList, subjects),





    isGetSubscriptionsListLoading: isLoading(LoadingSubject.GetSubscriptionsList, subjects),

    isGetTariffsListLoading: isLoading(LoadingSubject.GetTariffsList, subjects),




    isGetCommentsListLoading: isLoading(LoadingSubject.GetCommentsList, subjects),
    isDeleteCommentLoading: isLoading(LoadingSubject.DeleteComment, subjects),


    isCreateTariffLoading: isLoading(LoadingSubject.CreateTariff, subjects),
    isDeleteTariffLoading: isLoading(LoadingSubject.DeleteTariff, subjects),

    isUpdateTariffLoading: isLoading(LoadingSubject.UpdateTariff, subjects),

    isSetClubSortingLoading: isLoading(LoadingSubject.SetClubSorting, subjects),












  };
}
