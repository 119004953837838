import styles from "./CreateUserPage.module.css";
import AuthorizedLayout from "layouts/AuthorizedLayout";
import { useIntl } from "react-intl";
import CreateUserPageContent from "./CreateUserPageContent";

const s = (className: string) => styles[className];

function CreateUserPage() {
  const intl = useIntl();

  return (
    <AuthorizedLayout noBackground>
      <CreateUserPageContent />
    </AuthorizedLayout>
  );
}

export default CreateUserPage;
