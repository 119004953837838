import React, { useState } from "react";
import styles from "./Categories.module.css";
import AppText from "../../../components/AppText/AppText";
import Icon from "../../../assets/icons/svgs/Icon";
import CategoryModal from "../Modals/CategoryModal/CategoryModal";
import cn from "classnames";
import DeleteModal from "../../../components/modals/DeleteModal/DeleteModal";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/rootReducer";
import { ICategory, deleteCategory } from "../../../slices/categorySlice";
import { isLoading } from "../../../utils";
import { LoadingSubject } from "../../../slices/loadingSlice";
import { getProductList } from "../../../slices/productSlice";
import { Container } from "@mui/material";
import { useRoles } from "hooks/useRoles";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";

type Props = {
  categories: ICategory[];
  clubId: string;
  categoryItem: ICategory | undefined;
  setCategoryItem: React.Dispatch<React.SetStateAction<ICategory | undefined>>;
  //
  catId: string;
  setCatId: React.Dispatch<React.SetStateAction<string>>;
};

enum ModalEnum {
  None,
  Category,
  DeleteCategory,
}

const s = (className: string) => styles[className];

function Categories(props: Props) {
  const {
    categories,
    clubId,
    categoryItem,
    setCategoryItem,
    //
    catId,
    setCatId,
  } = props;
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });

  const dispatch = useDispatch();
  const token = useSelector((s: RootState) => s.auth.token);

  const [isModal, setModal] = useState(ModalEnum.None);

  const openCategoryModal = () => setModal(ModalEnum.Category);
  const openDeleteModal = () => setModal(ModalEnum.DeleteCategory);

  const params = useParams();
  const catType = params.catType;

  const onCategoryDelete = () => {
    // @ts-ignore
    dispatch(deleteCategory(clubId, categoryItem?.id, catType, closeModal));
  };

  const closeModal = () => {
    setModal(ModalEnum.None);
    setCategoryItem(undefined);
  };

  const subjects = useSelector((s: RootState) => s.loading.subjects);

  const isLoadingDeleteCategory = isLoading(
    LoadingSubject.DeleteCategory,
    subjects
  );

  const renderCategoryItem = (item: ICategory, index: number) => {
    return (
      <div
        onClick={() => {
          // @ts-ignore
          dispatch(getProductList(item.id));
          setCategoryItem(item);
        }}
        style={
          categoryItem?.id === item.id || categoryItem?.id === catId
            ? { border: "2px solid red" }
            : {}
        }
        className={cn(s("category-item-common"), s("category-item"), s("item"))}
      >
        <img
          className={s("category-item-image")}
          src={item.image_url + "&token=" + token}
          alt={item.name}
        />
        <AppText
          className={s("category-item-index")}
          variant="body2"
          color="white"
        >
          {index + 1}
        </AppText>
        {!roles.Staff && (
          <div onClick={openCategoryModal}>
            <Icon className={s("edit-icon")} name="Edit" />
          </div>
        )}
        <AppText
          sx={{
            mr: 2,
            mb: 2,
            alignSelf: "flex-end",
            textShadow: "1px 1px 3px #000",
            backgroundColor: "rgba(0,0,0,0.5)",
            borderRadius: 1,
            padding: "0 5px 3px 5px",
          }}
          variant="h4"
          color="white"
        >
          {item.name.length > 15 ? item.name.slice(0, 15) + "..." : item.name}
        </AppText>
      </div>
    );
  };

  const renderAddCategory = () => {
    return (
      <div
        onClick={() => {
          setCategoryItem(undefined);
          openCategoryModal();
        }}
        className={cn(
          s("category-item-common"),
          s("category-item-add"),
          s("item")
        )}
      >
        <Icon className={s("category-add-icon")} name="Plus" />
        <AppText sx={{ mb: 4, mr: 3 }} variant="body2" color="green">
          {t("text.add-new-category")}
        </AppText>
      </div>
    );
  };

  const roles = useRoles();

  const carouselData = [
    ...categories?.map((item, index) => renderCategoryItem(item, index)),
  ];
  if (!roles.Staff) {
    carouselData.unshift(renderAddCategory());
  }

  return (
    <div className={s("categories-container")}>
      <div className={s("categories-wrapper")}>
        <Container maxWidth="lg">
          <AliceCarousel
            mouseTracking
            items={carouselData}
            responsive={{
              0: { items: 1 },
              568: { items: 2 },
              1024: { items: 4 },
            }}
            controlsStrategy="alternate"
            disableDotsControls
            disableButtonsControls
            disableSlideInfo
          />
        </Container>

        <CategoryModal
          open={isModal === ModalEnum.Category}
          onClose={closeModal}
          openDeleteModal={openDeleteModal}
          categoryItem={categoryItem}
          //
          catId={catId}
          setCatId={setCatId}
        />

        <DeleteModal
          loading={isLoadingDeleteCategory}
          open={isModal === ModalEnum.DeleteCategory}
          onClose={closeModal}
          onDelete={onCategoryDelete}
          title={t("text.delete-category") + "?"}
          desc={t("text.delete-category-desc")}
        />
      </div>
    </div>
  );
}

export default Categories;
