import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../store/store";
import { baseUrl } from "../constants";
import { loadingEnd, loadingStart, LoadingSubject } from "./loadingSlice";
import { getClub, getClubMembers } from "./clubSlice";
import toast from "react-hot-toast";

export interface IBalace {
  amount: number;
  club_id?: string;
  customer_id?: string;
}

export interface IBalanceState {
  balance?: IBalace;
}

const initialState: IBalanceState = {};

const deposit = createSlice({
  name: "deposit",
  initialState,
  reducers: {
    _getClubBalance: (state, { payload }: PayloadAction<IBalace>) => {
      return { ...state, balance: payload };
    },
  },
});

export const getClubBalance = (
  clubId: string
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.GetClubBalance));
    const { token } = getState().auth;
    const response = await fetch(`${baseUrl}/api/club/${clubId}/balance`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonData = await response.json();

    if (response.status === 200) {
      dispatch(_getClubBalance(jsonData));
    } else {
    }
  } catch (e) {
  } finally {
    dispatch(loadingEnd(LoadingSubject.GetClubBalance));
  }
};

type MakeTransactionParamsType = {
  amount: number | string;
  club_id: string;
  customer_id?: string;
};
export const makeTransaction = (
  data: MakeTransactionParamsType,
  onSuccess?: () => void
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadingStart(LoadingSubject.MakeTransaction));
    const { token } = getState().auth;
    const response = await fetch(`${baseUrl}/api/payment/transaction`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    const jsonData = await response.json();

    if (response.status === 200) {
      if (data.club_id && !data.customer_id) {
        dispatch(getClubBalance(data.club_id));
      }
      if (data.club_id && data.customer_id) {
        dispatch(getClubMembers(data.club_id));
      }
      onSuccess && onSuccess();
    } else {
      toast.error(jsonData.detail)
    }
  } catch (e) {
  } finally {
    dispatch(loadingEnd(LoadingSubject.MakeTransaction));
  }
};

export const { _getClubBalance } = deposit.actions;

export default deposit.reducer;
