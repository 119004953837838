import React from "react";
import { CommentsStatusEnum } from "./Comments.mock";
import { Column, Row } from "components/blocks";
import AppText from "components/AppText/AppText";
import styles from "./styles.module.css";
import AppButton from "components/AppButton/AppButton";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";

import { IComment, deleteComment } from "./../../../slices/commentsSlice";
import { useLoadings } from "hooks/useLoadings";

type Props = {
  newsId: string;
};

const Comments = (props: Props) => {
  const { newsId } = props;
  const { commentsList } = useSelector((s: RootState) => s.comments);
  const filteredCommentsList = commentsList.filter((c) => c.news_id === newsId);
  const { token } = useSelector((s: RootState) => s.auth);
  const dispatch = useDispatch();
  const { isGetCommentsListLoading, isDeleteCommentLoading } = useLoadings();

  const renderCommentTile = (item: IComment) => {
    // const isPending = item.status === CommentsStatusEnum.Pending;
    // const isAccepted = item.status === CommentsStatusEnum.Accepted;
    // const isRejected = item.status === CommentsStatusEnum.Rejected;

    return (
      <div
        style={
          false
            ? { backgroundColor: "var(--gray1)" }
            : { backgroundColor: "var(--white)" }
        }
        className={styles["comment-container"]}
      >
        <div className={styles["comment-header"]}>
          <div className={styles["user-img-container"]}>
            <img
              className={styles["user-img"]}
              src={item.author.photo_url + "&token=" + token}
            />
          </div>
          <div className={styles["comment-data"]}>
            <AppText variant="body3" sx={{ fontFamily: "Malgun-Bold" }}>
              {item.author.full_name}
            </AppText>
            <AppText style={{ marginTop: 5 }} variant="body3">
              {item.text}
            </AppText>
          </div>
        </div>
        <div className={styles["comment-footer"]}>
          <AppText sx={{ fontSize: 12, mt: 2 }} color="gray3" variant="body4">
            {moment(item.created_at).format("DD.MM.YYYY")}
          </AppText>
          {/* <div>
            {(isRejected || isPending) && (
              <AppButton variant="outlined" text={"Accept"} />
            )}
            {(isAccepted || isPending) && (
              <AppButton
                sx={isPending ? { ml: 3 } : {}}
                variant="outlined"
                color={"error"}
                text={"Reject"}
              />
            )}
          </div> */}

          <AppButton
            // @ts-ignore
            onClick={() => dispatch(deleteComment(item.id))}
            loading={isDeleteCommentLoading}
            variant="outlined"
            color={"error"}
            text={"Delete"}
          />
        </div>
      </div>
    );
  };

  return (
    <div>{filteredCommentsList.map((item) => renderCommentTile(item))}</div>
  );
};

export default Comments;
