import styled from "@emotion/styled";
import { Box, Container, Grid } from "@mui/material";
import AppText from "components/AppText/AppText";
import { Row } from "components/blocks";
import { IconLogo } from "components/icons";
import { useNav } from "hooks/useNav";
import { useRoles } from "hooks/useRoles";
import { ROUTES } from "navigation/navigation";
import React from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

export interface AppFooterProps {}

type LinkItemType = {
  name: string;
  route: string;
  active: boolean;
};

const AppFooter: React.FC<AppFooterProps> = ({}) => {
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const { viewWelcome } = useNav();
  const roles = useRoles();

  const linkItems: LinkItemType[] = [
    { name: t("text.main"), route: ROUTES.WELCOME, active: true },
    {
      name: t("text.feedbacks"),
      route: ROUTES.FEEDBACKS,
      active:
        roles.ClubOwner ||
        roles.ManagerAdmin ||
        roles.Moderator ||
        roles.SuperAdmin,
    },
    { name: t("Messages"), route: ROUTES.HELP_CHAT, active: true },
  ];

  return (
    <Wrapper sx={{ py: "20px" }}>
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item xs={12} lg={4}>
            <StyledLogoButton onClick={viewWelcome}>
              {/* <IconLogo /> */}
              <img
                style={{ width: 150, height: 60, objectFit: "contain" }}
                src={require("../../assets/images/raster/logo_white.png")}
              />
            </StyledLogoButton>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Row
              sx={{
                alignSelf: "start",
                gap: 4,
                px: 4,
              }}
              wrap
            >
              {linkItems
                .filter((l) => l.active)
                ?.map((item) => (
                  <Link
                    style={{ textDecoration: "none" }}
                    key={item.name}
                    to={item.route}
                  >
                    <AppText color="gray2" variant="body2">
                      {item.name}
                    </AppText>
                  </Link>
                ))}
            </Row>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItem: "flex-end",
              }}
            >
              <AppText
                variant="body3"
                color="gray1"
                sx={{
                  alignSelf: "end",
                  fontSize: { xs: "10px", sm: "14px" },
                }}
              >
                {t("copyright.full-text")}
              </AppText>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default AppFooter;

export const Wrapper = styled(Box)(({ theme }: any) => ({
  height: "100px",
  background: "#131517",
  boxShadow: "0px -4px 8px 0px rgba(0, 0, 0, 0.25)",
}));

const StyledLogoButton = styled(Box)(({ theme }: any) => ({
  display: "inline-flex",
  alignItems: "center",
  color: theme.palette.common.white,
  cursor: "pointer",
  alignSelf: "start",
}));
