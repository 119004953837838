import { useEffect, useState } from "react";
import styles from "./CreateUserPage.module.css";
import AppText from "../../components/AppText/AppText";
import AppButton from "../../components/AppButton/AppButton";
import CreateUserForm from "./forms/CreateUserForm";
import Icon from "../../assets/icons/svgs/Icon";
import { useNav } from "../../hooks/useNav";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import {
  IClub,
  IEmployee,
  createEmployee,
  deleteEmployee,
  getClubList,
  getEmployeeList,
} from "../../slices/clubSlice";
import { CircularProgress, Grid, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import AddedIcon from "@mui/icons-material/CheckCircleOutline";
import ModalWrapper from "components/modals/ModalWrapper";
import CreateClubForm from "pages/CreateClubPage/forms/CreateClubForm";
import { useRoles } from "hooks/useRoles";
import { useParams } from "react-router-dom";
import { IUser, UserRole } from "slices/usersSlice";
import { useIntl } from "react-intl";
import { handleParseAddress } from "pages/ClubProfilePage/forms/EditClubForm";
import { baseUrl } from "./../../constants";
import qs from "qs";
import { useLoadings } from "hooks/useLoadings";
import toast from "react-hot-toast";

const s = (className: string) => styles[className];

type Props = {
  selectedUserId?: string;
  clearSelectedUserId?: () => void;
};

function CreateUserPageContent(props: Props) {
  const { selectedUserId, clearSelectedUserId } = props;
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const { viewClubProfile } = useNav();
  const [showClubs, setShowClubs] = useState(true);
  const [clubIds, setClubIds] = useState<string[]>([]);
  const [isModal, setModal] = useState(false);
  const { me } = useSelector((s: RootState) => s.auth);
  const { token } = useSelector((s: RootState) => s.auth);

  const { isCreateEmployeeLoading, isDeleteEmployeeLoading } = useLoadings();

  const { list } = useSelector((s: RootState) => s.users);

  const seletedUserItem = list.find((lu) => lu.id === selectedUserId);

  useEffect(() => {
    if (seletedUserItem) {
      setClubIds(seletedUserItem.clubs.map((c) => c.id));
    }
  }, [seletedUserItem]);

  const handleClubIdsList = (id: string) => {
    const isAdded = clubIds.find((i) => i === id);
    if (isAdded) {
      setClubIds(clubIds.filter((i) => i !== id));
    } else {
      setClubIds((prev) => [...prev, id]);
    }
  };

  const closeModal = () => {
    setModal(false);
  };
  const roles = useRoles();
  useEffect(() => {
    if (roles.SuperAdmin || roles.Moderator) {
      // @ts-ignore
      dispatch(getClubList());
    } else {
      // @ts-ignore
      dispatch(getEmployeeList(me?.id, undefined, true));
    }
  }, []);

  const [employeeItems, setEmployeeItems] = useState<undefined | IEmployee[]>();

  useEffect(() => {
    const getEmployeeListItems = async (admin_ids: string[]) => {
      try {
        const params = { admin_ids };
        const queryParams = params
          ? `?${qs.stringify(params, { arrayFormat: "repeat", encode: false })}`
          : "";
        const url = `${baseUrl}/api/club/employee${queryParams}`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const jsonData = await response.json();
        if (response.status === 200) {
          setEmployeeItems(jsonData);
        }
      } catch (e) {}
    };
    if (seletedUserItem) {
      getEmployeeListItems([seletedUserItem.id]);
    }
  }, [isCreateEmployeeLoading, isDeleteEmployeeLoading]);

  const { clubList } = useSelector((s: RootState) => s.club);

  const renderClub = (item: IClub) => {
    const isAdded = clubIds.find((i) => i === item.id);
    const parsedAddress = handleParseAddress(item?.address);

    return (
      <div
        className={s("club-container")}
        style={{ opacity: isAdded ? 1 : 0.5 }}
      >
        <div className={s("club-image-container")}>
          <IconButton
            disabled={isCreateEmployeeLoading || isDeleteEmployeeLoading}
            onClick={() => {
              // debugger;
              if (seletedUserItem) {
                if (isAdded) {
                  const employeeItem = employeeItems?.find(
                    (el) => el.club_id === item.id
                  );
                  const isLastEl = employeeItems?.filter(
                    (el) => el.employee_id === seletedUserItem.id
                  );
                  if (isLastEl?.length === 1) {
                    toast.error(`This role requires at least one club`);
                    return;
                  }
                  if (employeeItem) {
                    // @ts-ignore
                    dispatch(deleteEmployee(employeeItem.id));
                  }
                } else {
                  // @ts-ignore
                  dispatch(createEmployee(seletedUserItem.id, item.id));
                }
              } else {
                handleClubIdsList(item.id);
              }
            }}
            className={s("club-action-container")}
          >
            {isAdded ? (
              <AddedIcon className={s("club-action-icon")} />
            ) : (
              <AddIcon className={s("club-action-icon")} />
            )}
          </IconButton>
          <img className={s("club-image")} src={item.picture_url} alt="club" />
        </div>
        <div className={s("club-details-container")}>
          <div className={s("club-desc-container")}>
            <AppText variant="body2">{item.name}</AppText>
            <AppText variant="body4" color="gray2">
              {parsedAddress.address}
            </AppText>
          </div>
          <AppButton
            onClick={() => viewClubProfile(item.id)}
            text={t("text.open-club")}
            sx={{ height: 30, minWidth: 100, width: 100 }}
          />
        </div>
      </div>
    );
  };

  const renderAddClubButton = () => {
    return (
      <div onClick={() => setModal(true)} className={s("add-club-button")}>
        <div className={s("add-club-left")}>
          <Icon className={s("add-club-icon")} name={"Plus"} />
        </div>
        <div className={s("add-club-right")}>
          <AppText variant="body2">{t("text.add-new-club")}</AppText>
        </div>
      </div>
    );
  };

  const addCreatedClubId = (clubId: string) => {
    setClubIds((prev) => [...prev, clubId]);
  };

  const urlParams = useParams();

  return (
    <>
      <CreateUserForm
        clearSelectedUserId={clearSelectedUserId}
        selectedUserId={selectedUserId}
        showClubs={(isShown) => {
          if (!isShown) {
            setClubIds([]);
          }
          setShowClubs(isShown);
        }}
        clubIds={clubIds}
      />

      {showClubs &&
        urlParams.role !== UserRole.Moderator &&
        seletedUserItem?.role !== UserRole.Moderator &&
        urlParams.role !== UserRole.SuperAdmin &&
        seletedUserItem?.role !== UserRole.SuperAdmin && (
          <Grid container spacing={8}>
            {(roles.SuperAdmin || roles.Moderator || roles.ClubOwner) && (
              <Grid item xs={12} md={6}>
                {renderAddClubButton()}
              </Grid>
            )}

            {clubList.map((club) => (
              <Grid item xs={12} md={6}>
                {renderClub(club)}
              </Grid>
            ))}
          </Grid>
        )}

      <ModalWrapper title="Add new club" open={isModal} onClose={closeModal}>
        <CreateClubForm
          addCreatedClubId={addCreatedClubId}
          onClose={closeModal}
        />
      </ModalWrapper>
    </>
  );
}

export default CreateUserPageContent;
