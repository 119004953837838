import { useNavigate } from 'react-router-dom'
import { ROUTES } from './../navigation/navigation'
import { UserRole } from 'slices/usersSlice'

export const useNav = () => {
  const navigate = useNavigate()

  return {
    viewLogin: () => navigate(`${ROUTES.LOGIN}`),
    viewClubMenu: (id?: string) => navigate(`${ROUTES.CLUB_MENU}/${id || ''}`),
    viewForgot: () => navigate(`${ROUTES.FORGOT}`),
    viewAdminEdit: (id: string, catType: string) => navigate(`${ROUTES.ADMIN_EDIT}/${id || ''}/${catType || ''}`),
    viewClubProfile: (id?: string) => navigate(`${ROUTES.CLUB_PROFILE}/${id || ''}`),
    viewProfile: () => navigate(`${ROUTES.PROFILE}`),
    viewWelcome: () => navigate(`${ROUTES.WELCOME}`),
    viewCreateUser: (role?: UserRole) => navigate(`${ROUTES.CREATE_USER}/${role || ''}`),
    viewSuperAdminClubs: () => navigate(`${ROUTES.SUPER_ADMIN_CLUBS}`),
    viewCreateNews: () => navigate(`${ROUTES.CREATE_NEWS}`),
    viewFeedbacks: (clubId?: string) => navigate(`${ROUTES.FEEDBACKS}/${clubId || ''}`),
    viewNews: (id?: string) => navigate(`${ROUTES.NEWS}/${id || ''}`),
    viewCreateClub: () => navigate(`${ROUTES.CREATE_CLUB}`),
    viewHelpChat: (clubId?: string) => navigate(`${ROUTES.HELP_CHAT}/${clubId || ''}`),
    viewGoBack: () => navigate(-1)
  }
}
