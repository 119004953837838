import { CSSProperties } from "react";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    body3: React.CSSProperties;
    body4: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
  }
}

const typography = {
  h1: {
    fontFamily: "Malgun-Bold",
    fontSize: 48,
  },
  h2: {
    fontFamily: "Malgun-Bold",
    fontSize: 40,
  },
  h3: {
    fontFamily: "Malgun-Bold",
    fontSize: 24,
  },
  h4: {
    fontFamily: "Malgun-Bold",
    fontSize: 20,
  },
  body1: {
    fontFamily: "Malgun-Regular",
    fontSize: 18,
  },
  body2: {
    fontFamily: "Malgun-Bold",
    fontSize: 16,
  },
  body3: {
    fontFamily: "Malgun-Regular",
    fontSize: 14,
  },
  body4: {
    fontFamily: "Malgun-Bold",
    fontSize: 12,
  },
  fontFamily: "Malgun-Regular",
};

export default typography;
